import React, { useEffect, useState, useRef, useContext } from 'react'
import { useHistory } from "react-router-dom";
import Form from '../../../components/Form'
import '../styleForm.css'
import TitleFormBox from '../../../components/Platform/TitleBox/TitleFormBox'
import api from '../../../services/api'
import { toast } from 'react-toastify'
import TableHistoric from '../../../components/Platform/Table/TableHistoric';
import TableAccess from './Tabs/TableAccess';
import FormLoader from '../../../components/Loaders/Divs/Platform/FormLoader';
import HistoricLoader from '../../../components/Loaders/Divs/Platform/HistoricLoader';
import { Context } from '../../../context/AuthContext';
import { FormControl, FormHelperText, Grid, IconButton, InputAdornment, MenuItem } from "@mui/material";
import NotListedLocationOutlinedIcon from '@mui/icons-material/NotListedLocationOutlined';
import { Visibility, VisibilityOff } from '@mui/icons-material'
import Box from '@mui/material/Box';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import {TabPanel, a11yProps} from '../../../components/TabPanel';
import { CustomTooltipDark } from '../../../components/MaterialCustom/General/Tooltip/CustomTooltip';
import { CustomTab, CustomTabs } from '../../../components/MaterialCustom/Laboratory/Tabs/CustomTabs';
import SpinnerLoader from '../../../components/Loaders/SpinnerLoader';
import { CustomTextField, inputLabelStyles } from '../../../components/MaterialCustom/Laboratory/TextField/CustomTextField';

function UsuariosForm({regId}) {  
    const history = useHistory()
    const [loaderTab, setLoaderTab] = useState(false)
    const [loaderTab2, setLoaderTab2] = useState(false)
    const [loaderTab3, setLoaderTab3] = useState(false)
    const { handleLogout, loading, setLoading } = useContext(Context)
    const [showPassword, setShowPassword] = useState(false)

    const [formUsuarios, setFormUsuarios] = useState({
        name: '',
        email: '',
        status: '',
        born_date: '',
        password: '',
        plan_id: ''
    })

    const [fieldsError, setFieldsError] = useState({
        name: '',
        email: '',
        status: '',
        born_date: '',
        password: '',
        plan_id: ''
    })

    const [statusOptions, setStatusOptions] = useState([
        {id: 1, name: 'Ativo'},
        {id: 2, name: 'Inativo'}
    ])

    const [plansOptions, setPlansOptions] = useState([])

    const [tableAccessCell, setTableAccessCell] = useState([])
    const [tableHistrcCell, setTableHistrcCell] = useState([])

    const title = useRef({
        name: 'Alunos',
        description_insert: 'Insira novos alunos no sistema',
        description_update: 'Altere alunos do sistema',
        link_from: '/admin/alunos'
    }) 

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (regId) {
            setLoaderTab(true)
            setLoaderTab2(true)
            setLoaderTab3(true)

            api.get(`admin/alunos/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab(false)
                        setFormUsuarios(response.data.data.user)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca histórico de inserções/remoções
            api.get(`admin/alunos/historico/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca histórico de acessos
            api.get(`admin/alunos/historico-acessos/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab3(false)
                        setTableAccessCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca planos e profissões existentes para por no select>options
            api.get('admin/alunos/options')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setPlansOptions(response.data.data.plans)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
                
        } else {
            setLoaderTab2(true)

            //Busca histórico de alterações
            api.get('admin/alunos/historico')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca planos e profissões existentes para por no select>options
            api.get('admin/alunos/options')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setPlansOptions(response.data.data.plans)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
        }
    }, [])

    function setName(e) {
        setFormUsuarios({...formUsuarios, name: e.target.value})
    }

    function setEmail(e) {
        setFormUsuarios({...formUsuarios, email: e.target.value})
    }

    function setStatus(e) {
        setFormUsuarios({...formUsuarios, status: e.target.value})
    }

    function setPlan(e) {
        setFormUsuarios({...formUsuarios, plan_id: e.target.value})
    }

    function setBornDate(e) {
        setFormUsuarios({...formUsuarios, born_date: e.target.value})
    }

    function setPassword(e) {
        setFormUsuarios({...formUsuarios, password: e.target.value})
    }

    async function handleSave(e) {
        e.preventDefault()
        setLoading(true)

        await api.post('admin/alunos', formUsuarios)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    setFormUsuarios({name: '', email: '', status: '', born_date: '', password: '', plan_id: ''})

                    history.push("/admin/alunos")
                } else {
                    toast.error(response.data.message)
                }

                setLoading(false)
                setFieldsError({name: '', email: '', status: '', born_date: '', password: '', plan_id: ''})
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    async function handleUpdate(e) {
        e.preventDefault()
        setLoading(true)

        await api.post(`admin/alunos/${regId}`, formUsuarios)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)

                    history.push("/admin/alunos")
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({name: '', email: '', status: '', born_date: '', password: '', plan_id: ''})
                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    function handleClickShowPassword() {
        setShowPassword(!showPassword)
    }

    function handleMouseDownPassword(event) {
        event.preventDefault();
    }

    return (
        <>
            <TitleFormBox handleSave={handleSave} handleUpdate={handleUpdate} regId={regId} title={title.current}/>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <CustomTabs 
                                value={value} 
                                onChange={handleChange} 
                                variant="scrollable"
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                                sx={{
                                    '& .Mui-selected': { color: 'var(--plat-twelve) !important' }
                                }}
                            >
                                <CustomTab icon={<DatasetOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Cadastro" {...a11yProps(0)} />
                                <CustomTab icon={<UpdateOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Histórico" {...a11yProps(1)} />
                                
                                {regId &&
                                    <CustomTab icon={<LoginOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Acessos" {...a11yProps(2)}  />
                                }
                            </CustomTabs>
                        </Box>

                        <TabPanel value={value} index={0}>
                            <>
                                {!loaderTab ?
                                    <div className='admin_box_form'>
                                        <Form>
                                            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Nome completo"
                                                            onChange={setName}
                                                            value={formUsuarios.name} 
                                                            name='name'
                                                            autoComplete='off'
                                                            {...(fieldsError.name && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 200 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.name}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="E-mail"
                                                            name='email'
                                                            autoComplete='off'
                                                            {...(fieldsError.email && {error: 'true'})}
                                                            onChange={setEmail} 
                                                            value={formUsuarios.email}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 200 }}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.email}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField 
                                                            id="outlined-adornment-password" 
                                                            autoComplete='new-password'
                                                            type={showPassword ? 'text' : 'password'} 
                                                            label="Senha"
                                                            {...(fieldsError.password && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            onChange={setPassword} 
                                                            value={formUsuarios.password}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            InputProps={{ 
                                                                maxLength: 200,
                                                                endAdornment:
                                                                    <>
                                                                        <CustomTooltipDark
                                                                            placement="top-end"
                                                                            title={
                                                                                <React.Fragment>
                                                                                    <span className="tooltiptext">
                                                                                        <h6>A senha deve ter: </h6>
        
                                                                                        <ul>
                                                                                            <li>no mínimo 6 caracteres</li>
                                                                                            <li>no mínimo um número</li>
                                                                                            <li>letras maiúsculas e minúsculas</li>
                                                                                            <li>caractere especial</li>
                                                                                        </ul>
                                                                                    </span>
                                                                                </React.Fragment>
                                                                            }
                                                                        >
                                                                            <IconButton>
                                                                                <NotListedLocationOutlinedIcon color="sixthcolor" />
                                                                            </IconButton>
                                                                        </CustomTooltipDark>
        
                                                                        <InputAdornment position="end">
                                                                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                                                                {showPassword ? <VisibilityOff color="sixthcolor" /> : <Visibility color="sixthcolor" />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    </>
                                                            }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.password}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                    
                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            label="Status"
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            {...(fieldsError.status && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="status"
                                                            onChange={setStatus} 
                                                            value={formUsuarios.status} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {statusOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.status}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            label="Plano"
                                                            {...(fieldsError.plan_id && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="plan_id"
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            onChange={setPlan} 
                                                            value={formUsuarios.plan_id} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {plansOptions.map((plan) => (
                                                                <MenuItem key={plan.id} value={plan.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {plan.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.plan_id}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                
                                                {regId &&
                                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <CustomTextField
                                                                // required
                                                                disabled
                                                                label="Stripe Costumer ID"
                                                                // onChange={setStripeCostumer}
                                                                value={formUsuarios.stripe_id} 
                                                                name='stripe_id'
                                                                {...(regId && {disabled: 'true'})}
                                                                autoComplete='off'
                                                                {...(fieldsError.stripe_id && {error: 'true'})}
                                                                InputLabelProps={{
                                                                    sx: {
                                                                    ...inputLabelStyles,
                                                                    },
                                                                }}
                                                                size="medium"
                                                                fullWidth
                                                                inputProps={{ maxLength: 200 }}
                                                            />

                                                            <FormHelperText>
                                                                {fieldsError.stripe_id}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                }

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            label="Data de nascimento"
                                                            type="date"
                                                            size="medium"
                                                            name="born_date"
                                                            // defaultValue="2017-05-24"
                                                            {...(fieldsError.born_date && {error: 'true'})}
                                                            onChange={setBornDate}
                                                            value={formUsuarios.born_date}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.born_date}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    </div>
                                :
                                    <FormLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <>
                                {!loaderTab2 ?
                                    <div className='admin_box_historic'>
                                        <div className='admin_con_table'>
                                            <TableHistoric tableHistrcCell={tableHistrcCell} />
                                        </div>
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>
                        
                        <TabPanel value={value} index={2}>
                            <>
                                {!loaderTab3 ?
                                    <div className='admin_box_historic'>
                                        <div className='admin_con_table'>
                                            <TableAccess tableAccessCell={tableAccessCell} />
                                        </div>
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>

            {loading &&
                <div className='modal_box modal_open'>
                    <SpinnerLoader borderColorImp="var(--plat-seven)" />
                </div>
            }
        </>
    )
}

export default UsuariosForm;