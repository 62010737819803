import './style.css'
import RowCourseList from '../../../components/Laboratory/RowCourseList';
import { useEffect, useRef, useState } from 'react';
import ModalCourseDtls from '../../../components/Modal/Laboratory/ModalCourseDtls';
import MainBanner from '../../../components/Laboratory/MainBanner';
import api from '../../../services/api';
import HomeLoader from '../../../components/Loaders/Divs/Laboratory/HomeLoader';
import BannerLoader from '../../../components/Loaders/Divs/Laboratory/BannerLoader';
import { useContext } from 'react';
import { Context } from '../../../context/AuthContext';

function LaboratoryHome() {
    const { setMetaTagsOfPage, handleLogout } = useContext(Context)
    const [homeLoader, setHomeLoader] = useState(true)
    const [courseOnBanner, setCourseOnBanner] = useState({})
    // const [continueWatchingCourses, setContinueWatchingCourses] = useState([])
    const [favoriteCourses, setFavoriteCourses] = useState([])
    const [backEndCourses, setBackEndCourses] = useState([])
    const [frontEndCourses, setFrontEndCourses] = useState([])
    const [modalCourseDtlsStatus, setModalCourseDtlsStatus] = useState(false)
    const [arrowsDisplay, setArrowDisplay] = useState(true)

    let courseMouseHover = useRef('')
    let modalBlock = useRef(false);

    useEffect(() => {
        setMetaTagsOfPage('Laboratório', 'Uma plataforma de ensino feita para designers e programadores!')
        // console.log('render component LaboratoryHome')
        loadFavorites()
        loadHome()
        window.scrollTo(0, 0)

        resetCarousel()
        window.addEventListener('resize', resetCarousel);

        return () => {
            window.removeEventListener('resize', resetCarousel);
        }
    }, [])

    const resetCarousel = () => {
        let carousels = document.getElementsByClassName('row_group')

        if (carousels?.length) {
            for(let i = 0; i <= carousels?.length; i++) {
                if (carousels[i]) {
                    carousels[i].scrollLeft = 0
                }
            }
        }
    }

    async function loadHome() {
        await api.get('home-lab')
            .then((response) => {
                if (response.data.status === 'success') {
                    setCourseOnBanner(response.data.data.banner_course)
                    // setContinueWatchingCourses(response.data.data.backend_courses)
                    setBackEndCourses(response.data.data.backend_courses)
                    setFrontEndCourses(response.data.data.frontend_courses)
                    setHomeLoader(false)
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    async function loadFavorites() {
        await api.get('favorite-lab')
            .then((response) => {
                if (response.data.status === 'success') {
                    setFavoriteCourses(response.data.data.favorite_courses)
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    return (
        <div className="course_videos">
            {homeLoader ?
                <BannerLoader/>
            :
                <MainBanner course={courseOnBanner} linkTo={`curso/${courseOnBanner.link_category}/${courseOnBanner.link_subcategory}/${courseOnBanner.slug}`}/>
            }
            
            {homeLoader ?
                <HomeLoader/>
            :
                <div className='crs_vid_content_all'>
                    {/* <RowCourseList arrowsDisplay={arrowsDisplay} rowTitle="Continuar Assistindo" rowId="1" courses={continueWatchingCourses} modalCourseDtlsStatus={modalCourseDtlsStatus} setModalCourseDtlsStatus={setModalCourseDtlsStatus} courseMouseHover={courseMouseHover} modalBlock={modalBlock} /> */}
                    
                    {favoriteCourses?.length >= 1 &&
                        <RowCourseList arrowsDisplay={arrowsDisplay} rowTitle="Meus cursos favoritos" rowId="1" courses={favoriteCourses} modalCourseDtlsStatus={modalCourseDtlsStatus} setModalCourseDtlsStatus={setModalCourseDtlsStatus} courseMouseHover={courseMouseHover} modalBlock={modalBlock} />
                    }

                    <RowCourseList arrowsDisplay={arrowsDisplay} rowTitle="Front-End" rowId="2" courses={frontEndCourses} modalCourseDtlsStatus={modalCourseDtlsStatus} setModalCourseDtlsStatus={setModalCourseDtlsStatus} courseMouseHover={courseMouseHover} modalBlock={modalBlock} />
                    <RowCourseList arrowsDisplay={arrowsDisplay} rowTitle="Back-End" rowId="3" courses={backEndCourses} modalCourseDtlsStatus={modalCourseDtlsStatus} setModalCourseDtlsStatus={setModalCourseDtlsStatus} courseMouseHover={courseMouseHover} modalBlock={modalBlock} />
                </div>
            }

            <ModalCourseDtls setArrowDisplay={setArrowDisplay} modalStatus={modalCourseDtlsStatus} setModalStatus={setModalCourseDtlsStatus} modalBlock={modalBlock} courseMouseHover={courseMouseHover} />
        </div>
    )
}

export default LaboratoryHome;