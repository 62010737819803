import '../../style.css'
import Logo from '../../../../../assets/imgs/logos/logoeduteka.png'
import { Context } from '../../../../../context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import api from '../../../../../services/api';
import { useHistory } from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';
import StepCreateUser from '../../../../../components/PaymentWithStripe/Steps/General/StepCreateUser'
import StepShowPlans from '../../../../../components/PaymentWithStripe/Steps/Subscription/StepShowPlans';
import StepAlertPayment from '../../../../../components/PaymentWithStripe/Steps/General/StepAlertPayment';
import StepAlertSuccessfullyPaid from '../../../../../components/PaymentWithStripe/Steps/General/StepAlertSuccessfullyPaid';
import StepFirstPayment from '../../../../../components/PaymentWithStripe/Steps/Subscription/StepFirstPayment';
import { LoadingButton } from '@mui/lab';
import StepReactivateDefaultPayment from '../../../../../components/PaymentWithStripe/Steps/General/StepReactivateDefaultPayment';
import ModalLogin from '../../../../../components/Modal/General/ModalLogin';
import StepVerifyHasActivePlanOrBuyThisCourse from '../../../../../components/PaymentWithStripe/Steps/General/StepVerifyHasActivePlanOrBuyThisCourse';
import StepReactivateChangeDefaultPayment from '../../../../../components/PaymentWithStripe/Steps/General/StepReactivateChangeDefaultPayment';

function Payment() {
    const { authenticated, setMetaTagsOfPage, handleLogout } = useContext(Context)
    const history = useHistory()
    const [plans, setPlans] = useState([])
    const [planSelected, setPlanSelected] = useState()
    const [currentStep, setCurrentStep] = useState(1)
    const [user, setUser] = useState('')
    const [modalLoginStatus, setModalLoginStatus] = useState(false)
    const [userCards, setUserCards] = useState()

    //stripe
    const [stripePromise, setStripePromise] = useState(null)
    const [clientSecret, setClientSecret] = useState(null)

    useEffect(() => {
        setMetaTagsOfPage('Complete a sua matrícula na Eduteka', 'Uma plataforma de ensino feita para designers e programadores!')

        //Stripe
        loadPublishableKey()
    }, [])

    useEffect(() => {
        //Se o usuário se autenticou, ou fez logou no meio do processo do checkou eu forço ele a voltar pro passo 1
        setCurrentStep(1)

        // Checa se o usuário está logado
        if (authenticated) {
            getDataOfUserLogged()
        }
    }, [authenticated])

    useEffect(() => {
        if (user?.id) {
            //Cria uma intenção de pagamento no servidor
            createSetupIntent()
        }
    }, [user]);

    // useEffect(() => {
    //     loadCourse()

    //     window.scrollTo(0, 0)
    // }, [courseSlug])

    // async function loadCourse() {
    //     await api.get(`curso/lp/${courseSlug}`)
    //         .then((response) => {
    //             if (response.data.status === 'success') {
    //                 setCourse(response.data.data.course)
    //             } else {
    //                 history.push("/erro/404");
    //             }
    //         }).catch((error) => {
    //             if (error.response.status === 404) {
    //                 history.push("/erro/404");
    //             }
    //         })
    // }
    
    function loadPublishableKey() {
        api.get(`/stripe/config`)
            .then((response) => {
                if (response.data.status === 'success') {
                    setStripePromise(loadStripe(response.data.data.publishable_key))
                } else {
                    history.push("/erro/404")
                }
            })
            .catch((error) => {
                // if (error.response.status === 401) {
                //     handleLogout()
                // }
            })
    }

    //Cria uma intenção de pagamento
    function createSetupIntent() {
        api.post('assinatura/create-setup-intent', {usrid: user?.id})
            .then((response) => {
                if (response.data.status === 'success') {
                    setClientSecret(response.data.data.client_secret);
                }
            }).catch((error) => {
                // if (error.response.status === 401) {
                //     handleLogout()
                // }
            })
    }

    //Recupera os dados do usuário logado
    const getDataOfUserLogged = () => {
        let userRecovery = JSON.parse(localStorage?.getItem('user'));
        setUser({id: userRecovery.id, name: userRecovery.name})        
    }

    return (
        <>
            <div className='payment_header'>
                <div className='website_pay_header_container'>
                    {/* <Link to="/"> */}
                        <img className="logo" src={Logo} width={98} alt="Eduteka" title="Eduteka"/>
                    {/* </Link> */}
                    
                    {!authenticated ?
                        <>
                            {!user &&
                                <LoadingButton
                                    onClick={() => setModalLoginStatus(!modalLoginStatus)}
                                    // endIcon={<PsychologyAltOutlinedIcon />}
                                    // loading={spinnerLoaderBtn}
                                    // loadingPosition="end"
                                    variant="outlined"
                                    color="secondary"
                                >
                                    <span>Logar</span>
                                </LoadingButton>
                            }
                        </>
                    :
                        <LoadingButton
                            onClick={() => handleLogout(null, false)}
                            // endIcon={<PsychologyAltOutlinedIcon />}
                            // loading={spinnerLoaderBtn}
                            // loadingPosition="end"
                            variant="outlined"
                            color="secondary"
                        >
                            <span>Sair</span>
                        </LoadingButton>
                    }
                </div>
            </div>

            {!authenticated ?
                <>
                    {currentStep === 1 &&
                        <StepShowPlans 
                            plans={plans}
                            setPlans={setPlans}
                            planSelected={planSelected}
                            setPlanSelected={setPlanSelected}
                            stepNow={1} 
                            stepTotals={4} 
                            nextStep={() => setCurrentStep(2)} 
                        />
                    }

                    {currentStep === 2 &&
                        <StepCreateUser 
                            setUser={setUser} 
                            planId={planSelected?.id} 
                            stepNow={2} 
                            stepTotals={4} 
                            nextStep={() => setCurrentStep(3)} 
                        />
                    }

                    {currentStep === 3 &&
                        <StepAlertPayment 
                            stepNow={3} 
                            stepTotals={4}
                            user={user}
                            nextStep={() => setCurrentStep(4)} 
                        />
                    }

                    {currentStep === 4 &&
                        <StepFirstPayment
                            clientSecret={clientSecret}
                            stripePromise={stripePromise}
                            setPlanSelected={setPlanSelected}
                            plans={plans}
                            planSelected={planSelected}
                            stepNow={4} 
                            stepTotals={4} 
                            userId={user?.id}
                            nextStep={() => setCurrentStep(5)} 
                        />
                    }

                    {currentStep === 5 &&
                        <StepAlertSuccessfullyPaid user={user} />
                    }
                </>
            :
                <>
                    {currentStep === 1 &&
                        <StepVerifyHasActivePlanOrBuyThisCourse 
                            stepNow={1} 
                            stepTotals={4} 
                            checkType='plan'
                            setUserCards={setUserCards}
                            user={user}
                            nextStep={() => setCurrentStep(2)} 
                        />
                    }

                    {currentStep === 2 &&
                        <StepShowPlans 
                            plans={plans}
                            setPlans={setPlans}
                            planSelected={planSelected}
                            setPlanSelected={setPlanSelected}
                            stepNow={2} 
                            stepTotals={4} 
                            nextStep={() => setCurrentStep(3)} 
                        />
                    }

                    {currentStep === 3 &&
                        <>
                            {userCards ?
                                <StepReactivateDefaultPayment
                                    stepNow={3} 
                                    stepTotals={4}
                                    plans={plans}
                                    planSelected={planSelected}
                                    setPlanSelected={setPlanSelected}
                                    userCards={userCards}
                                    setUserCards={setUserCards}
                                    nextStep={() => setCurrentStep(5)}
                                    changeDefaultPayStep={() => setCurrentStep(4)}
                                    typeCheckout='subscription'
                                />
                            : 
                                <StepFirstPayment
                                    clientSecret={clientSecret}
                                    stripePromise={stripePromise}
                                    setPlanSelected={setPlanSelected}
                                    plans={plans}
                                    planSelected={planSelected}
                                    stepNow={3} 
                                    stepTotals={4} 
                                    userId={user?.id}
                                    nextStep={() => setCurrentStep(5)} 
                                />
                            }
                        </>
                    }

                    {currentStep === 4 &&
                        <StepReactivateChangeDefaultPayment 
                            backToBeforeStep={() => setCurrentStep(3)}
                            nextStep={() => setCurrentStep(5)}
                            clientSecret={clientSecret}
                            stripePromise={stripePromise}
                            typeCheckout='subscription'
                        />
                    }

                    {currentStep === 5 &&
                        <StepAlertSuccessfullyPaid user={user} />
                    }
                </>
            }

            <ModalLogin modalStatus={modalLoginStatus} setModalStatus={setModalLoginStatus} />
        </>
    )
}

export default Payment;