import { Link, useHistory } from "react-router-dom"
import '../style.css'
import { Grid } from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { CustomLoadingButtonOutlined } from '../../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButtonOutlined'
import { redirectToWithEvent } from "../../../../helpers";

function TitleTableBox({ title }) {
    const history = useHistory()

    return (
        <div className='admin_cont_title'>
            <div className='admin_tit'>
                <div className="admin_link_back">
                    <Link className="admin_link_from" to='/admin/dashboard'>Dashboard</Link>
                </div>

                <h1>{title.name}</h1>
                <p>{title.description}</p>
            </div>

            <div className='admin_btns'>
                <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomLoadingButtonOutlined
                            onClick={(e) => redirectToWithEvent(history, e, title.link_to)}
                            endIcon={<AddOutlinedIcon />}
                            loadingPosition="end"
                            variant="outlined"
                            color="secondary"
                            fullWidth
                        >
                            <span>Incluir</span>
                        </CustomLoadingButtonOutlined>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default TitleTableBox