import React, { useState, useEffect, memo, useContext } from 'react'
import api from '../../../services/api'
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import { Context } from '../../../context/AuthContext';

const FavIcon = memo(({courseId, sizeIcon = 'medium', sxIcon = {}}) => {
    const [isFavorite, setIsFavorite] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const { handleLogout } = useContext(Context)

    useEffect(() => {
        // console.log('render component FavIcon')
    }, [])

    useEffect(() => {
        if (courseId) {
            getData()
        }
    }, [])

    //Carregar se o curso foi favoritado pelo usuário
    async function getData() {
        // setIsLoading(true)

        // console.log(`BUSCA NA API - ${courseId}`)
        await api.get(`favorite-course/${courseId}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    setIsFavorite(response.data.data.is_favorite)
                } 

                setIsLoading(false)
            }).catch((error) => {
                setIsLoading(false)

                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    async function markAsFavorite(e) {
        e.preventDefault()
        setIsLoading(true)

        await api.post(`favorite-course/${courseId}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    setIsFavorite(!isFavorite)
                    toast.success(response.data.message)
                } else {
                    toast.error(response.data.message)
                }

                setIsLoading(false)
            }).catch((error) => {
                toast.error('Falha ao alterar curso!')
                setIsLoading(false)

                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }
    
    return (
        <div id="fav-icon-favorite">
            {isLoading ?
                <CircularProgress sx={{width:"24px !important", height:"24px !important", color: "var(--plat-twelve)"}} />
            :
                <>
                    {isFavorite ?
                        // style={{pointerEvents: 'none'}}
                        <FavoriteIcon id="fav-icon-favorite" color='fifthcolor' titleAccess='Desfavoritar' fontSize={sizeIcon} onClick={(e) => markAsFavorite(e)} sx={{...sxIcon, ...{cursor:"pointer"}}} />
                    :
                        <FavoriteBorderIcon id="fav-icon-favorite" color='fifthcolor' titleAccess='Favoritar' fontSize={sizeIcon} onClick={(e) => markAsFavorite(e)} sx={{...sxIcon, ...{cursor:"pointer"}}} />
                    } 
                </>
            }
        </div>
    )
}, (prevProps, nextProps) => { // Compara se mudou as props e se precisa renderizar novamente
    if (prevProps.courseId === nextProps.courseId) {
        return true; // props are equal
    }

    return false; // props are not equal -> update the component
})

export default FavIcon