import '../style.css'
import './style.css'
import React, { useContext } from 'react'
import { Context } from '../../../context/AuthContext'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Grid } from '@mui/material'
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { CustomLoadingButton } from '../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CancelScheduleSendOutlinedIcon from '@mui/icons-material/CancelScheduleSendOutlined';

function ModalCancelUserPlan({modalStatus, setModalStatus, userPlanCurrent, setUserPlanCurrent}) {
    const { loading, setLoading, handleLogout } = useContext(Context)

    function handleCancel(e) {
        e.preventDefault()
        setModalStatus(false)
    }

    async function handleCancelSignature() {
        setLoading(true)

        await api.post('assinatura/costumer/cancel-user-plan')
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)

                    setUserPlanCurrent({...userPlanCurrent, ...{
                        status: response.data.data.signature_status, 
                        signature_ends_at: response.data.data.signature_ends_at
                    }})

                    setModalStatus(false)
                } else {
                    toast.error(response.data.message)
                }

                setLoading(false)
            }).catch((error) => {
                if (error.response.status === 422) {
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                }

                setLoading(false)
            })
    }

    return (
        <div className={`modal_box ${modalStatus && 'modal_open'}`}>

            <div className='modal mdl_blk'>
                <div className="modal_content">
                    <div className='modal_header mdl_blk'>
                        <div className='modtitbox'>
                            <CancelOutlinedIcon color='neutral' sx={{marginRight:"12px"}} fontSize="large" />
                            <h3 className='mdl_blk'>Cancelar assinatura</h3>
                        </div>

                        <CloseOutlinedIcon onClick={(e) => handleCancel(e)} color='neutral' sx={{cursor:"pointer"}}/>
                    </div>

                    <div className="modal_content_middle">
                        <p className='mdl_blk'>Tem certeza que deseja cancelar sua assinatura?</p>
                    </div>

                    <div className="modal_footer">
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CustomLoadingButton
                                    onClick={() => handleCancelSignature()}
                                    endIcon={<CancelScheduleSendOutlinedIcon />}
                                    loading={loading}
                                    loadingPosition="end"
                                    variant="contained"
                                    fullWidth
                                    color="secondary"
                                >
                                    <span>Cancelar assinatura</span>
                                </CustomLoadingButton>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default ModalCancelUserPlan;