import { LoadingButton } from '@mui/lab';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import SlowMotionVideoOutlinedIcon from '@mui/icons-material/SlowMotionVideoOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FavIcon from '../FavIcon';
import styled from 'styled-components';
import Stack from '@mui/material/Stack';
import { StyledRating } from "../../../components/MaterialCustom/Laboratory/Rating/CustomRating";
import api from "../../../services/api";
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { Context } from '../../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { redirectTo } from '../../../helpers';

const StyledDivMainBanner = styled.div`
    background: linear-gradient(to left, rgba(17, 17, 17, .2) 50%, var(--plat-one)), 
        linear-gradient(rgba(17, 17, 17, .7) 50%, var(--plat-one)), 
        url(${(props) => props.thumb}) right center / cover; 
        //url("https://res.cloudinary.com/hvzbb2hdx/image/upload/c_scale,f_auto,w_1600/v1/banners/main/image/b59a83c0-ffd6-11ed-9e4c-c795cffd6c3c") right center / cover;}

    @media (min-width: 990px) {
        background: linear-gradient(to left, rgba(17, 17, 17, 0) 40%, var(--plat-one)), 
            linear-gradient(rgba(17, 17, 17, 0) 20%, var(--plat-one)), 
            url(${(props) => props.thumb}) center center / cover; 
            /* url("https://res.cloudinary.com/hvzbb2hdx/image/upload/c_scale,f_auto,w_1600/v1/banners/main/image/b59a83c0-ffd6-11ed-9e4c-c795cffd6c3c") center center / cover; */
    }
`

function MainBanner ({course, textBtn = null, linkTo, disableBtn = false}) {
    const { handleLogout } = useContext(Context)
    const history = useHistory()

    async function changeUsrEvaluation(evalValue, courseId) {
        await api.post(`evaluation-course/${courseId}`, {eval: evalValue})
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                } else {
                    toast.error(response.data.message)
                }
            }).catch((error) => {
                toast.error('Falha ao enviar avaliação!')

                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    return (
        <StyledDivMainBanner className="labor_main_banner" thumb={course.thumb}>
            <span>{course.subcategory}</span>
            <h1>{course.description}</h1>

            <div className='banner_box_dtls'>
                <div>
                    <CalendarMonthOutlinedIcon color='fifthcolor'/> 
                    <span className="banner_dtls">{course.publication_date}</span> 
                </div>

                <div>
                    <SlowMotionVideoOutlinedIcon color='fifthcolor'/> 
                    <span className="banner_dtls">{course.number_of_classes} Aulas</span> 
                </div>

                <div>
                    <FavIcon courseId={course.id} /*sizeIcon="medium" sxIcon={{color: "var(--plat-twelve)"}}*/ />
                </div>

                <Stack spacing={1} className='stack_margin'>
                    <StyledRating 
                        title='Sua Avaliação' 
                        name="half-rating-read" 
                        defaultValue={course.usr_evaluation}
                        onChange={(event, newValue) => {
                            changeUsrEvaluation(newValue, course.id);
                        }}
                        precision={0.5} 
                        size="medium" 
                    />
                </Stack>
            </div>

            <p>{course.summary}</p>

            {!disableBtn ?
                <LoadingButton
                    onClick={() => redirectTo(history, linkTo)}
                    
                    // endIcon={<PsychologyAltOutlinedIcon />}
                    startIcon={<PlayArrowIcon color="primary"/>}
                    className='lbtn_labor'
                    variant="contained"
                    color="neutral"
                    size="large"
                >
                    <span className="labor_btn">{textBtn ? textBtn : 'Assistir'}</span>
                </LoadingButton>
            :
                <p className='crs_concluded'>Parabéns, você concluiu esse curso!</p>
            }
        </StyledDivMainBanner>
    )
}

export default MainBanner;