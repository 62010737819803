import '../style.css'
import './style.css'
import React, { useState, useContext } from 'react'
import { Context } from '../../../context/AuthContext'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import { Grid, FormControl } from '@mui/material'
import Form from '../../Form'
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { CustomLoadingButton } from '../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';
import { CustomTextField, inputLabelStyles } from '../../MaterialCustom/Laboratory/TextField/CustomTextField';

function ModalForgetPassword({modalStatus, setModalStatus}) {
    const { loading, setLoading } = useContext(Context)
    const [email, setEmail] = useState('')

    function handleCancel(e) {
        e.preventDefault()
        setModalStatus(false)
    }

    async function handleForgetPassword() {
        setLoading(true)
        // setModalStatus(false)

        //disparar request de redefinição de senha
        await api.post('esqueceu-senha', {account_email:email})
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    setEmail('')
                    setModalStatus(false)
                } else {
                    toast.error(response.data.message)
                }

                setLoading(false)
            }).catch((error) => {
                if (error.response.status === 422) {
                    toast.error(error.response.data.message)
                }

                setLoading(false)
            })
    }

    return (
        <div className={`modal_box ${modalStatus && 'modal_open'}`}>

            <div className='modal mdl_blk'>
                <div className="modal_content">
                    <div className='modal_header mdl_blk'>
                        <div className='modtitbox'>
                            <PasswordOutlinedIcon color='neutral' sx={{marginRight:"12px"}} fontSize="large" />
                            <h3 className='mdl_blk'>Esqueceu sua senha?</h3>
                        </div>

                        <CloseOutlinedIcon onClick={(e) => handleCancel(e)} color='neutral' sx={{cursor:"pointer"}}/>
                    </div>

                    <div className="modal_content_middle">
                        <p className='mdl_blk'>Após enviar a solicitação aguarde alguns instantes que enviaremos um e-mail para você redefinir sua senha.</p>

                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormControl variant="outlined" fullWidth>
                                        <CustomTextField
                                            label="E-mail" 
                                            onChange={(e) => setEmail(e.target.value)}
                                            autoComplete='off'
                                            value={email}
                                            name='name'
                                            size="medium"
                                            InputLabelProps={{
                                                sx: {
                                                ...inputLabelStyles,
                                                },
                                            }}
                                            // {...(fieldsError.name && {error: 'true'})}
                                            fullWidth
                                            inputProps={{ maxLength: 200 }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Form>

                    </div>

                    <div className="modal_footer">
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CustomLoadingButton
                                    onClick={() => handleForgetPassword()}
                                    endIcon={<ScheduleSendOutlinedIcon />}
                                    loading={loading}
                                    loadingPosition="end"
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    color="secondary"
                                >
                                    <span>Solicitar redefinição de senha</span>
                                </CustomLoadingButton>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>

        </div> 
    )
}

export default ModalForgetPassword;