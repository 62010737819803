const appearance = {
    theme: 'night',
    variables: { 
        borderRadius: '4px',
        colorBackground: '#222222', //COR INPUT
        fontFamily: 'AlbertSans, Roboto, Segoe UI, Arial',
        colorPrimary: '#333333', //BORDA DO INPUT
        colorText: '#FAFAFA', //COR DO TEXTO DO INPUT
        colorTextPlaceholder: '#989898',  //COR DO PLACEHOLDER DO INPUT
    },
    rules: {
        '.Input': {
            padding: '16.5px 14px',
            boxShadow: 'none'
        },
        '.Input:focus': {
            boxShadow: 'none',
            backgroundColor: "rgba(255, 255, 255, .04)",  //var( --plat-four)
        },
        '.Label': {
            color: 'rgba(255, 255, 255, .9)' //var(--plat-six)
        },
        '.Error': {
            color: 'rgba(237, 67, 55, .9)', //var(--error-color)
        },
        '.Input--invalid': {
            color: 'rgba(237, 67, 55, .9)',  //var(--error-color)
            borderColor: 'rgba(237, 67, 55, .9)'  //var(--error-color)
        }
    }
};

const paymentElementOptions = {
    layout: "tabs", //"accordion"
    defaultCollapsed: false,
}

export { appearance, paymentElementOptions }