import { CircularProgress } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import './style.css'
import api from '../../../../../services/api';
import { CustomLoadingButton } from '../../../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';
import { Context } from '../../../../../context/AuthContext';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

function StepShowPlans ({plans, setPlans, planSelected, setPlanSelected, nextStep, stepNow = null, stepTotals = null }) {
    const { handleLogout, authenticated } = useContext(Context)
    const [stepLoader, setStepLoader] = useState(false)

    useEffect(() => {
        //Carrega Planos
        loadData()
    }, [])

    async function loadData() {
        await api.get('plans')
            .then((response) => {
                if (response.data.status === 'success') {
                    setPlans(response.data.data.plans)
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    function changeSelectedPlan(plan) {
        setPlanSelected(plan)
    }

    async function sendStep() {
        setStepLoader(true)

        if (authenticated) {
            //Manda o Plano Selecionado pelo Usuário pro back-end
            await onChangePlan()
        } else {
            setTimeout(() => {
                nextStep()
                setStepLoader(false)
            }, 300)
        }
    }

    async function onChangePlan() {
        await api.post('assinatura/costumer/change-user-plan', {plan_id: planSelected?.id})
            .then((response) => {
                if (response.data.status === 'success') {
                    nextStep()
                    setStepLoader(false)
                    // toast.success(response.data.message)
                } else {
                    // toast.error(response.data.message)
                }
            }).catch((error) => {
                setStepLoader(false)

                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    return (
        <div className='payment_box_small_msg'>
            <div className='payment_box_message'>
                <span className='step_payment'>PASSO {stepNow} de {stepTotals}</span>
                
                <h1>Escolha o plano que se encaixa melhor com seu objetivo!</h1>
                <p>Altere ou cancele seu plano quando quiser.</p>

                <div className='payment_select_plan'>
                    {plans.length > 1 ?
                        <>
                            {plans.map((plan, index) => {
                                return (
                                    <div className='box_plan' key={index} onClick={() => changeSelectedPlan(plan)}>
                                        <div className='a_plan_head'>
                                            <div className='b_plan_header'>
                                                <span>Plano</span>
                                                <h4>{plan.name}</h4>
                                            </div>

                                            {(planSelected && planSelected.id === plan.id) &&
                                                <span>ESCOLHIDO</span>
                                            }
                                        </div>

                                        <div className='b_plan_middle'>
                                            <h5>{plan.price === 0 ? 
                                                'Gratuito' 
                                            : 
                                                `R$ ${plan.price_pretty} / Mês`
                                            }</h5>
                                        </div>

                                        <div className='b_plan_content'>
                                            <ul>
                                                {plan.plan_itens.map((planItem, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <DoneOutlinedIcon color={planItem.status === 1 ? 'secondary' : 'sixthcolor'}/>
                                                            <span>{planItem.description}</span>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    :
                        <CircularProgress sx={{width:"34px !important", height:"34px !important", color: "var(--plat-seven)"}} />
                    }
                </div>

                <CustomLoadingButton
                    onClick={() => sendStep()}
                    loading={stepLoader}
                    disabled={!planSelected}
                    variant="contained"
                    fullWidth
                    sx={{width: '300px'}}
                    color='secondary'
                    size="large"
                >
                    <span>Próximo</span>
                </CustomLoadingButton>
            </div>
        </div>
    )
}

export default StepShowPlans;