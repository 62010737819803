import '../style.css'
import './style.css'
import React, { useState, useContext, useEffect, useRef } from 'react'
import { Context } from '../../../context/AuthContext'
import ModalForgetPassword from './ModalForgetPassword'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Grid, FormControl } from '@mui/material'
import LoginIcon from '@mui/icons-material/Login';
import Form from '../../Form'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { CustomTextField, inputLabelStyles } from '../../MaterialCustom/Laboratory/TextField/CustomTextField'
import { CustomLoadingButton } from '../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton'

function ModalLogin({modalStatus, setModalStatus}) {
    const { handleLogin, loading } = useContext(Context)
    const [modalForgetPassStatus, setModalForgetPassStatus] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    let btnLogin = useRef()

    const [userLogin, setUserLogin] = useState({
        username: '',
        password: ''
    })

    useEffect(() => {
        const keyDownHandler = event => {
            // console.log('User pressed: ', event.key);

            if (event.key === 'Enter' && modalStatus) {
                event.preventDefault();

                //Clica no botão de login ao pressionar ENTER
                btnLogin.current.click()
            }
        };
    
        document.addEventListener('keydown', keyDownHandler);
    
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
      }, [modalStatus]);

    function handleCancel(e) {
        e.preventDefault()
        setModalStatus(false)
    }

    function openModalForget() {
        setModalStatus(false)
        setModalForgetPassStatus(!modalForgetPassStatus)
    }

    function handleClickShowPassword() {
        setShowPassword(!showPassword)
    }

    function handleMouseDownPassword(event) {
        event.preventDefault();
    }

    return (
        <>
            <div className={`modal_box ${modalStatus && 'modal_open'}`}>

                <div className='modal mdl_blk'>
                    <div className="modal_content">
                        <div className='modal_header mdl_blk'>
                            <div className='modtitbox'>
                                <SchoolOutlinedIcon color='neutral' sx={{marginRight:"12px"}} fontSize="large" />
                                <h3 className='mdl_blk'>Bora estudar?</h3> 
                            </div>

                            <CloseOutlinedIcon onClick={(e) => handleCancel(e)} color='neutral' sx={{cursor:"pointer"}}/>
                        </div>

                        <div className="modal_content_middle">

                            <Form>
                                <Grid container spacing={2} sx={{marginBottom:"20px"}}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl variant="outlined" fullWidth>
                                            <CustomTextField
                                                label="E-mail" 
                                                onChange={(e) => setUserLogin({...userLogin, username: e.target.value})} 
                                                autoComplete='off'
                                                value={userLogin.email}
                                                name='name'
                                                size="medium"
                                                InputLabelProps={{
                                                    sx: {
                                                    ...inputLabelStyles,
                                                    },
                                                }}
                                                // {...(fieldsError.name && {error: 'true'})}
                                                fullWidth
                                                inputProps={{ maxLength: 200 }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl variant="outlined" fullWidth> 
                                            <CustomTextField 
                                                id="outlined-adornment-password" 
                                                autoComplete='new-password'
                                                type={showPassword ? 'text' : 'password'} 
                                                label="Senha"
                                                size="medium"
                                                fullWidth
                                                onChange={(e) => setUserLogin({...userLogin, password: e.target.value})}
                                                value={userLogin.password}
                                                InputLabelProps={{
                                                    sx: {
                                                    ...inputLabelStyles,
                                                    },
                                                }}
                                                InputProps={{ 
                                                    maxLength: 200,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                                                {showPassword ? <VisibilityOff color="sixthcolor" /> : <Visibility color="sixthcolor" />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Form>
                         
                            <div className="box_forget">
                                <span className='forget fgt_dark' onClick={() => openModalForget()}>Esqueceu sua senha?</span>
                            </div>

                        </div>

                        <div className="modal_footer">
                            <Grid container >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CustomLoadingButton
                                        id="send-btn-login"
                                        ref={btnLogin}
                                        onClick={(event) => handleLogin(event, userLogin, setModalStatus)}
                                        endIcon={<LoginIcon />}
                                        loading={loading}
                                        loadingPosition="end"
                                        variant="contained"
                                        size="large"
                                        fullWidth
                                        color="secondary"
                                    >
                                        <span>Logar</span>
                                    </CustomLoadingButton>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>

            </div>

            <ModalForgetPassword modalStatus={modalForgetPassStatus} setModalStatus={setModalForgetPassStatus} />
        </>
    )
}

export default ModalLogin;