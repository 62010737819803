import { useContext, useEffect, useState } from 'react';
import { CustomLoadingButton } from '../../../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';
import SpinnerLoader from '../../../../Loaders/SpinnerLoader';
import api from '../../../../../services/api';
import { Context } from '../../../../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { prettyUserName, redirectTo, redirectToAnExternalLink } from '../../../../../helpers';

function StepVerifyHasActivePlanOrBuyThisCourse ({nextStep, stepNow = null, stepTotals = null, setUserCards = null, course = null, checkType, user}) {
    const [stepLoader, setStepLoader] = useState(false)
    const [checkLoader, setCheckLoader] = useState(true)
    const [userHasPlan, setUserHasPlan] = useState(false)
    const [userHasCourseOnPlan, setUserHasCourseOnPlan] = useState(false)
    const [userHasCourseOnPlanDowngrade, setUserHasCourseOnPlanDowngrade] = useState(false)
    const [userPurchasedTheCourse, setUserPurchasedTheCourse] = useState(false)
    const { authenticated, handleLogout } = useContext(Context)
    const history = useHistory()
    const [userRecovery, setUserRecovery] = useState();

    useEffect(() => {
        //Recupera os dados do usuário logado
        getDataOfUserLogged()
    }, [])

    useEffect(() => {
        //Checagem do plano e dos cursos adquiridos pelo usuário
        if (userRecovery && ((checkType === 'course' && course) || checkType === 'plan')) {
            checkUserPlanAndPurchasedCourses()
        }
    }, [userRecovery, checkType, course])

    function getDataOfUserLogged(){
        let userRecov = JSON.parse(localStorage?.getItem('user'))
        setUserRecovery(userRecov)
    }

    function checkUserPlanAndPurchasedCourses(){
        if (checkType === 'plan') { 

            //Verifica se o usuário já possui um PLANO ativo
            if (userRecovery?.has_an_active_plan && userRecovery?.plan) {
                setUserHasPlan(true)
            }
        
        } else if (checkType === 'course') {

            //Verifica se o usuário possui o CURSO disponível no seu plano (somente cursos grátis)
            if ((course?.is_free === 1 && userRecovery?.has_an_active_plan && userRecovery?.plan) || (course?.is_free === 2 && userRecovery?.has_an_active_plan && userRecovery?.plan !== 'Free') || (userRecovery?.is_teacher && userRecovery?.plan === 'Teacher')) {
                setUserHasCourseOnPlan(true)
            }

            //Verifica se o usuário possui o CURSO disponível no seu plano (o plano apesar de ser FREE está em processo de downgrade, o usuário tinha um plano pago anteriormente. O usuário ainda possui permissão do plano pago até o final do prazo de renovação)
            if ((course?.is_free === 2 && userRecovery?.has_an_active_plan && userRecovery?.plan === 'Free' && userRecovery?.plan_downgrade === true)) {
                setUserHasCourseOnPlanDowngrade(true)
            }
            
            //Verifica se o CURSO(pago ou gratuito) já foi adquirido anteriormente pelo usuário, e se o acesso está dentro do período de validade
            else if (userRecovery?.has_an_active_product) {
                checkIfTheUserPurchasedTheCourse()
            }

        }

        // Libero a tela com delay, pois se não ele pode renderizar incorretamente por causa da modificação constante de states
        setTimeout(() => {
            setCheckLoader(false)
        }, 1000)
    }

    async function checkIfTheUserPurchasedTheCourse() {
        await api.get('purchase/costumer/check-user-has-course-enabled', {params: {course_id: course?.id}})
            .then((response) => {
                if (response.data.status === 'success') {
                    setUserPurchasedTheCourse(response.data.data.user_has_course)
                }
            }).catch((error) => {
                if (error.response?.status === 401) {
                    handleLogout()
                }
            })
    }

    async function retrieveUserCards() {
        await api.get('costumer/user-payment-methods')
            .then((response) => {
                if (response.data.status === 'success') {
                    setUserCards(response.data.data.cards)
                    nextStep()
                }

                setStepLoader(false)
            }).catch((error) => {
                setStepLoader(false)

                if (error.response?.status === 401) {
                    handleLogout()
                }
            })
    }

    async function sendStep() {
        setStepLoader(true)

        if (authenticated) {
            await retrieveUserCards()
        } else {
            setTimeout(() => {
                nextStep()
                setStepLoader(false)
            }, 300)
        }
    }

    return (
        <div className='payment_box_big_msg'>
            <div className='payment_box_message'>
                {(!checkLoader && (!userHasPlan && !userHasCourseOnPlan && !userPurchasedTheCourse)) && 
                    <span className='step_payment'>PASSO {stepNow} de {stepTotals}</span>
                }

                {authenticated ?
                    <h1>Que bom ver você {user?.name ? prettyUserName(user.name) : user.name}!</h1>
                :
                    <h1>Olá {user?.name ? prettyUserName(user.name) : user.name}!</h1>
                }

                {checkLoader ?
                    <>
                        <p>Só mais um pouco, estamos validando seu acesso...</p>
                        <SpinnerLoader borderColorImp="var(--plat-seven)" />
                    </>
                :
                    <>
                        {userHasPlan &&
                            <>
                                <p>Reparamos que você possui o <b>Plano {userRecovery.plan}</b> ativo, caso você queira fazer alterações no seu plano recomendamos acessar a tela de perfil.</p>
                                
                                <CustomLoadingButton
                                    onClick={() => redirectTo(history, '/meu-perfil')}
                                    variant="contained"
                                    fullWidth
                                    color='secondary'
                                    size="large"
                                >
                                    <span>Quero alterar meu Plano</span>
                                </CustomLoadingButton>
                            </>
                        }

                        {userHasCourseOnPlan &&
                            <>
                                <p>Reparamos que você possui o <b>Plano {userRecovery.plan}</b>, seu plano já garante acesso ao curso.</p>
                                
                                <CustomLoadingButton
                                    onClick={() => redirectToAnExternalLink(`${process.env.REACT_APP_URL_BASE_LABORATORY}curso/${course?.link_category}/${course?.link_subcategory}/${course?.slug}`)}
                                    variant="contained"
                                    fullWidth
                                    color='secondary'
                                    size="large"
                                >
                                    <span>Quero Acessar o Curso</span>
                                </CustomLoadingButton>
                            </>
                        }

                        {userHasCourseOnPlanDowngrade &&
                            <>
                                <p>Reparamos que seu plano está em processo de <b>downgrade</b>, por enquanto você ainda possui as permissões do seu plano anterior, o que garante acesso a esse curso.</p>
                                
                                <CustomLoadingButton
                                    onClick={() => redirectToAnExternalLink(`${process.env.REACT_APP_URL_BASE_LABORATORY}curso/${course?.link_category}/${course?.link_subcategory}/${course?.slug}`)}
                                    variant="contained"
                                    fullWidth
                                    color='secondary'
                                    size="large"
                                >
                                    <span>Quero Acessar o Curso</span>
                                </CustomLoadingButton>
                            </>
                        }

                        {userPurchasedTheCourse &&
                            <>
                                <p>Reparamos que você já possui acesso ao curso de <b>{course?.subcategory} ({course.name})</b>.</p>
                                
                                <CustomLoadingButton
                                    onClick={() => redirectToAnExternalLink(`${process.env.REACT_APP_URL_BASE_LABORATORY}curso/${course?.link_category}/${course?.link_subcategory}/${course?.slug}`)}
                                    variant="contained"
                                    fullWidth
                                    color='secondary'
                                    size="large"
                                >
                                    <span>Quero Acessar o Curso</span>
                                </CustomLoadingButton>
                            </>
                        }

                        {!checkLoader && (!userHasPlan && !userHasCourseOnPlan && !userHasCourseOnPlanDowngrade && !userPurchasedTheCourse) && 
                            <>
                                <p>Siga todos os passos para liberar o seu acesso.</p>
                                
                                <CustomLoadingButton
                                    onClick={() => sendStep()}
                                    loading={stepLoader}
                                    variant="contained"
                                    fullWidth
                                    color='secondary'
                                    size="large"
                                >
                                    <span>Próximo</span>
                                </CustomLoadingButton>
                            </>
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default StepVerifyHasActivePlanOrBuyThisCourse;