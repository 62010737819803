import { useEffect, useState } from 'react';
import '../style.css'
import { Link } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Blog";
import api from "../../../services/api";
import { Context } from '../../../context/AuthContext'
import React, { useContext } from 'react'
import { Avatar } from '@mui/material';
import HomeLoader from '../../../components/Loaders/Divs/Blog/HomeLoader';
import SectionBeStudent from '../../../components/Website/SectionBeStudent'
import { upperFirstLetter } from '../../../helpers';

function BlogHome () {
    const { setMetaTagsOfPage, showAlert, alertInTopClosed } = useContext(Context)
    const [articles, setArticles] = useState([])
    // const [programingArticles, setProgramingArticles] = useState([])
    // const [designArticles, setDesignArticles] = useState([])
    const [blogHomeSec1Loader, setBlogHomeSec1Loader] = useState(true)

    useEffect(() => {
        setMetaTagsOfPage('Tudo sobre o mundo do design e da programação', 'Aqui você encontra diversos artigos sobre o mundo do design e da programação!')
        loadHome()

        window.scrollTo(0, 0)
    }, [])

    async function loadHome() {
        await api.get('blog')
            .then((response) => {
                if (response.data.status === 'success') {
                    // setProgramingArticles(response.data.data.blog_programing_articles)
                    // setDesignArticles(response.data.data.blog_design_articles)
                    setArticles(response.data.data.blog_articles)

                    setBlogHomeSec1Loader(false)
                }
            }).catch((error) => {
            })
    }

    const LastArticle = (props) => {
        return (
            <article>
                <Link to={`/${props.link_category}/${props.link_subcategory}/${props.link}`}>
                    <div className='article_bg_img' style={{backgroundImage: `url(${props.thumb})`}}></div>

                    <div className='thumb_box_article'>
                        {props.author.picture ?
                            <img src={props.author.picture} alt={props.author.name} title={props.author.name}/>
                        :
                            <Avatar title={props.author.name}>{upperFirstLetter(props.author.name)}</Avatar>
                        }
                    </div>
                </Link>

                <div className='article_content'>
                    <div className='thumb_title'>
                        <div className='thumb_title_box'>
                            <h1>
                                <Link to={`/${props.link_category}/${props.link_subcategory}/${props.link}`}>{props.title}</Link>
                            </h1>

                            <div className='title_box'>
                                <Link className='categlk' to={`/${props.link_category}/${props.link_subcategory}`} style={{background:props.subcat_bg_color}}>{props.subcategory}</Link>
                                <span className='pub_date'>{props.publication_date}</span>
                            </div>
                        </div>
                    </div>

                    <div className='description_article'>
                        <p dangerouslySetInnerHTML={{ __html: props.description}} />
                    </div>
                </div>
            </article>
        )
    }

    return (
        <>
            <div className={`box_blog ${(showAlert !== '' && !alertInTopClosed) && 'alert-active'}`}>
                <div className="blog_content">

                    <div className='box_home_content'>
                        <div className='home_inside'>
                            <div className='blog_section_full'>
                                <div className='blog_section_1'>
                                    {blogHomeSec1Loader ?
                                        <HomeLoader/>
                                    :
                                        <>
                                            {articles.map((item, index) => {
                                                return <LastArticle key={index} thumb={item.thumb} author={item.author} title={item.title} category={item.category} description={item.description} subcategory={item.subcategory} subcategory_description={item.subcategory_description} publication_date={item.publication_date} link={item.link} link_category={item.link_category} subcat_bg_color={item.subcat_bg_color} link_subcategory={item.link_subcategory} id={item.id}/>
                                            })}
                                            {/* {programingArticles.map((item, index) => {
                                                return <LastArticle key={index} thumb={item.thumb} author={item.author} title={item.title} category={item.category} description={item.description} subcategory={item.subcategory} subcategory_description={item.subcategory_description} publication_date={item.publication_date} link={item.link} link_category={item.link_category} subcat_bg_color={item.subcat_bg_color} link_subcategory={item.link_subcategory} id={item.id}/>
                                            })} */}
                                        </>
                                    }
                                </div>
                            </div>

                            {/* <div className='blog_section_full'>
                                <div className='section_title'>
                                    <h1>Design</h1>

                                    <LoadingButton
                                        onClick={() => redirectTo(history, '/design')}
                                        // endIcon={<SchoolIcon />}
                                        // loading={spinnerLoaderBtn}
                                        // loadingPosition="end"
                                        variant="outlined"
                                        color="secondary"
                                    >
                                        <span>Ver Todos</span>
                                    </LoadingButton>
                                </div>
                                
                                <div className='blog_section_1'>
                                    {blogHomeSec1Loader ?
                                        <HomeLoader/>
                                    :
                                        <>
                                            {designArticles.map((item, index) => {
                                                return <LastArticle key={index} thumb={item.thumb} author={item.author} title={item.title} category={item.category} description={item.description} subcategory={item.subcategory} subcategory_description={item.subcategory_description} publication_date={item.publication_date} link={item.link} link_category={item.link_category} subcat_bg_color={item.subcat_bg_color} link_subcategory={item.link_subcategory} id={item.id}/>
                                            })}
                                        </>
                                    }
                                </div>
                            </div> */}
                        </div>

                        <Sidebar />
                    </div> 
                    
                </div>
            </div>

            <SectionBeStudent />
        </>
    )
}

export default BlogHome;