import React from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Collapse } from '@mui/material';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useState } from 'react'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Link } from 'react-router-dom';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';

function NavBarBlogMobile({ showMenuMain, setShowMenuMain, blogCategorys }) {
    const [openCollapse, setOpenCollapse] = useState(false)
    const [openCollapse2, setOpenCollapse2] = useState(false)
    const [openCollapse3, setOpenCollapse3] = useState(false)
    const [openCollapse4, setOpenCollapse4] = useState(false)

    const toggleDrawer = () => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setShowMenuMain(!showMenuMain)
    };

    const showCollapse = (event, categSlug) => {
        if (categSlug === 'design') {
            setOpenCollapse(!openCollapse)
        } else if (categSlug === 'programacao') {
            setOpenCollapse2(!openCollapse2)
        } else if (categSlug === 'marketing') {
            setOpenCollapse3(!openCollapse3)
        } else if (categSlug === 'suporte-ao-cliente') {
            setOpenCollapse4(!openCollapse4)
        }
    }

    const getOpenCollapse = (categSlug) => {
        if (categSlug === 'design') {
            return openCollapse
        } else if (categSlug === 'programacao') {
            return openCollapse2
        } else if (categSlug === 'marketing') {
            return openCollapse3
        } else if (categSlug === 'suporte-ao-cliente') {
            return openCollapse4
        }
    }

    return (
        <Drawer 
            anchor='left'
            open={showMenuMain} 
            onClose={toggleDrawer()}
            disableScrollLock={true}
            PaperProps={{
                sx: {backgroundColor: "var(--plat-two)", color: "var(--plat-six)"}
            }}
        >
            <Box
                sx={{ width: 250 }}
                role="presentation"
                // onClick={toggleDrawer()}
                // onKeyDown={toggleDrawer()}
            >
                <List
                    // subheader={
                    //     <ListSubheader component="div" id="nested-list-subheader">
                    //         Qual área você tem interesse?
                    //     </ListSubheader>
                    // }
                >

                    <ListItemButton 
                        component={Link} 
                        to="/" 
                        sx={{
                            "&:hover": {
                                backgroundColor: "var(--plat-four)",
                            }
                        }}
                    >
                        <ListItemIcon>
                            <HomeOutlinedIcon color="neutral" />
                        </ListItemIcon>
                        
                        <ListItemText primary={'Home'} />
                    </ListItemButton>

                    <ListItemButton 
                        onClick={() => {window.location.href = process.env.REACT_APP_URL_BASE}}
                        sx={{
                            "&:hover": {
                                backgroundColor: "var(--plat-four)",
                            }
                        }}
                    >
                        <ListItemIcon>
                            <SchoolOutlinedIcon color="neutral" />
                        </ListItemIcon>
                        
                        <ListItemText primary={'Site'} />
                    </ListItemButton> 

                    {blogCategorys.map((category, index) => (
                        <>
                            <ListItemButton 
                                key={index} 
                                onClick={(e) => showCollapse(e, category.slug)}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "var(--plat-four)",
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    {category.slug === 'design' && <DesignServicesOutlinedIcon color="neutral" />}
                                    {category.slug === 'programacao' && <DataObjectOutlinedIcon color="neutral" />}
                                    {category.slug === 'marketing' && <LocationSearchingOutlinedIcon color="neutral" /> }
                                    {category.slug === 'suporte-ao-cliente' && <SupportAgentOutlinedIcon color="neutral" /> }
                                </ListItemIcon>
                                
                                <ListItemText primary={category.name} />

                                {category.subcategorys.length > 0 &&
                                    getOpenCollapse(category.slug) ? <ExpandLess /> : <ExpandMore />
                                }
                            </ListItemButton>
                                
                            <Collapse in={getOpenCollapse(category.slug)} timeout="auto" unmountOnExit>
                                {category.subcategorys.map((subcateg, index) => {
                                    return (
                                        <List key={`sub-${index}`} component="div" disablePadding>
                                            <ListItemButton 
                                                component={Link} 
                                                to={`/${category.slug}/${subcateg.slug}`} 
                                                onClick={() => setShowMenuMain(!showMenuMain)}
                                                sx={{
                                                    "&:hover": {
                                                        backgroundColor: "var(--plat-four)",
                                                    },
                                                    pl: 4
                                                }}
                                            >
                                                <ListItemText primary={subcateg.name} />
                                            </ListItemButton>
                                        </List>
                                    )
                                })}
                            </Collapse>
                        </>
                    ))}
                </List>
            </Box>
        </Drawer>
    );
}

export default NavBarBlogMobile;