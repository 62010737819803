import { useState } from 'react'
import logo from '../../assets/imgs/logos/logoeduteka.png'
import iconFace from '../../assets/imgs/website/icons/icon-facebook-wt.png'
import iconInsta from '../../assets/imgs/website/icons/icon-instagram-wt.png'
import iconYTube from '../../assets/imgs/website/icons/icon-youtube-wt.png'
import iconTwitter from '../../assets/imgs/website/icons/icon-twitter-wt.png'
import './style.css'
import { Link } from 'react-router-dom'
import Form from '../Form'
import { toast } from 'react-toastify'
import api from '../../services/api'
import { Grid, FormControl, FormHelperText } from '@mui/material'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { CustomLoadingButtonOutlined } from '../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButtonOutlined'
import { CustomTextField, inputLabelStyles } from "../MaterialCustom/Laboratory/TextField/CustomTextField";

function Footer({}) {     
    const [socialMediaItem] = useState([
        {icon: iconYTube, title: 'Eduteka | YouTube', link: 'https://www.youtube.com/@Edutekabr', width: 31, height: 22},
        {icon: iconInsta, title: 'Eduteka | Instagram', link: 'https://www.instagram.com/edutekabr', width: 25, height: 25},
        {icon: iconTwitter, title: 'Eduteka | Twitter', link: 'https://twitter.com/Edutekabr', width: 29, height: 23},
        {icon: iconFace, title: 'Eduteka | Facebook', link: 'https://www.facebook.com/profile.php?id=100092142484014', width: 28, height: 28},
    ])

    const [formNewsLetSub, setFormNewsLetSub] = useState({ email_nwl:'' })
    const [fieldsError, setFieldsError] = useState({ email_nwl: '' })
    const [spinnerBtnLoader, setSpinnerBtnLoader] = useState(false)

    function setEmailNwl(e) {
        setFormNewsLetSub({...formNewsLetSub, email_nwl: e.target.value})
    }

    function sendNewsLetterSubscribe(e) {
        e.preventDefault()
        setSpinnerBtnLoader(true)

        api.post('newsletter-subscribe', formNewsLetSub)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    setFormNewsLetSub({email_nwl: ''});
                } else {
                    toast.error(response.data.message)
                }

                setSpinnerBtnLoader(false)
                setFieldsError({email_nwl: ''})
            }).catch((error) => {
                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                }
                
                setSpinnerBtnLoader(false)
            })
    }

    const MediaItem = (props) => {
        return (
            <div className="midia">
                <a href={props.link} target="__blank">
                    <img src={props.icon} alt={props.title} title={props.title} width={props.width} height={props.height}/>
                </a>
            </div>
        )
    }

    return (
        <footer className="big">
            <Grid container spacing={7} sx={{paddingBottom:"40px"}}>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <h3 className='foot'>Sobre nós</h3>
                    <p className='foot'>Somos uma plataforma de ensino focada em programação.</p>

                    <div className="social_midia">
                        {socialMediaItem.map((item, index) => {
                            return <MediaItem key={index} icon={item.icon} title={item.title} link={item.link} width={item.width} height={item.height} />
                        })}
                    </div>

                    <Link to="/">
                        <img className="logo" src={logo} width={98} alt="Eduteka" title="Eduteka"/>
                    </Link>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                    <h3 className='foot'>Navegação</h3>

                    <ul>
                        <li><a href={process.env.REACT_APP_URL_BASE}>Site</a></li> 
                        <li><a href={process.env.REACT_APP_URL_BASE_BLOG}>Blog</a></li>
                        <li><a href={process.env.REACT_APP_URL_BASE_LABORATORY}>Área do aluno</a></li> 
                        <li><Link to="/assinatura">Planos</Link></li>
                        <li><Link to="/suporte">Suporte</Link></li>
                        
                        {/* <li><Link to="/">Política de Privacidade</Link></li> */}
                        {/* <li><Link to="/">Termos de Uso</Link></li> */}
                    </ul>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                    {/* <h3 className='foot'>Cursos de Programação</h3> */}

                    {/* <ul>
                        <li><Link to="/">Bootstrap</Link></li>
                        <li><a href={`${process.env.REACT_APP_URL_BASE}cursos/front-end/html-e-css`}>Html e Css</a></li>
                        <li><a href={`${process.env.REACT_APP_URL_BASE}cursos/front-end/javascript`}>JavaScript</a></li>
                        <li><Link to="/">PHP</Link></li>
                        <li><Link to="/">Laravel</Link></li>
                        <li><Link to="/">React JS</Link></li>
                    </ul> */}
                </Grid> 

                <Grid item xs={12} sm={12} md={12} lg={2} xl={2}> 
                    {/* <h3 className='foot'>Cursos de Design</h3>

                    <ul>
                        <li><Link to="/">Adobe XD</Link></li>
                        <li><Link to="/suporte">Adobe Illustrator</Link></li>
                        <li><Link to="/">Adobe Photoshop</Link></li>
                        <li><Link to="/">Corel Draw</Link></li>
                        <li><Link to="/">Design UX/UI</Link></li>
                    </ul> */}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <h3 className='foot'>Newsletter</h3>
                    <p className='foot'>Informe seu e-mail e receba conteúdo na sua caixa de entrada.</p>

                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={5} lg={12} xl={12}>
                                <FormControl variant="standard" fullWidth>
                                    <CustomTextField
                                        required
                                        label="E-mail"
                                        name='email_nwl'
                                        {...(fieldsError.email_nwl && {error: 'true'})}
                                        onChange={setEmailNwl} 
                                        value={formNewsLetSub.email_nwl}
                                        size="medium"
                                        autoComplete='off'
                                        fullWidth
                                        inputProps={{ 
                                            maxLength: 200
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                            ...inputLabelStyles,
                                            },
                                        }}
                                    />

                                    <FormHelperText>
                                        {fieldsError.email_nwl}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4} md={3} lg={12} xl={12}>
                                <CustomLoadingButtonOutlined
                                    onClick={sendNewsLetterSubscribe}
                                    endIcon={<SendOutlinedIcon />}
                                    loading={spinnerBtnLoader}
                                    loadingPosition="end"
                                    variant="outlined"
                                    fullWidth
                                    size="medium"
                                    color="secondary"
                                >
                                    <span>Inscreva-se</span>
                                </CustomLoadingButtonOutlined>
                            </Grid>
                        </Grid>
                    </Form>
                </Grid>
            </Grid>

            <p className="copyright">Copyright ©2023 All rights reserved</p>
        </footer> 
    )
}

export default Footer;