import { useEffect, useState } from "react";
import { Context } from '../../../context/AuthContext'
import React, { useContext } from 'react'
import { CustomLoadingButtonOutlined } from "../../../components/MaterialCustom/Laboratory/LoadingButton/CustomLoadingButtonOutlined";
import { CustomLoadingButton } from '../../../components/MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton'
import { Box, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { prettyUserName, redirectTo, textLimit } from "../../../helpers";
import './style.css'
import api from "../../../services/api";
import SpinnerLoader from "../../../components/Loaders/SpinnerLoader";
import CourseItem from '../../../components/Website/CourseItem';

function LaboratoryCourseNotAvailable () {
    const { setMetaTagsOfPage, handleLogout } = useContext(Context)
    const history = useHistory()
    const urlParams = new URLSearchParams(window.location.search);
    const courseSlug = urlParams.get('curso');
    const [course, setCourse] = useState()
    const [userRecovery, setUserRecovery] = useState();
    const [userHasPlan, setUserHasPlan] = useState(false)
    const [userHasCourseOnPlanFree, setUserHasCourseOnPlanFree] = useState(false)
    const [userHasCourseOnPlanPay, setUserHasCourseOnPlanPay] = useState(false)
    const [userHasCourseOnPlanDowngrade, setUserHasCourseOnPlanDowngrade] = useState(false)
    const [userPurchasedTheFreeCourse, setUserPurchasedTheFreeCourse] = useState(false)
    const [userPurchasedThePayCourse, setUserPurchasedThePayCourse] = useState(false)
    const [checkLoader, setCheckLoader] = useState(true)
    const [courseLoader, setCourseLoader] = useState(true)
    const [currentStep, setCurrentStep] = useState(1)
    const [stepLoader, setStepLoader] = useState(false)

    useEffect(() => {
        //Recupera os dados do usuário logado
        getDataOfUserLogged()

        loadCourse()

        window.scrollTo(0, 0)
        setMetaTagsOfPage('Acesso bloqueado', 'Esse recurso não está disponível para você!')
    }, [])

    useEffect(() => {
        //Checagem do plano e dos cursos adquiridos pelo usuário
        if (userRecovery && course) {
            checkUserPlanAndPurchasedCourses()
        }
    }, [userRecovery, course])

    async function loadCourse() {
        setCourseLoader(true)

        await api.get(`curso/lp/${courseSlug}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    setCourse(response.data.data.course)
                    setCourseLoader(false)
                } else {
                    // Curso não encontrado
                    redirectTo(history, '/')
                }
            }).catch((error) => {
            })
    }

    function checkUserPlanAndPurchasedCourses(){
   
        //Verifica se o usuário já possui um PLANO ativo
        if (userRecovery?.has_an_active_plan && userRecovery?.plan) {
            setUserHasPlan(true)
        }
        

        //Verifica se o usuário possui o CURSO disponível no plano Free (somente cursos grátis)
        if ((course?.is_free === 1 && userRecovery?.has_an_active_plan && userRecovery?.plan === 'Free' && !userRecovery?.plan_downgrade)) {
            setUserHasCourseOnPlanFree(true)
        }
            
        //Verifica se o usuário possui o CURSO disponível no plano Pago
        else if ((userRecovery?.has_an_active_plan && (userRecovery?.plan !== 'Free')) || (userRecovery?.is_teacher && userRecovery?.plan === 'Teacher')) {
            setUserHasCourseOnPlanPay(true)
        }
            
        //Verifica se o CURSO(pago ou gratuito) já foi adquirido anteriormente pelo usuário, e se o acesso está dentro do período de validade
        else if (userRecovery?.has_an_active_product && (course?.is_free === 2 || (course?.is_free === 1 && !userRecovery?.plan_downgrade))) {
            checkIfTheUserPurchasedTheCourse()
        }

        
        //Verifica se o usuário possui o CURSO disponível no plano (o plano apesar de ser FREE está em processo de downgrade, o usuário tinha um plano pago anteriormente. O usuário ainda possui permissão do plano pago até o final do prazo de renovação)
        if ((userRecovery?.has_an_active_plan && userRecovery?.plan === 'Free' && userRecovery?.plan_downgrade)) {
            setUserHasCourseOnPlanDowngrade(true)
        }
       

        // Libero a tela com delay, pois se não ele pode renderizar incorretamente por causa da modificação constante de states
        setTimeout(() => {
            setCheckLoader(false)
        }, 1000)
    }

    async function checkIfTheUserPurchasedTheCourse() {
        await api.get('purchase/costumer/check-user-has-course-enabled', {params: {course_id: course?.id}})
            .then((response) => {
                if (response.data.status === 'success') {
                    if (course?.is_free === 1) {
                        setUserPurchasedTheFreeCourse(response.data.data.user_has_course)
                    } else {
                        setUserPurchasedThePayCourse(response.data.data.user_has_course)
                    }
                }
            }).catch((error) => {
                if (error.response?.status === 401) {
                    handleLogout()
                }
            })
    }

    function getDataOfUserLogged(){
        let userRecov = JSON.parse(localStorage?.getItem('user'))
        setUserRecovery(userRecov)
    }

    function sendStep(nextStep) {
        setStepLoader(true)

        setTimeout(() => {
            setStepLoader(false)
            setCurrentStep(nextStep)
        }, 300)
    }

    const StepOne = () => {
        return (
            <>
                {userHasCourseOnPlanFree &&
                    <>
                        <h1>Olá {userRecovery?.name ? prettyUserName(userRecovery?.name) : userRecovery?.name}!</h1>
                        <p>Reparamos que você possui o <b>Plano {userRecovery?.plan}</b>, com ele você tem acesso as aulas do curso, mas não aos recursos. Se você quiser habilitar os desafios, fórum e emissão de certificado, clique no botão de upgrade do plano.</p>

                        <Grid container spacing={2} sx={{marginTop:"40px"}} alignItems='stretch'>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
 
                                <CustomLoadingButtonOutlined
                                    onClick={() => redirectTo(history, `curso/${course?.link_category}/${course?.link_subcategory}/${course?.slug}`)}
                                    variant="outlined"
                                    fullWidth
                                    color='secondary'
                                    size="large"
                                >
                                    <span>Quero Acessar o Curso</span>
                                </CustomLoadingButtonOutlined>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomLoadingButton
                                    onClick={() => redirectTo(history, '/meu-perfil')}
                                    variant="contained"
                                    size="large"
                                    color="secondary"
                                    fullWidth
                                >
                                    <span>Fazer Upgrade do Plano</span>
                                </CustomLoadingButton>
                            </Grid>
                        </Grid>
                    </>
                }

                {userPurchasedTheFreeCourse &&
                    <>
                        <h1>Olá {userRecovery?.name ? prettyUserName(userRecovery?.name) : userRecovery?.name}!</h1>
                        <p>Reparamos que você possui o curso de <b>{course?.subcategory} ({textLimit(course?.name, 18)}...)</b>, por ser um curso <b>gratuito</b> você tem acesso as aulas do curso, mas não aos recursos. Se você quiser habilitar os desafios, fórum e emissão de certificado, precisa assinar um plano pago.</p>

                        <Grid container spacing={1} sx={{marginTop:"40px"}} alignItems='stretch'>
                            {!userHasPlan ?
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CustomLoadingButton
                                        onClick={() => redirectTo(history, '/assinatura')}
                                        variant="contained"
                                        size="large"
                                        color="secondary"
                                        fullWidth
                                    >
                                        <span>Planos e Condições</span>
                                    </CustomLoadingButton>
                                </Grid>
                            :
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CustomLoadingButton
                                        onClick={() => redirectTo(history, '/meu-perfil')}
                                        variant="contained"
                                        size="large"
                                        color="secondary"
                                        fullWidth
                                    >
                                        <span>Fazer Upgrade do Plano</span>
                                    </CustomLoadingButton>
                                </Grid>
                            }
                        </Grid>
                    </>
                }

                {userHasCourseOnPlanDowngrade &&
                    <>
                        <h1>Olá {userRecovery?.name ? prettyUserName(userRecovery?.name) : userRecovery?.name}!</h1>
                        <p>Reparamos que seu plano está em processo de <b>downgrade</b>, por enquanto você ainda possui as permissões do seu plano anterior, o que garante acesso total ao curso.</p>
                        
                        <CustomLoadingButton
                            onClick={() => redirectTo(history, `curso/${course?.link_category}/${course?.link_subcategory}/${course?.slug}`)}
                            variant="contained"
                            fullWidth
                            color='secondary'
                            size="large"
                        >
                            <span>Quero Acessar o Curso</span>
                        </CustomLoadingButton>
                    </>
                }

                {(userPurchasedThePayCourse || userHasCourseOnPlanPay) &&
                    <>
                        <h1>Olá {userRecovery?.name ? prettyUserName(userRecovery?.name) : userRecovery?.name}!</h1>
                        <p>Reparamos que você possui acesso total ao curso de <b>{course?.subcategory} ({course.name})</b>.</p>
                        
                        <CustomLoadingButton
                            onClick={() => redirectTo(history, `curso/${course?.link_category}/${course?.link_subcategory}/${course?.slug}`)}
                            variant="contained"
                            fullWidth
                            color='secondary'
                            size="large"
                        >
                            <span>Quero Acessar o Curso</span>
                        </CustomLoadingButton>
                    </>
                }

                {(!userHasCourseOnPlanDowngrade && !userHasCourseOnPlanFree && !userHasCourseOnPlanPay && !userPurchasedTheFreeCourse && !userPurchasedThePayCourse) &&
                    <>
                        <h1>Você não possui permissão <br/>para acessar este curso.</h1>
                        <p>Quer <b>liberar</b> seu acesso? Então, clique no botão abaixo.</p>
        
                        <Grid container justifyContent='center'>
                            {courseLoader ?
                                <SpinnerLoader borderColorImp='var(--plat-seven)'/>
                            :
                                <Grid container item xs={12} sm={10} md={10} lg={10} xl={10}>
                                    <CourseItem disableClick={true} name={course.name} isFree={course.is_free} evaluation={course.avg_evaluation} level={course.level} durationTotal={course.duration_total} quantityOfClasses={course.number_of_classes} slugCategory={course.link_category} slugSubCategory={course.link_subcategory} slugCourse={course.slug} category={course.category} subcategory={course.subcategory} description={course.description} teacher={course.teacher} subcategoryBgColor={course.subcat_bg_color} publicationDate={course.publication_date} isLaboratory={true} /*primary_color={'var(--first-color)'} secondary_color={'var(--secondary-color)'} characteristics={'asdasdas'} number_of_classes={'14'} duration_total={'2'}*/ />
                                </Grid>
                            }
                        </Grid>
        
                        <Grid container sx={{marginTop:"40px"}}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CustomLoadingButton
                                    onClick={() => sendStep(2)}
                                    loading={stepLoader}
                                    // onClick={() => redirectTo(history, '/meu-perfil')}
                                    variant="contained"
                                    sx={{width: '350px'}}
                                    size="large"
                                    color="secondary"
                                >
                                    <span>Liberar Acesso</span>
                                </CustomLoadingButton>
                            </Grid>
                        </Grid>
                    </>
                }
            </>
        )
    }

    const StepTwo = () => {
        return (
            <>
                <h1>Escolha uma das opções abaixo <br/>para ter acesso ao curso.</h1>
                <p>Clique na opção que faz mais sentido para você.</p>

                <Grid container spacing={2} sx={{marginTop:"40px"}} alignItems='stretch'>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomLoadingButtonOutlined
                            onClick={() => redirectTo(history, `/curso/${course.link_category}/${course.link_subcategory}/${course.slug}/checkout`)}
                            variant="outlined"
                            size="large"
                            color="secondary"
                            fullWidth
                        >
                            <span>Comprar o Curso</span>
                        </CustomLoadingButtonOutlined>
                    </Grid>

                    {!userHasPlan ?
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <CustomLoadingButton
                                onClick={() => redirectTo(history, '/assinatura')}
                                variant="contained"
                                size="large"
                                color="secondary"
                                fullWidth
                            >
                                <span>Planos e Condições</span>
                            </CustomLoadingButton>
                        </Grid>
                    :
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <CustomLoadingButton
                                onClick={() => redirectTo(history, '/meu-perfil')}
                                variant="contained"
                                size="large"
                                color="secondary"
                                fullWidth
                            >
                                <span>Fazer Upgrade do Plano</span>
                            </CustomLoadingButton>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }

    return (
        <div className="box_labor_all">
            <div className='box_not_available'>
                <div className="bna_content">
                    {(checkLoader || courseLoader) ?
                        <>
                            <h1>Aguarde, checando permissões...</h1>
                            <p>Estamos checando suas permissões de acesso a esse recurso.</p>

                            <Box display="flex" justifyContent="center" alignItems="flex-start" marginTop="30px">
                                <SpinnerLoader heightVal="auto" justifyContentVal="center" borderColorImp='var(--plat-seven)'/>
                            </Box> 
                        </>
                    :
                        <>
                            {currentStep === 1 &&
                                <StepOne />
                            }
                    
                            {currentStep === 2 &&
                                <StepTwo />
                            }
                        </>
                    }
                </div>
            </div>              
        </div>
    )
}

export default LaboratoryCourseNotAvailable;