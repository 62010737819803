import './style.css'
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import SlowMotionVideoOutlinedIcon from '@mui/icons-material/SlowMotionVideoOutlined';
import { CustomThumb } from '../../MaterialCustom/Laboratory/CourseItem/CustomThumb';
// import FavIcon from '../../Laboratory/FavIcon';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useHistory } from 'react-router-dom';
import { redirectTo, redirectToWithParams } from '../../../helpers';

function ModalCourseDtls({setArrowDisplay, modalStatus, setModalStatus, courseMouseHover /*, hideModalDetails*/, modalBlock}) {
    const [modalFocus, setModalFocus] = useState(true)
    const history = useHistory()

    const [isMobile, setIsMobile] = useState()
    let modalPosition = useRef('')

    // useEffect(() => {
        // console.log('render component ModalCourseDtls')
    // }, [])

    // useEffect(() => {
    //     console.log('courseMouseHover ALTERADO')
    // }, [courseMouseHover.current])

    // useEffect(() => {
    //     console.log('--modalStatus ALTERADO para ' + modalStatus)
    // }, [modalStatus]);

    useEffect(() => { //ESSE USE EFFECT FORCA UMA NOVA RENDERIZACAO DO SUBCOMPONENTE FAVICON  (O MEMO NAO TA FUNCIONANDO)
        // console.log('courseMouseHover ALTERADO')
        let extremityLeft = 0;
        let extremityRight = window.innerWidth;
        let thumbWidth = courseMouseHover.current?.event?.target?.getBoundingClientRect()?.width
        let leftPositionOfThumbOpen = courseMouseHover.current?.event?.target?.getBoundingClientRect()?.left
        let rightPositionOfThumbOpen = courseMouseHover.current?.event?.target?.getBoundingClientRect()?.left + thumbWidth
        let thumbPosition = 'center'

        if (leftPositionOfThumbOpen < (extremityLeft + thumbWidth)) {
            thumbPosition = 'left';
        } else if (rightPositionOfThumbOpen > (extremityRight - thumbWidth)) {
            thumbPosition = 'right';
        }

        modalPosition.current = thumbPosition
    }, [courseMouseHover.current])

    useLayoutEffect(() => { /* Executa antes de renderizar */
        if (modalStatus === true) {
            setModalFocus(true)
            setArrowDisplay(false)

            let screenWidth = window.innerWidth;
            if (screenWidth < 750) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        }
    }, [modalStatus])

    function handleCancel(e) {
        // console.log('cancel')
        e.preventDefault()
        setModalFocus(false)
        modalBlock.current = false

        setTimeout(() => {
            setArrowDisplay(true)
            setModalStatus(false)
            // hideModalDetails(e)
        }, 150) //150
    }

    function handleClickModal(e) {
        //se clicou em favoritar ou desfavoritar eu não redireciono para o curso
        if (e?.target?.parentElement?.id === 'fav-icon-favorite'){
            e.preventDefault();
            return;
        }

        if (courseMouseHover.current.permissions?.can_access_course === true) {
            redirectTo(history, `curso/${courseMouseHover.current.link_category}/${courseMouseHover.current.link_subcategory}/${courseMouseHover.current.slug}`)
        } else {
            redirectToWithParams(history, '/recurso-bloqueado', `?curso=${courseMouseHover.current.slug}`)
        }
    }

    const modalShowAnim = keyframes`
        to {
            transform: ${ 
                modalPosition.current === 'right' ? 'translateX(-30px) translateY(-30px) scaleX(1) scaleY(1) translateZ(0px)' 
                : modalPosition.current === 'left' ? 'translateX(0px) translateY(-30px) scaleX(1) scaleY(1) translateZ(0px)'
                : modalPosition.current === 'center' ? 'translateX(-15px) translateY(-30px) scaleX(1) scaleY(1) translateZ(0px)'
                : ''
            };

            width: ${isMobile ? courseMouseHover.current?.event?.target?.getBoundingClientRect()?.width
                : (courseMouseHover.current?.event?.target?.getBoundingClientRect()?.width + 30)
            }px;
        }
    `

    const modalHideAnim = keyframes`
        from {
            transform: ${ 
                modalPosition.current === 'right' ? 'translateX(-30px) translateY(-30px) scaleX(1) scaleY(1) translateZ(0px)' 
                : modalPosition.current === 'left' ? 'translateX(0px) translateY(-30px) scaleX(1) scaleY(1) translateZ(0px)'
                : modalPosition.current === 'center' ? 'translateX(-15px) translateY(-30px) scaleX(1) scaleY(1) translateZ(0px)'
                : ''
            };

            width: ${isMobile ? courseMouseHover.current?.event?.target?.getBoundingClientRect()?.width
                : (courseMouseHover.current?.event?.target?.getBoundingClientRect()?.width + 30)
            }px;
        }
        to {
            transform: ${ 
                modalPosition.current === 'right' ? 'translateX(0px) translateY(0px) scaleX(1) scaleY(1) translateZ(0px)' 
                : modalPosition.current === 'left' ? 'translateX(0px) translateY(0px) scaleX(1) scaleY(1) translateZ(0px)'
                : modalPosition.current === 'center' ? 'translateX(0px) translateY(0px) scaleX(1) scaleY(1) translateZ(0px)'
                : ''
            };

            opacity: .9;
        }
    `
    
    const ModalCrsDtl = styled.div `
        display: ${modalStatus ? 'block' : 'none'};
        position: absolute;
        border-radius: 8px;
        transform-origin: center center;
        box-shadow: rgba(0, 0, 0, 0.75) 0px 3px 10px;
        z-index: 3;
        opacity: 1;
        animation: ${modalFocus === true ? modalShowAnim : modalHideAnim} .1s linear forwards;
        width: ${courseMouseHover.current?.event?.target?.getBoundingClientRect()?.width}px;
        height: ${(courseMouseHover.current?.event?.target?.getBoundingClientRect()?.height + 60)}px;
        top: ${(courseMouseHover.current?.event?.target?.getBoundingClientRect()?.top + window?.scrollY)}px;
        left: ${courseMouseHover.current?.event?.target?.getBoundingClientRect()?.left}px;
    `

    return (
        <>
            <ModalCrsDtl onMouseLeave={(e) => handleCancel(e)} onClick={(e) => handleClickModal(e)}>
                <div className='modal_crs_container'>
                    <CustomThumb className="mdl_crs_thumb" thumb={courseMouseHover.current.thumb} isAvailable={courseMouseHover.current.permissions?.can_access_course}>
                        {courseMouseHover.current.permissions?.can_access_course === false &&
                            <>
                                <LockOutlinedIcon color='fifthcolor' fontSize='large'/>
                                <p className='blocked_plan_current'>Acesso Bloqueado</p>
                            </>
                        }
                    </CustomThumb>

                    <div className='mdl_crs_infos'>
                        <div className='mdl_crs_title_header'>
                            <div>
                                <h3>Curso de {courseMouseHover.current.subcategory}</h3>
                                <span>{courseMouseHover.current.description}</span>
                                {/*<span>Aula {courseMouseHover.current.current_class}</span>*/}
                            </div>

                            {/* Comentei em baixo, pois tava disparando muitas requisições por causa do useMemo */}
                            {/* <FavIcon id="fav-icon-favorite" courseId={courseMouseHover?.current?.id} />               */}
                        </div>

                        <div className='mdl_crs_more_infos'>
                            <div className='mdl_crs_m_i'>
                                <span>{courseMouseHover.current.subcategory}</span>

                                <div className='mdl_crs_more_dtls'>
                                    <div>
                                        <CalendarMonthOutlinedIcon color='fifthcolor'/> 
                                        <span>{courseMouseHover.current.publication_date}</span> 
                                    </div>

                                    <div>
                                        <SlowMotionVideoOutlinedIcon color='fifthcolor'/> 
                                        <span>{courseMouseHover.current.number_of_classes} Aulas</span> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalCrsDtl>
        </>
    )
}

export default ModalCourseDtls;