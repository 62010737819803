import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { List, ListItemButton, ListItemText, Menu } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

function NavBarBlogDesktop({ blogCategorys }) {
    const history = useHistory()
    const [anchorEl3, setAnchorEl3] = useState(null)
    const [anchorEl4, setAnchorEl4] = useState(null)
    const [anchorEl5, setAnchorEl5] = useState(null)
    const [anchorEl6, setAnchorEl6] = useState(null)
    const open3 = Boolean(anchorEl3);
    const open4 = Boolean(anchorEl4);
    const open5 = Boolean(anchorEl5);
    const open6 = Boolean(anchorEl6);

    const darkMode = {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        bgcolor: 'var(--plat-two)',
        color: 'var(--plat-six)',
        '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1 },
        '&:before': { content: '""', display: 'block', position: 'absolute', top: 0, left: 25, width: 10, height: 10, bgcolor: 'var(--plat-two)', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 }
    }

    const showSubmenu = (event, categSlug) => {
        if (categSlug === 'design') {
            setAnchorEl3(event.currentTarget);
        } else if (categSlug === 'programacao') {
            setAnchorEl4(event.currentTarget);
        } else if (categSlug === 'marketing') {
            setAnchorEl5(event.currentTarget);
        } else if (categSlug === 'suporte-ao-cliente') {
            setAnchorEl6(event.currentTarget);
        }
    }

    const getOpenSubmenu = (categSlug) => {
        if (categSlug === 'design') {
            return open3
        } else if (categSlug === 'programacao') {
            return open4
        } else if (categSlug === 'marketing') {
            return open5
        } else if (categSlug === 'suporte-ao-cliente') {
            return open6
        }

        return false;
    }

    const handleCloseMenu = (categSlug, linkUrl = null) => {
        if (linkUrl) {
            history.push(linkUrl);
        }

        if (categSlug === 'design') {
            setAnchorEl3(null);
        } else if (categSlug === 'programacao') {
            setAnchorEl4(null);
        } else if (categSlug === 'marketing') {
            setAnchorEl5(null);
        } else if (categSlug === 'suporte-ao-cliente') {
            setAnchorEl6(null);
        }
    };

    return (
        <nav className='top_menu'>
            <ul>
                <li>
                    <Link to="/">
                        <span>Home</span>
                    </Link>
                </li>

                <li>
                    <a href={process.env.REACT_APP_URL_BASE}>
                        <span>Site</span>
                    </a>
                </li>

                {blogCategorys.map((categ, index) => {
                    return (
                        <li key={index} onClick={(e) => showSubmenu(e, categ.slug)} style={{cursor: "pointer"}}> 
                            <Link to={`/${categ.slug}`} style={{pointerEvents: "none"}}>
                                <span>{categ.name}</span>

                                {categ.subcategorys.length > 0 &&
                                    getOpenSubmenu(categ.slug) ? <ExpandLess /> : <ExpandMore />
                                }
                            </Link>
                        </li>
                    )
                })}
            </ul>
             

            {/* ***TIVE QUE DEIXAR FIXO POIS DAVA ERRO DE RE-RENDER*** */}
            {/* Submenu Design */}
            <Menu
                anchorEl={anchorEl3}
                open={open3}
                onClose={() => handleCloseMenu('design')}
                // onClick={handleClose2} // não fecha ao clicar nos itens
                PaperProps={{
                    elevation: 0,
                    sx: darkMode
                }}
                getContentAnchorEl={null}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                disableScrollLock={true}
            >
                <List
                    sx={{ width: '100%', minWidth: 200, maxWidth: 360, bgcolor: 'var(--plat-two)', maxHeight: '500px', overflow: 'auto' }}
                    component="nav"
                    // aria-labelledby="nested-list-subheader"
                    // subheader={
                    //     <ListSubheader component="div" id="nested-list-subheader">
                    //         Qual área você tem interesse?
                    //     </ListSubheader>
                    // }
                >
                    {blogCategorys.map((category, index) => {
                        return (
                            <div key={index}>
                                {category.slug === 'design' &&            
                                    <>
                                        {category.subcategorys.map((subcateg, index) => {
                                            return (
                                                <ListItemButton 
                                                    key={subcateg.id} 
                                                    component={Link} 
                                                    to={`/${category.slug}/${subcateg.slug}`} 
                                                    onClick={() => handleCloseMenu(category.slug)}
                                                    sx={{
                                                    // "&$selected": {
                                                    //     backgroundColor: "red",
                                                    //     color: "white",
                                                    //     "& .MuiListItemIcon-root": {
                                                    //         color: "white"
                                                    //     }
                                                    // },
                                                    // "&$selected:hover": {
                                                    //     backgroundColor: "purple",
                                                    //     color: "white",
                                                    //     "& .MuiListItemIcon-root": {
                                                    //         color: "white"
                                                    //     }
                                                    // },
                                                    "&:hover": {
                                                        backgroundColor: "var(--plat-four)",
                                                        // color: "white",
                                                        // "& .MuiListItemIcon-root": {
                                                        //     color: "white"
                                                        // }
                                                    }}}
                                                >
                                                    <ListItemText primary={subcateg.name} />
                                                </ListItemButton>
                                            )
                                        })}
                                    </>
                                }
                            </div>
                        )
                    })}
                </List>
            </Menu>


            {/* Submenu Programação */}
            <Menu
                anchorEl={anchorEl4}
                open={open4}
                onClose={() => handleCloseMenu('programacao')}
                // onClick={handleClose2} // não fecha ao clicar nos itens
                PaperProps={{
                    elevation: 0,
                    sx: darkMode
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                disableScrollLock={true}
            >
                <List
                    sx={{ width: '100%', minWidth: 200, maxWidth: 360, bgcolor: 'var(--plat-two)' }}
                    component="nav"
                    // aria-labelledby="nested-list-subheader"
                    // subheader={
                    //     <ListSubheader component="div" id="nested-list-subheader">
                    //         Qual área você tem interesse?
                    //     </ListSubheader>
                    // }
                >
                    {blogCategorys.map((category, index) => {
                        return (
                            <div key={index}>
                                {category.slug === 'programacao' &&
                                    <>
                                        {category.subcategorys.map((subcateg, index) => {
                                            return (
                                                <ListItemButton 
                                                    key={subcateg.id} 
                                                    component={Link} 
                                                    to={`/${category.slug}/${subcateg.slug}`} 
                                                    onClick={() => handleCloseMenu(category.slug)}
                                                    sx={{
                                                        "&:hover": {
                                                            backgroundColor: "var(--plat-four)",
                                                        }}
                                                    }
                                                >
                                                    <ListItemText primary={subcateg.name} />
                                                </ListItemButton>
                                            )
                                        })}
                                    </>
                                }
                            </div>
                        )
                    })}
                </List>
            </Menu>

            {/* Submenu Marketing */}
            <Menu
                anchorEl={anchorEl5}
                open={open5}
                onClose={() => handleCloseMenu('marketing')}
                // onClick={handleClose2} // não fecha ao clicar nos itens
                PaperProps={{
                    elevation: 0,
                    sx: darkMode
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                disableScrollLock={true}
            >
                <List
                    sx={{ width: '100%', minWidth: 200, maxWidth: 360, bgcolor: 'var(--plat-two)' }}
                    component="nav"
                    // aria-labelledby="nested-list-subheader"
                    // subheader={
                    //     <ListSubheader component="div" id="nested-list-subheader">
                    //         Qual área você tem interesse?
                    //     </ListSubheader>
                    // }
                >
                    {blogCategorys.map((category, index) => {
                        return (
                            <div key={index}>
                                {category.slug === 'marketing' &&
                                    <>
                                        {category.subcategorys.map((subcateg, index) => {
                                            return (
                                                <ListItemButton 
                                                    key={subcateg.id} 
                                                    component={Link} 
                                                    to={`/${category.slug}/${subcateg.slug}`} 
                                                    onClick={() => handleCloseMenu(category.slug)}
                                                    sx={{
                                                        "&:hover": {
                                                            backgroundColor: "var(--plat-four)",
                                                        }}
                                                    }
                                                >
                                                    <ListItemText primary={subcateg.name} />
                                                </ListItemButton>
                                            )
                                        })}
                                    </>
                                }
                            </div>
                        )
                    })}
                </List>
            </Menu>

            {/* Submenu Suporte ao Cliente */}
            <Menu
                anchorEl={anchorEl6}
                open={open6}
                onClose={() => handleCloseMenu('suporte-ao-cliente')}
                // onClick={handleClose2} // não fecha ao clicar nos itens
                PaperProps={{
                    elevation: 0,
                    sx: darkMode
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                disableScrollLock={true}
            >
                <List
                    sx={{ width: '100%', minWidth: 200, maxWidth: 360, bgcolor: 'var(--plat-two)' }}
                    component="nav"
                    // aria-labelledby="nested-list-subheader"
                    // subheader={
                    //     <ListSubheader component="div" id="nested-list-subheader">
                    //         Qual área você tem interesse?
                    //     </ListSubheader>
                    // }
                >
                    {blogCategorys.map((category, index) => {
                        return (
                            <div key={index}>
                                {category.slug === 'suporte-ao-cliente' &&
                                    <>
                                        {category.subcategorys.map((subcateg, index) => {
                                            return (
                                                <ListItemButton 
                                                    key={subcateg.id} 
                                                    component={Link} 
                                                    to={`/${category.slug}/${subcateg.slug}`} 
                                                    onClick={() => handleCloseMenu(category.slug)}
                                                    sx={{
                                                        "&:hover": {
                                                            backgroundColor: "var(--plat-four)",
                                                        }}
                                                    }
                                                >
                                                    <ListItemText primary={subcateg.name} />
                                                </ListItemButton>
                                            )
                                        })}
                                    </>
                                }
                            </div>
                        )
                    })}
                </List>
            </Menu>
        </nav>
    );
}

export default NavBarBlogDesktop;