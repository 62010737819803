import '../style.css'
import './style.css'
// import { useParams } from "react-router-dom";
import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../../context/AuthContext'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import Form from '../../Form'
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { Grid, FormControl, FormHelperText } from '@mui/material'
import { CustomLoadingButton } from '../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { CustomTextField, inputLabelStyles } from '../../MaterialCustom/Laboratory/TextField/CustomTextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useHistory } from 'react-router-dom';
import { redirectToWithParams } from '../../../helpers';

function ModalEditTopic({modalStatus, setModalStatus, topicClicked, setTopicClicked, setForumTopics, forumTopics, classOfCourse}) {
    const { loading, setLoading, handleLogout } = useContext(Context)
    const history = useHistory()
    // const {classId} = useParams()
    
    const [topic, setTopic] = useState({
        subject: '',
        message: '',
    })

    const [fieldsError, setFieldsError] = useState({
        subject: '',
        message: '',
    })

    useEffect(() => {
        if (modalStatus === true) {
            setTopic({
                subject: topicClicked.subject,
                message: topicClicked.message
            })
        }
    }, [modalStatus, topicClicked])

    function handleCancel(e) {
        e.preventDefault()
        setModalStatus(false)
        setFieldsError({subject: '', message: ''});
    }

    async function handleEditTopic() {
        setLoading(true)

        //disparar request de edição de topico
        await api.post(`edit-topic/${topicClicked.id}`, topic)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)

                    let newForumTopics = forumTopics.map((tpc) => {
                        if (tpc.id === topicClicked.id) {
                            tpc.subject = topic.subject
                            tpc.message = topic.message
                        }
                        
                        return tpc;
                    })

                    setForumTopics(newForumTopics)
                    setTopicClicked({...topicClicked, ...{subject: topic.subject, message: topic.message}})

                    setTopic({subject: '', message: ''})
                    setModalStatus(false)
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({subject: '', message: ''});
                setLoading(false)
            }).catch((error) => {
                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data);
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else if (error.response.status === 403) {
                    toast.error(error.response.data.message)
                }

                setLoading(false)
            })
    }

    return (
        <div className={`modal_box ${modalStatus && 'modal_open'}`}>

            <div className='modal_big mdl_blk'>
                <div className="modal_content">
                    <div className='modal_header mdl_blk'>
                        <div className='modtitbox'>
                            {!classOfCourse.course_permissions?.can_manage_topics ?
                                <>
                                    <LockOutlinedIcon color='neutral' sx={{marginRight:"12px"}} fontSize="large" />
                                    <h3 className='mdl_blk'>Acesso Bloqueado</h3>
                                </>
                            :
                                <>
                                    <EditOutlinedIcon color='neutral' sx={{marginRight:"12px"}} fontSize="large" />
                                    <h3 className='mdl_blk'>Editar tópico</h3>
                                </>
                            }
                        </div>

                        <CloseOutlinedIcon onClick={(e) => handleCancel(e)} color='neutral' sx={{cursor:"pointer"}}/>
                    </div>

                    <div className="modal_content_middle">
                        {!classOfCourse.course_permissions?.can_manage_topics ?  
                            <p className='mdl_blk'>A edição de um tópico não está disponível no plano <b>Free</b>.</p>
                        :
                            <>
                                <p className='mdl_blk'>Edite seu tópico através dos campos abaixo.</p>

                                <Form>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormControl variant="outlined" fullWidth>
                                                <CustomTextField
                                                    required
                                                    label="Assunto" 
                                                    onChange={(e) => setTopic({...topic, subject: e.target.value})} 
                                                    autoComplete='off'
                                                    value={topic.subject}
                                                    name='subject'
                                                    {...(fieldsError.subject && {error: 'true'})}
                                                    {...(!classOfCourse.course_permissions?.can_manage_topics && {disabled: 'true'})}
                                                    size="large"
                                                    fullWidth
                                                    inputProps={{ maxLength: 200 }}
                                                    InputLabelProps={{
                                                        sx: {
                                                        ...inputLabelStyles,
                                                        },
                                                    }}
                                                />

                                                <FormHelperText>
                                                    {fieldsError.subject}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormControl variant="outlined" fullWidth>
                                                <CustomTextField
                                                    required
                                                    label="Mensagem" 
                                                    onChange={(e) => setTopic({...topic, message: e.target.value})} 
                                                    autoComplete='off'
                                                    value={topic.message}
                                                    name='message'
                                                    {...(classOfCourse.course_permissions?.can_manage_topics && {multiline: 'true'})}
                                                    maxRows={10}
                                                    {...(fieldsError.message && {error: 'true'})}
                                                    {...(!classOfCourse.course_permissions?.can_manage_topics && {disabled: 'true'})}
                                                    size="large"
                                                    fullWidth
                                                    inputProps={{ maxLength: 2500 }}
                                                    InputLabelProps={{
                                                        sx: {
                                                        ...inputLabelStyles,
                                                        },
                                                    }}
                                                />

                                                <FormHelperText>
                                                    {fieldsError.message}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Form>
                            </>
                        }
                    </div>

                    <div className="modal_footer">
                        {!classOfCourse.course_permissions?.can_manage_topics ?
                            <Grid container >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CustomLoadingButton
                                        onClick={() => redirectToWithParams(history, '/recurso-bloqueado', `?curso=${classOfCourse.course_slug}`)}
                                        variant="outlined"
                                        size="large"
                                        color="secondary"
                                        fullWidth
                                    >
                                        <span>Saiba Mais</span>
                                    </CustomLoadingButton>
                                </Grid>
                            </Grid>
                        :
                            <Grid container >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CustomLoadingButton
                                        onClick={() => handleEditTopic()}
                                        endIcon={<ScheduleSendOutlinedIcon />}
                                        loading={loading}
                                        loadingPosition="end"
                                        variant="contained"
                                        {...(!classOfCourse.course_permissions?.can_manage_topics && {disabled: 'true'})}
                                        fullWidth
                                        color="secondary"
                                        size="large"
                                    >
                                        <span>Editar tópico</span>
                                    </CustomLoadingButton>
                                </Grid>
                            </Grid>
                        }
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default ModalEditTopic;