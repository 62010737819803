import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react'
import Logo from '../../../assets/imgs/logos/logoeduteka.png'
import { Context } from '../../../context/AuthContext'
import React, { useContext } from 'react'
import api from '../../../services/api';
import ModalLogin from '../../Modal/General/ModalLogin';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import NavBarLaboratoryMobile from '../../NavBar/Laboratory/Mobile';
import { useMediaQuery, useTheme } from '@material-ui/core';
import NavBarLaboratoryDesktop from '../../NavBar/Laboratory/Desktop';
import NavBarProfileAndNotification from '../../MaterialCustom/NavBarProfileAndNotification';
import NavBarSearchArea from '../../MaterialCustom/NavBarSearchArea';
import { redirectTo } from '../../../helpers';

function HeaderLaboratory () { 
    const { showMenuMain, setShowMenuMain, showSearchBar, setShowSearchBar, authenticated, /*showAlert, setShowAlert,*/ alertInTopClosed, setAlertInTopClosed } = useContext(Context)
    const [categorys, setCategorys] = useState([])
    const [modalLoginStatus, setModalLoginStatus] = useState(false)
    const history = useHistory()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    // const scrollTop = useRef('')
    // const showAlertCopy = useRef('')

    useEffect(() => { 
        function loadCategorys() {
            api.get('menu-site')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setCategorys(response.data.data.categorys)
                        // setShowAlert(response.data.data.main_alert)
                    }
                }).catch((error) => {
                })
        }

        loadCategorys()
    }, [])

    // useEffect(() => {
    //     const handleScroll = (event) => {
    //         function hideOrShowAlertOnScroll() {
    //             if (showAlert) {
    //                 showAlertCopy.current = showAlert
    //             }

    //             //Quando a página é rolada para baixo ele some o alerta que fica no topo, se é rolada novamente pro topo reaparece
    //             if (window.scrollY > 300 && scrollTop.current < window.scrollY) {
    //                 setShowAlert('')
    //             } else {
    //                 setShowAlert(showAlertCopy.current)
    //             }

    //             scrollTop.current = window.scrollY;
    //         }

    //         hideOrShowAlertOnScroll()
    //     };
    
    //     window.addEventListener('scroll', handleScroll);
    
    //     return () => {
    //       window.removeEventListener('scroll', handleScroll);
    //     };
    //   }, [showAlert]);

    // function hideAlertInTop(e) {
    //     e.preventDefault()

    //     setAlertInTopClosed(true)
    //     // setShowAlert('')
    // }

    return (
        <>
            {/* {!alertInTopClosed &&
                <div id="box-alert-important" className='box_alert_important'>
                    <span>{showAlert}</span>
                    <button onClick={(e) => hideAlertInTop(e)}>X</button>
                </div>
            } */}

            <div className={`header_only_brand logo_bar logo_labor`}>
                <img className="logo" width={98} onClick={() => redirectTo(history, '/')} src={Logo} alt="Eduteka" title="Eduteka" />
            </div>

            <header className={`short head_labor`}>

                <div className='top_main_left'>
                    {/* Menu pizza (somente mobile) */}
                    <MenuOutlinedIcon className='show_on_md_screen' onClick={() => setShowMenuMain(!showMenuMain)} color="neutral" fontSize="large"/>

                    {/* LOGO */}
                    <img className="logo hide_on_mobscr" width={98} onClick={() => redirectTo(history, '/')} src={Logo} alt="Eduteka" title="Eduteka" />
                </div>

                <div className='content_top_area'>
                    {/* NavBar - Menu */}
                    {isMobile ? 
                        <NavBarLaboratoryMobile showMenuMain={showMenuMain} setShowMenuMain={setShowMenuMain} categorys={categorys} />
                    : 
                        <NavBarLaboratoryDesktop categorys={categorys} />
                    }


                    {/* FORMULÁRIO DE PESQUISA */}
                    <NavBarSearchArea searchMode='laboratory'/>

                                        
                    {/* BOTÕES DE LOGAR E ASSIANAR AGORA (se não estiver logado) */}
                    {!authenticated ?
                        <div className="btns_on_top"></div>
                    :
                        <div className="btns_on_top">
                            <SearchOutlinedIcon className='hide_on_desktop2' color="neutral" onClick={() => setShowSearchBar(!showSearchBar)} sx={{cursor:"pointer"}}/>
                            <NavBarProfileAndNotification />
                        </div>
                    }
                </div>

            </header>

            <ModalLogin modalStatus={modalLoginStatus} setModalStatus={setModalLoginStatus} />
        </>
    );
}

export default HeaderLaboratory;