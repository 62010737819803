import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Menu } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';

function NavBarWebsiteDesktop({ categorys }) {
    const history = useHistory()
    const [anchorEl3, setAnchorEl3] = useState(null)
    const open3 = Boolean(anchorEl3);
    const [openCollapse, setOpenCollapse] = useState(false)
    const [openCollapse2, setOpenCollapse2] = useState(false)
    const [openCollapse3, setOpenCollapse3] = useState(false)

    const darkMode = {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        bgcolor: 'var(--plat-two)',
        color: 'var(--plat-six)',
        '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1 },
        '&:before': { content: '""', display: 'block', position: 'absolute', top: 0, left: 25, width: 10, height: 10, bgcolor: 'var(--plat-two)', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 }
    }

    const showSubmenu = (event, categSlug) => {
        if (categSlug === 'cursos') {
            setAnchorEl3(event.currentTarget);
        } 
    }

    const showCollapse = (event, categSlug) => {
        if (categSlug === 'front-end') {
            setOpenCollapse(!openCollapse)
        } else if (categSlug === 'back-end') {
            setOpenCollapse2(!openCollapse2)
        } else if (categSlug === 'devops') {
            setOpenCollapse3(!openCollapse3)
        }
    }

    const getOpenCollapse = (categSlug) => {
        if (categSlug === 'front-end') {
            return openCollapse
        } else if (categSlug === 'back-end') {
            return openCollapse2
        } else if (categSlug === 'devops') {
            return openCollapse3
        }
    }

    const handleCloseMenu = (categSlug, linkUrl = null) => {
        if (linkUrl) {
            history.push(linkUrl);
        }

        if (categSlug === 'cursos') {
            setAnchorEl3(null);
        }
    };

    return (
        <nav className='top_menu'>
            <ul>
                <li>
                    <Link to="/">
                        <span>Home</span>
                    </Link>
                </li>

                <li className='last-child'>
                    <a href={process.env.REACT_APP_URL_BASE_BLOG}>
                        <span>Blog</span>
                    </a>
                </li>

                <li>
                    <Link to='/#' onClick={(e) => showSubmenu(e, 'cursos')}>
                        <span>Cursos</span>

                        {open3 ? <ExpandLess /> : <ExpandMore />}
                    </Link>
                </li>
            </ul>


            {/* Submenu Cursos */}
            <Menu
                anchorEl={anchorEl3}
                open={open3}
                onClose={() => handleCloseMenu('cursos')}
                // onClick={handleClose2} // não fecha ao clicar nos itens
                PaperProps={{
                    elevation: 0,
                    sx: darkMode
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                disableScrollLock={true}
            >
                <List
                    sx={{ width: '100%', minWidth: 200, maxWidth: 360, bgcolor: 'var(--plat-two)', maxHeight: '500px', overflow: 'auto' }}
                    component="nav"
                    // aria-labelledby="nested-list-subheader"
                    // subheader={
                    //     <ListSubheader component="div" id="nested-list-subheader">
                    //         Qual área você tem interesse?
                    //     </ListSubheader>
                    // }
                >

                    {categorys.map((category, index) => {
                        return (
                            <div key={index}>
                                <ListItemButton 
                                    onClick={(e) => showCollapse(e, category.slug)}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "var(--plat-four)",
                                        }}
                                    }
                                >
                                    <ListItemIcon sx={{ minWidth:"36px" }}>
                                        {category.slug === 'front-end' && <ViewQuiltOutlinedIcon color="neutral" />}
                                        {category.slug === 'back-end' && <IntegrationInstructionsOutlinedIcon color="neutral" />}
                                        {category.slug === 'devops' && <SettingsSuggestOutlinedIcon color="neutral" />}
                                    </ListItemIcon>

                                    <ListItemText primary={category.name} />

                                    {category.subcategorys.length > 0 &&
                                        getOpenCollapse(category.slug) ? <ExpandLess /> : <ExpandMore />
                                    }
                                </ListItemButton>
                                    
                                <Collapse in={getOpenCollapse(category.slug)} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {category.subcategorys.map((subcateg, index) => {
                                            return (
                                                <ListItemButton 
                                                    key={index} 
                                                    sx={{
                                                        pl: 4 ,
                                                        "&:hover": {
                                                            backgroundColor: "var(--plat-four)",
                                                        }}
                                                    }
                                                    component={Link} 
                                                    to={`/cursos/${category.slug}/${subcateg.slug}`} 
                                                    onClick={() => handleCloseMenu('cursos')}
                                                >
                                                    <ListItemText primary={subcateg.subcategory} />
                                                </ListItemButton>
                                            )
                                        })}
                                    </List>
                                </Collapse>
                            </div>
                        )
                    })}
                </List>
            </Menu>
        </nav>
    );
}

export default NavBarWebsiteDesktop;