import { Avatar, CircularProgress, FormControl, FormHelperText, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState, useContext, useRef } from 'react'
import {TabPanel, a11yProps} from '../../../components/TabPanel';
import './style.css'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import {CustomTabs, CustomTab} from '../../../components/MaterialCustom/Laboratory/Tabs/CustomTabs'
import BannerLoader from '../../../components/Loaders/Divs/Laboratory/BannerLoader';
import api from "../../../services/api";
import { Context } from "../../../context/AuthContext";
import { LoadingButton } from '@mui/lab';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { CustomLoadingButtonOutlined } from '../../../components/MaterialCustom/Laboratory/LoadingButton/CustomLoadingButtonOutlined';
import { toast } from 'react-toastify';
import ForumIcon from '@mui/icons-material/Forum';
import TopicIcon from '@mui/icons-material/Topic';
import ModalCreateTopic from '../../../components/Modal/Laboratory/ModalCreateTopic';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { CustomTextFieldStandard, inputLabelStylesB } from '../../../components/MaterialCustom/Laboratory/TextField/CustomTextFieldStandard';
import Form from '../../../components/Form';
import TopicCommentMenu from '../../../components/MaterialCustom/Laboratory/TopicCommentMenu/TopicCommentMenu';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ModalEditTopic from '../../../components/Modal/Laboratory/ModalEditTopic';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import SpinnerLoader from '../../../components/Loaders/SpinnerLoader';
import { redirectTo, redirectToWithParams, upperFirstLetter } from '../../../helpers';

function LaboratoryClassVideo () {
    const { setMetaTagsOfPage, handleLogout, userPicture } = useContext(Context)
    const history = useHistory()
    const {categorySlug, subCategorySlug, courseSlug, classId} = useParams()
    const [singleLoader, setSingleLoader] = useState(true)
    const [spinnerBtnLoader, setSpinnerBtnLoader] = useState(false)
    // const [courseIschecked, setCourseIschecked] = useState(false)
    const [classVideo, setClassVideo] = useState({})
    const [classVideoFiles, setClassVideoFiles] = useState([])
    const [forumTopics, setForumTopics] = useState([])
    const [forumTotalTopics, setForumTotalTopics] = useState(0)
    const [modalCrtTopicStatus, setModalCrtTopicStatus] = useState(false)
    const [modalUpdtTopicStatus, setModalUpdtTopicStatus] = useState(false)
    const [numTopicPage, setNumTopicPage] = useState(1)
    const [spinnerLoaderBtn, setSpinnerLoaderBtn] = useState(false)
    const [topicIsOpen, setTopicIsOpen] = useState(false)
    const [topicClicked, setTopicClicked] = useState({})
    const [loadingSend, setLoadingSend] = useState(false)
    const [loadingAllComments, setLoadingAllComments] = useState(false)
    const [numTopicCommentsPage, setNumTopicCommentsPage] = useState(1)
    const [comments, setComments] = useState([])
    
    const [comment, setComment] = useState({
        comment: '',
        topic_id: ''
    })
    const [fieldsError, setFieldsError] = useState({
        comment: '',
        topic_id: '',
    })

    const [commentEditing, setCommentEditing] = useState({
        id: '',
        comment: ''
    })
    const [fieldsEditingError, setFieldsEditingError] = useState({
        id: '',
        comment: '',
    })

    //Evita que ao inserirem novos topicos os dados venham duplicados na paginação do "carregar mais"
    const maxIdByTopics = useRef(0)

    //Evita que ao inserirem novos comentarios os dados venham duplicados na paginação do "carregar mais"
    const maxIdByTopicComments = useRef(0)

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setMetaTagsOfPage('Laboratório', 'Uma plataforma de ensino feita para designers e programadores!')
        // console.log('render component LaboratorySingle')
        loadClassVideo()

        window.scrollTo(0, 0)
    }, [classId])

    async function loadClassVideo() {
        setSingleLoader(true)

        await api.get(`class-video-lab/${classId}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    setClassVideo(response.data.data.course_class)
                    setClassVideoFiles(response.data.data.files_didactic)
                    setForumTopics(response.data.data.forum_topics)
                    setForumTotalTopics(response.data.data.forum_total_topics ?? 0)
                    maxIdByTopics.current = response.data.data.forum_max_id
                    setSingleLoader(false)
                } else {
                    history.push("/erro/404")
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    handleLogout()
                } else if (error.response.status === 403) {
                    redirectToWithParams(history, '/recurso-bloqueado', `?curso=${courseSlug}`)
                }
            })
    }

    async function loadMoreTopics() {
        setSpinnerLoaderBtn(true)

        await api.get(`class-video-lab/${classId}/forum/topics/${numTopicPage + 1}`, { params: { max_id: maxIdByTopics.current } })
            .then((response) => {
                if (response.data.status === 'success') {
                    setForumTopics([...forumTopics, ...response.data.data.forum_topics])
                    setNumTopicPage(numTopicPage + 1)
                    setSpinnerLoaderBtn(false)
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout()
                } 
                // else if (error.response.status === 403) {
                //     redirectToWithParams(history, '/recurso-bloqueado', `?curso=${courseSlug}`)
                // }
            })
    }

    //////////////////
    // const [modalCourseDtlsStatus, setModalCourseDtlsStatus] = useState(false)
    // let classMouseHover = useRef('')
    // let modalBlock = useRef(false);

    async function markAsChecked(e) {
        e.preventDefault()
        setSpinnerBtnLoader(true)

        // setTimeout(() => {
            // setSpinnerBtnLoader(false)
            // setCourseIschecked(!courseIschecked)
        // }, 1500)

        //envia requisicação pro back
        await api.post(`class-video-watched/${classId}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    setClassVideo({...classVideo, ...{is_watched: !classVideo.is_watched}})
                    toast.success(response.data.message)
                } else {
                    toast.error(response.data.message)
                }

                setSpinnerBtnLoader(false)
            }).catch((error) => {
                toast.error('Falha ao alterar aula!')
                setSpinnerBtnLoader(false)

                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    async function showTopicClicked(topic) {
        setTopicIsOpen(!topicIsOpen)
        setTopicClicked(topic)
        setLoadingAllComments(true)

        //buscar comentários do topico em questão
        await api.get(`load-topic-comments/${topic.id}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    // toast.success(response.data.message)
                    setComments(response.data.data.comments)
                    maxIdByTopicComments.current = response.data.data.topic_comments_max_id
                    setNumTopicCommentsPage(1)
                } 
                // else {
                //     toast.error(response.data.message)
                // }

                setLoadingAllComments(false)
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout()
                }

                setLoadingAllComments(false)
            })
    }

    async function sendComment() {
        setLoadingSend(true)

        await api.post(`create-topic-comment/${classId}`, comment)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)

                    setComments([...[response.data.data.comment], ...comments])
                    setTopicClicked({...topicClicked, ...{total_comments: topicClicked.total_comments + 1}})

                    //Aumenta na tabela tbem o num. total de comentarios
                    let newForumTopics = forumTopics.map((forum) => {
                        if (forum.id === topicClicked.id) {
                            forum.total_comments = topicClicked.total_comments + 1
                        }

                        return forum
                    })
                    setForumTopics(newForumTopics)

                    setComment({topic_id: '', comment: ''})
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({topic_id: '', comment: ''});
                setLoadingSend(false)
            }).catch((error) => {
                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data);
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else if (error.response.status === 403) {
                    toast.error(error.response.data.message)
                }

                setLoadingSend(false)
            })
    }

    async function sendEditComment() {
        // setLoadingSend(true)
        let commentEditingId = commentEditing.id
        let commentEditingComment = commentEditing.comment

        //Envia comentário editado
        await api.post(`edit-topic-comment/${commentEditingId}`, commentEditing)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)

                    let newComments = comments.map((comt) => {
                        if (comt.id === commentEditingId) {
                            comt.comment = commentEditingComment
                            comt.edit_enabled = false
                        }
                        
                        return comt;
                    })

                    setComments(newComments)
                    setCommentEditing({id: '', comment: ''})
                } else {
                    toast.error(response.data.message)
                }

                setFieldsEditingError({id: '', comment: ''});
                // setLoadingSend(false)
            }).catch((error) => {
                if (error.response.status === 422) {
                    setFieldsEditingError(error.response.data.data);
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else if (error.response.status === 403) {
                    toast.error(error.response.data.message)
                }

                // setLoadingSend(false)
            })
    }

    async function loadMoreTopicsComments() {
        setSpinnerLoaderBtn(true)

        //buscar comentários do topico em questão com base na pagina
        await api.get(`load-topic-comments/${topicClicked.id}/${numTopicCommentsPage + 1}`, { params: { max_id: maxIdByTopicComments.current } })
            .then((response) => {
                if (response.data.status === 'success') {
                    setComments([...comments, ...response.data.data.comments])
                    setNumTopicCommentsPage(numTopicCommentsPage + 1)
                    setSpinnerLoaderBtn(false)
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout()
                } 
                // else if (error.response.status === 403) {
                //    redirectToWithParams(history, '/recurso-bloqueado', `?curso=${courseSlug}`)
                // }
            })
    }

    function handleCancelEditComment() {
        let newComments = comments.map((comt) => {
            comt.edit_enabled = false
            return comt;
        })

        setComments(newComments)
        setCommentEditing({id: '', comment: ''})
    }
    
    return (
        <div className="course_videos">
            {singleLoader ?
                <BannerLoader/>
            :
                <>
                    <div className="crs_class_vid_header_box"> 
                        <div className="crs_cl_vid_header">
                            <div className="cl_vid_header_top">
                                <h1>Aula {classVideo.order}</h1>
                                <h2>{classVideo.title}</h2>
                                <p>{classVideo.description}</p>
                            </div>

                            <div className="cl_vid_header_bottom">
                                <CustomLoadingButtonOutlined
                                    onClick={(e) => redirectTo(history, `/curso/${categorySlug}/${subCategorySlug}/${courseSlug}`)}
                                    startIcon={<KeyboardBackspaceOutlinedIcon />}
                                    sx={{marginRight:"16px"}}
                                    color="neutral"
                                    variant="outlined"
                                    size="large"
                                >
                                    <span>Voltar</span>
                                </CustomLoadingButtonOutlined>

                                <CustomLoadingButtonOutlined
                                    onClick={(e) => markAsChecked(e)}
                                    endIcon={classVideo.is_watched ? <CheckCircleOutlineOutlinedIcon /> : <RadioButtonUncheckedOutlinedIcon />}
                                    loading={spinnerBtnLoader}
                                    loadingPosition="end"
                                    variant="outlined"
                                    size="large"
                                    color="secondary"
                                >
                                    <span>{classVideo.is_watched ? 'Assistido' : 'Marcar como assistido'}</span>
                                </CustomLoadingButtonOutlined>
                            </div>
                        </div>
                    </div>

                    <div className="crs_class_vid_middle_box">
                        <CustomLoadingButtonOutlined
                            onClick={(e) => redirectTo(history, `/curso/${categorySlug}/${subCategorySlug}/${courseSlug}/${classVideo.prev_class}`)}
                            startIcon={<UndoOutlinedIcon />}
                            variant="outlined"
                            size="large"
                            color="secondary"
                            disabled={classVideo.prev_class === null ? true : false}
                        >
                            <span>Anterior</span>
                        </CustomLoadingButtonOutlined>

                        <CustomLoadingButtonOutlined
                            onClick={(e) => redirectTo(history, `/curso/${categorySlug}/${subCategorySlug}/${courseSlug}/${classVideo.next_class}`)}
                            endIcon={<RedoOutlinedIcon />}
                            variant="outlined"
                            size="large"
                            color="secondary"
                            disabled={classVideo.next_class === null ? true : false}
                        >
                            <span>Próximo</span>
                        </CustomLoadingButtonOutlined>
                    </div>

                    <div className="crs_class_video_box">  
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} sm={12} md={11} lg={10} xl={8}>
                                <div className="video">
                                    <iframe src={classVideo.url_video} className="embed" frameborder="0" allow="autoplay; fullscreen" title={classVideo.title} allowfullscreen></iframe>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </>
            }

            <div className='crs_vid_content_all'>
                <div className="crs_content_timeline">
                    <Grid container spacing={2} /*className="wb_sec_grid_center"*/>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <CustomTabs 
                                        value={value} 
                                        onChange={handleChange} 
                                        // variant="fullwith" 
                                        // scrollButtons={false} 
                                        variant="scrollable"
                                        allowScrollButtonsMobile
                                        aria-label="scrollable force tabs example"
                                        // centered 
                                        // aria-label="basic tabs example" 
                                        sx={{
                                            // '& .Mui-selected': { color: 'var(--plat-two) !important' },
                                            // '& .MuiTabs-indicator': { backgroundColor: 'var(--first-color)' },
                                            // '& .MuiTab-root': { color: 'blue' }
                                        }}
                                    >
                                        <CustomTab icon={<FileDownloadOutlinedIcon/>} iconPosition="start" label="Material Didático" {...a11yProps(0)}  />
                                        <CustomTab icon={<ForumOutlinedIcon/>} iconPosition="start" label="Fórum" {...a11yProps(1)}  />
                                    </CustomTabs>
                                </Box>

                                <TabPanel value={value} index={0}>
                                    {singleLoader ?
                                        <SpinnerLoader borderColorImp="var(--plat-seven)" />
                                    :
                                        <div className="crs_sgl_infos_all">
                                            <div className="crs_sgl_infos_new">
                                                <h2>Materiais didáticos:</h2>

                                                <div className="crs_sgl_infos_dscp">
                                                    <div>
                                                        {classVideoFiles.length <= 0 ?
                                                            <p>Essa aula não possui material!</p>
                                                        :
                                                            <>
                                                                <p>Abaixo você tem acesso ao resumo da aula!</p>
                                                                
                                                                {classVideoFiles.map((file, index) => {
                                                                    return (
                                                                        <a href={file.url} target='__blank' key={index} className='download_link'>
                                                                            <FileDownloadOutlinedIcon sx={{color: "var(--first-color)", marginRight:"12px"}} />
                                                                            <p>{file.name}</p>
                                                                        </a>
                                                                    )
                                                                })}
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='crs_sgl_infos_new'>
                                                <div className="crs_sgl_intro_infos">
                                                    <h2>Código fonte:</h2>

                                                    <div className="crs_sgl_infos_dscp">
                                                        <div>
                                                            {classVideo.link_of_code ?
                                                                <>
                                                                    <p>Abaixo você tem acesso ao código fonte completo do curso!</p>
                                                                
                                                                    <a href={classVideo.link_of_code} target='__blank' className='download_link'>
                                                                        <FileDownloadOutlinedIcon sx={{color: "var(--first-color)", marginRight:"12px"}} />
                                                                        <p>Código completo</p>
                                                                    </a>
                                                                </>
                                                            :
                                                                <p>Esse curso não possui código fonte!</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </TabPanel>

                                <TabPanel value={value} index={1}>
                                    {singleLoader ?
                                        <SpinnerLoader borderColorImp="var(--plat-seven)" />
                                    :
                                        <>
                                            {!topicIsOpen ?
                                                <div className="crs_sgl_infos_all">
                                                    <div className="crs_sgl_classes">
                                                        <div className="sgl_classes_header">
                                                            <div className="sgl_classes_head_left">
                                                                <ForumIcon color="secondary" />

                                                                <div className="sgl_classes_title">
                                                                    <h2>Fórum</h2>
                                                                    <span className='top_tic_main'>
                                                                        {forumTotalTopics > 1 ? 
                                                                            `${forumTotalTopics} tópicos foram abertos nessa aula.` 
                                                                        : forumTotalTopics === 1 ?
                                                                            `${forumTotalTopics} tópico foi aberto nessa aula.` 
                                                                        :
                                                                            `Nenhum tópico foi aberto nessa aula.` 
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className="sgl_classes_head_right">
                                                                <LoadingButton
                                                                    onClick={() => setModalCrtTopicStatus(!modalCrtTopicStatus)}
                                                                    endIcon={<TopicIcon />}
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                >
                                                                    <span>Criar novo tópico</span>
                                                                </LoadingButton>
                                                            </div>
                                                        </div>

                                                        <div className="sgl_classes_sub_header">
                                                            <h1>Ficou com alguma dúvida durante a aula?</h1>
                                                            <p>Primeiramente confira se há um tópico com o mesmo assunto, se não tiver crie um novo tópico!</p>
                                                        </div>

                                                        <div className="sgl_classes_content">
                                                            {forumTopics?.length !== (0 || null || '' || undefined) &&
                                                                <div className="box_tbl_plat">
                                                                    <table className="tbl_plat_classes">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Assunto</th>
                                                                                <th>Autor</th>
                                                                                <th>Status</th>
                                                                                <th>Comentários</th>
                                                                                <th>Publicado</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody> 
                                                                            {forumTopics?.map((topic, index) => {
                                                                                return (
                                                                                    <tr key={index} onClick={() => showTopicClicked(topic)}>
                                                                                        <td>{topic.subject}</td>
                                                                                        <td>
                                                                                            <div className='box_circle_img'>
                                                                                                {topic.author.picture ?
                                                                                                    <img className="circle_author" src={topic.author.picture} alt={topic.author.name} title={topic.author.name}/>
                                                                                                :
                                                                                                    <Avatar sx={{ width: 32, height: 32 }} alt={topic.author.name} title={topic.author.name}>{upperFirstLetter(topic.author.name)}</Avatar>
                                                                                                }
                                                                                            </div>      
                                                                                        </td>
                                                                                        <td>{topic.status}</td>
                                                                                        <td><div className='circle_commts'>{topic.total_comments}</div></td>
                                                                                        <td className="alignment">{topic.publication_date}</td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody> 
                                                                    </table>

                                                                    {forumTotalTopics !== forumTopics.length &&
                                                                        <div className='load_more_topics'>
                                                                            <CustomLoadingButtonOutlined
                                                                                onClick={() => loadMoreTopics()}
                                                                                endIcon={<MoreHorizIcon />}
                                                                                loading={spinnerLoaderBtn}
                                                                                loadingPosition="end"
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                            >
                                                                                <span>Carregar mais</span>
                                                                            </CustomLoadingButtonOutlined>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </div> 
                                                    </div>
                                                </div>
                                            :
                                                <div className="crs_sgl_infos_all">
                                                    <div className="crs_sgl_classes">
                                                        <div className="sgl_classes_header_back" onClick={() => setTopicIsOpen(!topicIsOpen)}>
                                                            <KeyboardBackspaceOutlinedIcon color="secondary" />
                                                            <h2>Voltar</h2>
                                                        </div>

                                                        <div className="sgl_classes_header">
                                                            <div className="sgl_classes_head_left">
                                                                <TopicIcon color="secondary" />

                                                                <div className="sgl_classes_title">
                                                                    <h2>Tópico <span>{topicClicked.status}</span></h2>
                                                                    <span>{topicClicked.subject}</span>
                                                                </div>
                                                            </div>

                                                            <div className="sgl_classes_head_right">
                                                                {/* Permite só o autor editar o próprio tópico */}
                                                                {topicClicked.author_id === JSON.parse(localStorage?.getItem('user')).id &&
                                                                    <LoadingButton
                                                                        onClick={() => setModalUpdtTopicStatus(!modalUpdtTopicStatus)}
                                                                        endIcon={<EditOutlinedIcon />}
                                                                        variant="outlined"
                                                                        color="secondary"
                                                                        sx={{marginRight: '24px'}}
                                                                    >
                                                                        <span>Editar</span>
                                                                    </LoadingButton> 
                                                                }

                                                                <div className='topic_post_date'>
                                                                    <CalendarMonthOutlinedIcon color='fifthcolor'/>
                                                                    <span className='dt_pub'>{topicClicked.publication_date}</span>
                                                                </div>

                                                                {topicClicked.author.picture ?
                                                                    <img className="circle_author" src={topicClicked.author.picture} alt={topicClicked.author.name} title={topicClicked.author.name}/>
                                                                :
                                                                    <Avatar sx={{ width: 32, height: 32, marginLeft: '24px' }} alt={topicClicked.author.name} title={topicClicked.author.name}>{upperFirstLetter(topicClicked.author.name)}</Avatar>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="sgl_topic_sub_header">
                                                            <p style={{ whiteSpace: "pre-wrap" }}>
                                                                {topicClicked.message}
                                                            </p>
                                                        </div>

                                                        <div className="sgl_classes_content">
                                                            <div className='sgl_comment_header'>
                                                                <TextsmsOutlinedIcon color='fifthcolor' />
                                                                <p>{topicClicked.total_comments === 0 ? 'Nenhum comentário' : topicClicked.total_comments === 1 ? '1 comentário' : `${topicClicked.total_comments} comentários`}</p>
                                                            </div>

                                                            {!classVideo.course_permissions?.can_manage_topics ?
                                                                <div className='sgl_comment_sender_block'>
                                                                    <LockOutlinedIcon color='secondary' fontSize='large'/>

                                                                    <div className='comm_text_block'>
                                                                        <h5>Acesso Bloqueado</h5>
                                                                        <p>Você não possui permissão para visualizar os comentários.</p>
                                                                    </div>
                                                                </div>
                                                            :
                                                                <div className='sgl_comment_sender'>
                                                                    <div className='comm_left'>
                                                                        {userPicture ?
                                                                            <img className="circle_author_2" src={userPicture} alt={JSON.parse(localStorage?.getItem('user')).name} title={JSON.parse(localStorage?.getItem('user')).name}/>
                                                                        :
                                                                            <Avatar sx={{ width: 40, height: 40 }} alt={JSON.parse(localStorage?.getItem('user')).name} title={JSON.parse(localStorage?.getItem('user')).name}>{upperFirstLetter(JSON.parse(localStorage?.getItem('user')).name)}</Avatar>
                                                                        }
                                                                    </div>

                                                                    <div className='comm_right'>
                                                                        <h5>{JSON.parse(localStorage?.getItem('user')).name}</h5>

                                                                        <Form>
                                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                <FormControl variant="outlined" fullWidth>
                                                                                    <CustomTextFieldStandard 
                                                                                        id="standard-basic" 
                                                                                        color='secondary' 
                                                                                        // required
                                                                                        label="Adicione um comentário..." 
                                                                                        variant="standard" 
                                                                                        autoComplete='off'
                                                                                        onChange={(e) => setComment({...comment, comment: e.target.value, topic_id: topicClicked.id})} 
                                                                                        multiline
                                                                                        maxRows={10}
                                                                                        value={comment.comment}
                                                                                        {...(fieldsError.comment && {error: 'true'})}
                                                                                        size="large"
                                                                                        name='message'
                                                                                        fullWidth
                                                                                        inputProps={{ maxLength: 2500 }}
                                                                                        InputLabelProps={{
                                                                                            sx: {
                                                                                                ...inputLabelStylesB,
                                                                                            },
                                                                                        }}
                                                                                    />

                                                                                    <FormHelperText>
                                                                                        {fieldsError.comment}
                                                                                    </FormHelperText>
                                                                                </FormControl>
                                                                            </Grid>
                                                                        </Form>
                                                                    </div>

                                                                    <div className='comm_bottom'>
                                                                        <LoadingButton
                                                                            onClick={() => setComment({comment: '', topic_id: ''})}
                                                                            // endIcon={<TopicIcon />}
                                                                            variant="outlined"
                                                                            color="secondary"
                                                                            sx={{marginRight: '10px'}}
                                                                        >
                                                                            <span>Limpar</span>
                                                                        </LoadingButton> 

                                                                        <LoadingButton
                                                                            onClick={() => sendComment()}
                                                                            loading={loadingSend}
                                                                            // loadingPosition="end"
                                                                            // endIcon={<TopicIcon />}
                                                                            variant="outlined"
                                                                            color="secondary"
                                                                        >
                                                                            <span>Enviar</span>
                                                                        </LoadingButton>            
                                                                    </div>
                                                                </div>
                                                            }

                                                            <div className='sgl_comments_box'>
                                                                {!loadingAllComments ?
                                                                    <>
                                                                        {comments.map((comm, index) => {
                                                                            return (
                                                                                <div key={index} className='sgl_comm_box'>
                                                                                    <div className='comm_box_left'>
                                                                                        {comm.author.picture ?
                                                                                            <img className='circle_author_3' src={comm.author.picture} alt={comm.author.name} title={comm.author.name}/>
                                                                                        :
                                                                                            <Avatar sx={{ width: 40, height: 40 }} alt={comm.author.name} title={comm.author.name}>{upperFirstLetter(comm.author.name)}</Avatar>
                                                                                        }
                                                                                    </div>
                                                                                    
                                                                                    <div className="comm_box_all_right">
                                                                                        <div className='comm_box_right'>
                                                                                            <h5>{comm.author.name}</h5>
                                                                                            <span className='comm_dt_pretty'>{comm.date}</span>
                                                                                        </div>
        
                                                                                        {comm.edit_enabled === true ?
                                                                                            <>
                                                                                                <div className='comm_full_box_right'>
                                                                                                    <Form>
                                                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                                            <FormControl variant="outlined" fullWidth>
                                                                                                                <CustomTextFieldStandard 
                                                                                                                    // id="standard-basic" 
                                                                                                                    color='secondary' 
                                                                                                                    // required
                                                                                                                    label="Editando comentário..." 
                                                                                                                    variant="standard" 
                                                                                                                    autoComplete='off'
                                                                                                                    onChange={(e) => setCommentEditing({...commentEditing, comment: e.target.value, id: comm.id})} 
                                                                                                                    multiline
                                                                                                                    maxRows={10}
                                                                                                                    value={commentEditing.comment}
                                                                                                                    {...(fieldsEditingError.comment && {error: 'true'})}
                                                                                                                    size="medium"
                                                                                                                    name='comment'
                                                                                                                    fullWidth
                                                                                                                    inputProps={{ maxLength: 2500 }}
                                                                                                                    InputLabelProps={{
                                                                                                                        sx: {
                                                                                                                            ...inputLabelStylesB,
                                                                                                                        },
                                                                                                                    }}
                                                                                                                />
                                
                                                                                                                <FormHelperText>
                                                                                                                    {fieldsEditingError.comment}
                                                                                                                </FormHelperText>
                                                                                                            </FormControl>
                                                                                                        </Grid>
                                                                                                    </Form>
                                                                                                </div>

                                                                                                <div className='comm_full_bottom'>
                                                                                                    <LoadingButton
                                                                                                        onClick={() => handleCancelEditComment()}
                                                                                                        // endIcon={<TopicIcon />}
                                                                                                        variant="outlined"
                                                                                                        color="secondary"
                                                                                                        sx={{marginRight: '10px'}}
                                                                                                    >
                                                                                                        <span>Cancelar</span>
                                                                                                    </LoadingButton> 
                                
                                                                                                    <LoadingButton
                                                                                                        onClick={() => sendEditComment()}
                                                                                                        loading={loadingSend}
                                                                                                        // loadingPosition="end"
                                                                                                        // endIcon={<TopicIcon />}
                                                                                                        variant="outlined"
                                                                                                        color="secondary"
                                                                                                    >
                                                                                                        <span>Salvar</span>
                                                                                                    </LoadingButton>            
                                                                                                </div>
                                                                                            </>
                                                                                        :
                                                                                            <div className='comm_box_right'>
                                                                                                <p style={{ whiteSpace: "pre-wrap" }}>
                                                                                                    {comm.comment}
                                                                                                </p>
                                                                                            </div>
                                                                                        }
                                                                                    </div>

                                                                                    <div className='comm_box_right_final'>
                                                                                        {classVideo.course_permissions?.can_manage_quiz &&
                                                                                            <>
                                                                                                {/* Só mostra os três pontinhos(menu editar e excluir comentario) se o comentário for do próprio usuario logado */}
                                                                                                {JSON.parse(localStorage?.getItem('user')).id === comm.user_id &&
                                                                                                    <TopicCommentMenu comment={comm} forumTopics={forumTopics} setForumTopics={setForumTopics} setComments={setComments} comments={comments} setLoadingAllComments={setLoadingAllComments} setTopicClicked={setTopicClicked} topicClicked={topicClicked} setCommentEditing={setCommentEditing}/>
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}

                                                                        {((topicClicked.total_comments !== comments.length) && classVideo.course_permissions?.can_manage_topics) &&
                                                                            <div className='load_more_topics_comms'>
                                                                                <CustomLoadingButtonOutlined
                                                                                    onClick={() => loadMoreTopicsComments()}
                                                                                    endIcon={<MoreHorizIcon />}
                                                                                    loading={spinnerLoaderBtn}
                                                                                    loadingPosition="end"
                                                                                    variant="outlined"
                                                                                    color="secondary"
                                                                                >
                                                                                    <span>Carregar mais</span>
                                                                                </CustomLoadingButtonOutlined>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                :
                                                                    <div className='sgl_comm_box'>
                                                                        <CircularProgress sx={{color: "var(--plat-seven)"}} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                </TabPanel>

                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </div>

            <ModalCreateTopic forumTotalTopics={forumTotalTopics} setForumTotalTopics={setForumTotalTopics} setForumTopics={setForumTopics} forumTopics={forumTopics} modalStatus={modalCrtTopicStatus} setModalStatus={setModalCrtTopicStatus} classOfCourse={classVideo}/>
            <ModalEditTopic topicClicked={topicClicked} setTopicClicked={setTopicClicked} setForumTopics={setForumTopics} forumTopics={forumTopics} modalStatus={modalUpdtTopicStatus} setModalStatus={setModalUpdtTopicStatus} classOfCourse={classVideo}/>
        </div>
    )
}

export default LaboratoryClassVideo