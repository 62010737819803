
//Validação de Dados no Front-end
function validateDataFront(validations, fieldName, fieldValue) {
    let messageErrosFeedback = '';

    for (let [validate, info] of Object.entries(validations)) {

        if (validate === 'required') {
            if (!fieldValue) {
                messageErrosFeedback = info.message
                break;
            }
        } 

        else if (validate === 'email') {
            if (!validateEmail(fieldValue)) {
                messageErrosFeedback = info.message
                break;
            }
        } 

        else if (validate === 'min') {
            if (fieldValue.length < info.quantity) {
                messageErrosFeedback = info.message
                break;
            }
        } 
        
        else if (validate === 'max') {
            if (fieldValue.length > info.quantity) {
                messageErrosFeedback = info.message
                break;
            }
        }

        else if (validate === 'length') {
            if (fieldValue.length < info.quantity) {
                messageErrosFeedback = info.message
                break;
            }
        }

    }

    renderErrorMessage(fieldName, messageErrosFeedback)
}

function validateDataBack(validationsErrors) {
    for (let [fieldName, error] of Object.entries(validationsErrors)) {
        renderErrorMessage(fieldName, error)
    }
}

function renderErrorMessage(fieldName, messageErrosFeedback) {
    let inputBorderRed = document.getElementsByName(fieldName)[0] //as vezes tem dois elementos com mesmo name ai nao bota a bordar vermelha
    let divShowErrorMessage = document.getElementById(fieldName)

    //não encontrou a div na tela, talvez por causa das abas
    if (!inputBorderRed || !divShowErrorMessage) { 
        return true
    }

    if (!messageErrosFeedback) {
        inputBorderRed.style.borderColor = ''
        divShowErrorMessage.innerHTML = ''
    } else {
        inputBorderRed.style.borderColor = 'rgba(237, 67, 55, .7)'
        divShowErrorMessage.innerHTML = messageErrosFeedback
    }
}

//Valida se é um e-mail válido
const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

const prettyUserName = (fullName, onlyFirstName = true) => {
    let firstName = fullName.split(' ')[0];

    if (onlyFirstName) {
        return String(firstName[0])?.toUpperCase() + String(firstName)?.slice(1);
    }

    return String(firstName[0])?.toUpperCase() + String(fullName)?.slice(1);
}

const upperFirstLetter = (word) => {
    return word?.charAt(0)?.toUpperCase();
}

const textLimit = (text, letterLength = 18) => {
    return text?.substr(0, letterLength)
}

const redirectTo = (history, linkUrl) => {
    if (linkUrl) {
        history.push(linkUrl)
    }
}

const redirectToWithEvent = (history, event, linkUrl) => {
    event.preventDefault();

    if (linkUrl) {
        history.push(linkUrl)
    }
}

const redirectToAnExternalLink = linkUrl => {
    window.location.href = linkUrl;
}

const redirectToNewTab = linkUrl => {
    window.open(linkUrl, '_blank', 'noopener,noreferrer');
};

const redirectToWithParams = (history, linkUrl = '/', queryString = '') => { // queryString ex: ?q=brazil&id=12345
    history.push({
        pathname: linkUrl,
        search: queryString
    })
}

const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');

    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return "";
}

/*
    Sharing Cookies on Domains and Subdomains
    - Salvo o cookie do domínio principal dessa forma consigo compartilhar o access_token no dominio e subdominio (eduteka.com.br e blog.eduteka.com.br)
    - Como resultado nao precisa logar duas vezes
*/
const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=" + process.env.REACT_APP_DOMAIN_COOKIE + ";path=/";
}

const deleteCookie = (cname) => {
    setCookie(cname, "", null , null , null, 1);
}

//NAO APAGAR POIS UM DIA PODERÁ SER USADO EM OUTRA COISA
// const base64toBlob = (base64Data: string) => {
//     const sliceSize = 1024;
//     const byteCharacters = atob(base64Data);
//     const bytesLength = byteCharacters.length;
//     const slicesCount = Math.ceil(bytesLength / sliceSize);
//     const byteArrays = new Array(slicesCount);
  
//     for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
//       const begin = sliceIndex * sliceSize;
//       const end = Math.min(begin + sliceSize, bytesLength);
  
//       const bytes = new Array(end - begin);
//       for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
//         bytes[i] = byteCharacters[offset].charCodeAt(0);
//       }
//       byteArrays[sliceIndex] = new Uint8Array(bytes);
//     }
//     return new Blob(byteArrays, { type: "application/pdf" });
// }

export { validateDataFront, validateDataBack, redirectTo, redirectToWithEvent, redirectToAnExternalLink, redirectToNewTab, redirectToWithParams, getCookie, setCookie, deleteCookie, prettyUserName, upperFirstLetter, textLimit /*, base64toBlob*/ } 