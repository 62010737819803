import './style.css'
import { Context } from '../../../context/AuthContext'
import React, { useContext, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { CustomLoadingButtonOutlined } from '../../../components/MaterialCustom/Laboratory/LoadingButton/CustomLoadingButtonOutlined';
import SectionBeStudent from '../../../components/Website/SectionBeStudent'
import { redirectTo } from '../../../helpers';

function ErrorScreen ({codeHttpInline}) {
    const { setMetaTagsOfPage, showAlert, alertInTopClosed } = useContext(Context)
    const {codeHttp} = useParams()
    const history = useHistory()

    useEffect(() => {
        let getCodeHttp = codeHttp ?? codeHttpInline;
        getCodeHttp = parseInt(getCodeHttp)

        if (getCodeHttp === 403) {
            setMetaTagsOfPage(`Sem permissão de acesso`, 'Você não tem permissão de acessar esse recurso!')
        } else if (getCodeHttp === 404) {
            setMetaTagsOfPage(`Página não encontrada`, 'A página que você tentou acessar não foi encontrada!')
        } else {
            setMetaTagsOfPage(`Opssss! Erro ${codeHttp ?? codeHttpInline}`, 'Houve alguma falha no processamento da requisição!')
        }
        
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className={`box_blog ${(showAlert !== '' && !alertInTopClosed) && 'alert-active'}`}>

                <div className="blog_content">
                    <div className='box_error'>

                        {(parseInt(codeHttp) === 404 || codeHttpInline === 404) && 
                            <>
                                <div className='box_error_tit'>
                                    <WarningAmberOutlinedIcon fontSize="large" color="neutral"/>
                                    <h1 className='error404'>Opssss!</h1>
                                </div>
                                
                                <span className='errorcode'><b>404</b> | A página que você está tentando acessar não foi encontrada em nossos servidores!</span>
                                
                                <div className='box_btn_error'>
                                    <CustomLoadingButtonOutlined
                                        onClick={() => redirectTo(history, '/')}
                                        startIcon={<ArrowBackIosNewOutlinedIcon />}
                                        variant="outlined"
                                        fullWidth
                                        color='secondary'
                                        size="large"
                                        sx={{marginTop:"50px"}}
                                    >
                                        <span>Voltar para a Home</span>
                                    </CustomLoadingButtonOutlined>
                                </div>
                                
                            </>
                        }

                        {(parseInt(codeHttp) === 403 || codeHttpInline === 403) && 
                            <>
                                <div className='box_error_tit'>
                                    <WarningAmberOutlinedIcon fontSize="large" color="neutral"/>
                                    <h1 className='error404'>Opssss!</h1>
                                </div>

                                <span className='errorcode'><b>403</b> | Não foi concedido permissão para você acessar esse recurso!</span>
                                
                                <div className='box_btn_error'>
                                    <CustomLoadingButtonOutlined
                                        onClick={() => redirectTo(history, '/')}
                                        startIcon={<ArrowBackIosNewOutlinedIcon />}
                                        variant="outlined"
                                        fullWidth
                                        color='secondary'
                                        size="large"
                                        sx={{marginTop:"50px"}}
                                    >
                                        <span>Voltar para a Home</span>
                                    </CustomLoadingButtonOutlined>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>

            <SectionBeStudent/>
        </>
    )
}

export default ErrorScreen;