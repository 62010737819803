import { useEffect, useState, useRef } from 'react';
import '../style.css'
import { Link, useParams, useHistory } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Blog";
import api from "../../../services/api";
import SpinnerLoader from "../../../components/Loaders/SpinnerLoader";
import { Context } from '../../../context/AuthContext'
import React, { useContext } from 'react'
import { Avatar, Box, Fab } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CategoryOrSubcategoryLoader from '../../../components/Loaders/Divs/Blog/CategoryOrSubcategoryLoader';
import SectionBeStudent from '../../../components/Website/SectionBeStudent';
import { upperFirstLetter } from '../../../helpers';

function BlogSubCategory () {
    const { setMetaTagsOfPage, showAlert, alertInTopClosed } = useContext(Context)
    const {subCategorySlug} = useParams();
    const [articles, setArticles] = useState([])
    const [subcategory, setSubcategory] = useState({})
    const [isLastPage, setIsLastPage] = useState(true);
    const pageNumber = useRef(1);
    const [spinnerLoader, setSpinnerLoader] = useState(false)
    const [blogHomeSec3Loader, setBlogHomeSec3Loader] = useState(true)
    const history = useHistory()

    useEffect(() => {
        pageNumber.current = 1
        setArticles([])
        loadMoreArticles(true)

        window.scrollTo(0, 0)
    }, [subCategorySlug])

    async function loadMoreArticles(resetArticles) {
        setSpinnerLoader(true)

        await api.get(`blog/subcategoria/${subCategorySlug}`, {
            params:{
                page_number:pageNumber.current
            }
        }).then((response) => {
                if (response.data.status === 'success') {
                    if (resetArticles) {
                        setArticles(response.data.data.blog_articles)
                    } else {
                        let articlesMerged = articles.concat(response.data.data.blog_articles);
                        setArticles(articlesMerged)
                    }

                    if (pageNumber.current === 1) {
                        setMetaTagsOfPage(`Artigos sobre ${response.data.data.subcategory.name}`, `${response.data.data.subcategory.description}`)
                        setSubcategory(response.data.data.subcategory)
                        setBlogHomeSec3Loader(false)
                    }
                    
                    pageNumber.current = pageNumber.current + 1;
                    setIsLastPage(response.data.data.last_page)

                    setSpinnerLoader(false)
                } else {
                    history.push("/erro/404");
                }
            }).catch((error) => {
                if (error.response.status === 404) {
                    history.push("/erro/404");
                }
            })
    }

    const Article = (props) => {
        return (
            <article>
                <Link to={`/${props.link_category}/${props.link_subcategory}/${props.link}`}>
                    <div className='article_bg_img' style={{backgroundImage: `url(${props.thumb})`}}></div>

                    <div className='thumb_box_article'>
                        {props.author.picture ?
                            <img src={props.author.picture} alt={props.author.name} title={props.author.name}/>
                        :
                            <Avatar title={props.author.name}>{upperFirstLetter(props.author.name)}</Avatar>
                        }
                    </div>
                </Link>

                <div className='article_content'>
                    <div className='thumb_title'>
                        <div className='thumb_title_box'>
                            <h1>
                                <Link to={`/${props.link_category}/${props.link_subcategory}/${props.link}`}>{props.title}</Link>
                            </h1>

                            <div className='title_box'>
                                <Link className='categlk' to={`/${props.link_category}/${props.link_subcategory}`} style={{background:props.subcat_bg_color}}>{props.subcategory}</Link>
                                <span className='pub_date'>{props.publication_date}</span>
                            </div>
                        </div>
                    </div>

                    <div className='description_article'>
                        <p dangerouslySetInnerHTML={{ __html: props.description}} />
                    </div>
                </div>
            </article>
        )
    }

    return (
        <>
            <div className={`box_blog ${(showAlert !== '' && !alertInTopClosed) && 'alert-active'}`}>

                <div className="blog_content">
                    <div className='blog_section_3'>
                        <div className='box_sect_articles'>
                            {blogHomeSec3Loader ?
                                <CategoryOrSubcategoryLoader/>
                            :
                                <>              
                                    <div className='box_title_subcateg'>
                                        <span className='titsubcat'>
                                            <Link to={`/${subcategory.category_slug}`}>{subcategory.category_name}</Link> > {subcategory.name}
                                        </span>
                                    </div>       

                                    {!articles.length ? 
                                        <p>Nenhum artigo encontrado!</p>
                                    :
                                        <>
                                            {articles.map((item, index) => {
                                                return <Article key={index} thumb={item.thumb} author={item.author} title={item.title} subcat_bg_color={item.subcat_bg_color} description={item.description} category={item.category} subcategory={item.subcategory} subcategory_description={item.subcategory_description} publication_date={item.publication_date} link={item.link} link_category={item.link_category} link_subcategory={item.link_subcategory} id={item.id}/>
                                            })}
                                        </>
                                    }
                                    

                                    {!spinnerLoader ?
                                        !isLastPage &&
                                            <Box display="flex" justifyContent="center" alignItems="center" marginBottom="40px">
                                                <Fab variant="extended" color="primary" size="medium" onClick={() => loadMoreArticles()}>
                                                    <MoreHorizIcon sx={{ mr: 1 }} />
                                                    Carregar Mais
                                                </Fab>
                                            </Box> 
                                    :
                                        <SpinnerLoader borderColorImp='var(--plat-seven)'/>
                                    }
                                </>
                            }
                        </div>
                        
                        <Sidebar />
                    </div>
                </div>
            </div>

            <SectionBeStudent/>
        </>
    )
}

export default BlogSubCategory;