import React, { useEffect, useState, useRef, useContext } from 'react'
import { useHistory } from "react-router-dom";
import Form from '../../../components/Form'
import '../styleForm.css'
import TitleFormBox from '../../../components/Platform/TitleBox/TitleFormBox'
import api from '../../../services/api'
import { toast } from 'react-toastify'
import TableHistoric from '../../../components/Platform/Table/TableHistoric';
import FormLoader from '../../../components/Loaders/Divs/Platform/FormLoader';
import HistoricLoader from '../../../components/Loaders/Divs/Platform/HistoricLoader';
import { Context } from '../../../context/AuthContext';
import { Checkbox, FormControl, FormHelperText, Grid, InputAdornment, MenuItem } from "@mui/material";
import Box from '@mui/material/Box';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import {TabPanel, a11yProps} from '../../../components/TabPanel';
import { CustomTab, CustomTabs } from '../../../components/MaterialCustom/Laboratory/Tabs/CustomTabs';
import SpinnerLoader from '../../../components/Loaders/SpinnerLoader';
import { CustomTextField, inputLabelStyles } from '../../../components/MaterialCustom/Laboratory/TextField/CustomTextField';

function PlansForm({regId}) {  
    const history = useHistory()
    const [loaderTab, setLoaderTab] = useState(false)
    const [loaderTab2, setLoaderTab2] = useState(false)
    const [loaderTab3, setLoaderTab3] = useState(false)
    const { handleLogout, loading, setLoading } = useContext(Context)

    const [formPlanos, setFormPlanos] = useState({
        name: '',
        price: '',
        // icon: '',
        status: '',
        stripe_prod: '',
        stripe_price: ''
    })

    const [fieldsError, setFieldsError] = useState({
        name: '',
        price: '',
        // icon: '',
        status: '',
        stripe_prod: '',
        stripe_price: ''
    })

    const [planItens, setPlanItens] = useState([])
    const [tableHistrcCell, setTableHistrcCell] = useState([])

    const [statusOptions, setStatusOptions] = useState([
        {id: 1, name: 'Ativo'},
        {id: 2, name: 'Inativo'}
    ])

    const title = useRef({
        name: 'Planos',
        description_insert: 'Insira novos planos no sistema',
        description_update: 'Altere planos do sistema',
        link_from: '/admin/planos'
    })

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (regId) {
            setLoaderTab(true)
            setLoaderTab2(true)
            setLoaderTab3(true)
            
            api.get(`admin/planos/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab(false)
                        setFormPlanos(response.data.data.plan)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            api.get(`admin/plano-itens/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab3(false)
                        setPlanItens(response.data.data.plan_itens)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            api.get(`admin/planos/historico/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
        } else {
            setLoaderTab2(true)
            setLoaderTab3(true)

            api.get(`admin/plano-itens`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab3(false)
                        setPlanItens(response.data.data.plan_itens)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            api.get('admin/planos/historico')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
        }
    }, [])

    function setName(e) {
        setFormPlanos({...formPlanos, name: e.target.value})
    }

    function setPrice(e) {
        setFormPlanos({...formPlanos, price: e.target.value})
    }

    // function setIcon(e) {
    //     setFormPlanos({...formPlanos, icon: e.target.value})
    // }

    function setStatus(e) {
        setFormPlanos({...formPlanos, status: e.target.value})
    }

    function setStripeProd(e) {
        setFormPlanos({...formPlanos, stripe_prod: e.target.value})
    }

    function setStripePrice(e) {
        setFormPlanos({...formPlanos, stripe_price: e.target.value})
    }

    function setPlanItem(e) {
        let checkItemValue = parseInt(e.target.value)
    
        let newPlanItemState = planItens.map((planItem) => {
            if (planItem.id === checkItemValue) {
                planItem.enable = !planItem.enable
            }

            return planItem;
        })

        setPlanItens(newPlanItemState)
    }

    async function handleSave(e) {
        e.preventDefault()
        setLoading(true)

        let formData = new FormData();
        formData.append('name', formPlanos.name)
        formData.append('price', formPlanos.price)
        // formData.append('icon', formPlanos.icon)
        formData.append('status', formPlanos.status)
        formData.append('stripe_prod', formPlanos.stripe_prod)
        formData.append('stripe_price', formPlanos.stripe_price)
        formData.append('plan_itens', JSON.stringify(planItens))

        await api.post('admin/planos', formData)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    setFormPlanos({name: '', price: '', /*icon: '',*/ status: '', stripe_prod: '', stripe_price: ''})

                    history.push("/admin/planos")
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({name: '', price: '', /*icon: '',*/ status: '', stripe_prod: '', stripe_price: ''})
                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    async function handleUpdate(e) {
        e.preventDefault()
        setLoading(true)

        let formData = new FormData();
        formData.append('name', formPlanos.name)
        formData.append('price', formPlanos.price)
        // formData.append('icon', formPlanos.icon)
        formData.append('status', formPlanos.status)
        formData.append('stripe_prod', formPlanos.stripe_prod)
        formData.append('stripe_price', formPlanos.stripe_price)
        formData.append('plan_itens', JSON.stringify(planItens))

        await api.post(`admin/planos/${regId}`, formData)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    history.push("/admin/planos")
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({name: '', price: '', /*icon: '',*/ status: '', stripe_prod: '', stripe_price: ''})
                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    const CheckItem = ({item}) => {
        return (
            <div className='box_checkbox'>
                <Checkbox
                    // {...label}
                    defaultChecked
                    name='plitem'
                    value={item.id}
                    onChange={(e) => setPlanItem(e)}
                    checked={parseInt(item.enable ? item.id : '') === parseInt(item.id)}
                    color="fifthcolor" 
                    sx={{
                        // color: 'red',
                        '&.Mui-checked': {
                            color: 'var(--plat-twelve)',
                        },
                    }}
                />

                <span>{item.description}</span>
            </div>
        )
    }

    return (
        <>
            <TitleFormBox handleSave={handleSave} handleUpdate={handleUpdate} regId={regId} title={title.current}/>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <CustomTabs 
                                value={value} 
                                onChange={handleChange} 
                                variant="scrollable"
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                                sx={{
                                    '& .Mui-selected': { color: 'var(--plat-twelve) !important' }
                                }}
                            >
                                <CustomTab icon={<DatasetOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Cadastro" {...a11yProps(0)} />
                                <CustomTab icon={<CategoryOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Itens do Plano" {...a11yProps(1)} />
                                <CustomTab icon={<UpdateOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Histórico" {...a11yProps(2)} />
                            </CustomTabs>
                        </Box>

                        <TabPanel value={value} index={0}>
                            <>
                                {!loaderTab ?
                                    <div className='admin_box_form'>
                                        <Form>
                                            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Nome"
                                                            onChange={setName}
                                                            value={formPlanos.name} 
                                                            name='name'
                                                            autoComplete='off'
                                                            {...(fieldsError.name && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 100 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.name}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            label="Status"
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            {...(fieldsError.status && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="status"
                                                            onChange={setStatus} 
                                                            value={formPlanos.status} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {statusOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.status}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Preço (igual ao stripe_price, ex: 2990 = R$ 29,90)"
                                                            onChange={setPrice}
                                                            value={formPlanos.price} 
                                                            name='price'
                                                            {...(regId && {disabled: 'true'})}
                                                            // type='number'
                                                            {...(fieldsError.price && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 200 }}
                                                            // InputProps={{ startAdornment: <InputAdornment position="start">R$</InputAdornment> }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.price}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Stripe Prod ID"
                                                            onChange={setStripeProd}
                                                            value={formPlanos.stripe_prod} 
                                                            name='stripe_prod'
                                                            {...(regId && {disabled: 'true'})}
                                                            autoComplete='off'
                                                            {...(fieldsError.stripe_prod && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 200 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.stripe_prod}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Stripe Price ID"
                                                            onChange={setStripePrice}
                                                            value={formPlanos.stripe_price} 
                                                            name='stripe_price'
                                                            {...(regId && {disabled: 'true'})}
                                                            autoComplete='off'
                                                            {...(fieldsError.stripe_price && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 200 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.stripe_price}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <TextField
                                                            required
                                                            label="Ícone"
                                                            onChange={setIcon}
                                                            value={formPlanos.icon} 
                                                            name='icon'
                                                            {...(fieldsError.icon && {error: 'true'})}
                                                            size="small"
                                                            fullWidth
                                                            inputProps={{ maxLength: 200 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.icon}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid> */}
                                            </Grid>
                                        </Form>
                                    </div>
                                :
                                    <FormLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <> 
                                {!loaderTab3 ?
                                    <div className='admin_box_list'>
                                        <Form>
                                            {planItens.map((planItem) => {
                                                return <CheckItem key={planItem.id} item={planItem} />
                                            })}
                                        </Form>
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <>
                                {!loaderTab2 ?
                                    <div className='admin_box_historic'>
                                        <div className='admin_con_table'>
                                            <TableHistoric tableHistrcCell={tableHistrcCell} />
                                        </div>
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>

            {loading &&
                <div className='modal_box modal_open'>
                    <SpinnerLoader borderColorImp="var(--plat-seven)" />
                </div>
            }
        </>
    )
}

export default PlansForm;