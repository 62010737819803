import { CustomTextField, inputLabelStyles } from '../../../../MaterialCustom/Laboratory/TextField/CustomTextField';
import { CircularProgress, FormControl, FormHelperText, Grid, MenuItem } from '@mui/material';
import {Elements} from '@stripe/react-stripe-js';
import CheckoutSubscriptionForm from '../../../CheckoutSubscriptionForm'
import api from '../../../../../services/api';
import { toast } from 'react-toastify';
import { useState } from 'react';
import './style.css'
import '../../SinglePayment/StepSinglePayment/style.css'
import Form from '../../../../Form';
import { CustomLoadingButtonOutlined } from '../../../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButtonOutlined';
import DiscountIcon from '@mui/icons-material/Discount';
import { CustomTextFieldCheckoutForm } from '../../../../MaterialCustom/Laboratory/TextField/CustomTextFieldCheckoutForm';
import { appearance } from '../../../SubComponents/CheckoutFormOptionsAndStyled';

function StepFirstPayment ({clientSecret, stripePromise, plans, planSelected, setPlanSelected, nextStep, userId, stepNow = null, stepTotals = null}) {
    const [isSetPlanLoading, setIsSetPlanLoading] = useState(false)
    const [spinnerBtnLoader, setSpinnerBtnLoader] = useState(false)
    const [changePlan, setChangePlan] = useState(false)

    const options = {
        clientSecret,
        appearance
    };

    async function onChangePlan(newPlan) {
        setIsSetPlanLoading(true)

        await api.post('assinatura/change-user-plan', {user_id: userId, plan_id: newPlan.id})
            .then((response) => {
                if (response.data.status === 'success') {
                    setPlanSelected(newPlan)
                    toast.success(response.data.message)
                } else {
                    toast.error(response.data.message)
                }

                setChangePlan(!changePlan)
                setIsSetPlanLoading(false)
            }).catch((error) => {
                toast.error(error.response.data.message)
                setIsSetPlanLoading(false)
            })
    }

    function sendDiscountCoupon(e) {
        e.preventDefault()
        setSpinnerBtnLoader(true)

        // api.post('newsletter-subscribe', formNewsLetSub)
        //     .then((response) => {
        //         if (response.data.status === 'success') {
        //             toast.success(response.data.message)
        //             setFormNewsLetSub({email_nwl: ''});
        //         } else {
        //             toast.error(response.data.message)
        //         }

        //         setSpinnerBtnLoader(false)
        //         setFieldsError({email_nwl: ''})
        //     }).catch((error) => {
        //         if (error.response.status === 422) {
        //             setFieldsError(error.response.data.data)
        //             toast.error(error.response.data.message)
        //         }
                
        //         setSpinnerBtnLoader(false)
        //     })
    }

    return (
        <div className='payment_box_small_msg'>
            <div className='payment_box_message'>
                <span className='step_payment'>PASSO {stepNow} de {stepTotals}</span>

                <h1>Informe os dados do seu cartão!</h1>
                <p>Confira o resumo antes de iniciar a assinatura.</p>

                <div className="website_payment_new">
                    <div className="website_pay_container_new">
                       <div className='payment_content_new'>
                            <div className='box_all_checkout'>
                                {clientSecret ?
                                    <div className='box_checkout_father'>
                                        <div className='box_checkout_card_payment'>
                                            {clientSecret && stripePromise && (
                                                <Elements options={options} stripe={stripePromise}>
                                                    <CheckoutSubscriptionForm 
                                                        clientSecret={clientSecret} 
                                                        nextStep={nextStep}
                                                        // planSelectedId={planSelected?.id} 
                                                        // userId={userId} 
                                                    />
                                                </Elements>
                                            )}
                                        </div>

                                        <div className='box_checkout_shop_summary'>
                                            <div className='summ_head'>
                                                <h2>Resumo</h2>
                                            </div>

                                            <div className='summ_content'>
                                                {changePlan &&
                                                    <FormControl variant="standard" fullWidth sx={{marginBottom: '30px'}}>
                                                        <CustomTextFieldCheckoutForm
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Plano Selecionado"
                                                            size="medium"
                                                            fullWidth
                                                            name="plan_id"
                                                            {...(isSetPlanLoading && {disabled: 'true'})}
                                                            onChange={(e) => onChangePlan(e.target.value)} 
                                                            value={planSelected} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {plans.map((plan) => (
                                                                <MenuItem 
                                                                    key={plan.id} 
                                                                    value={plan}
                                                                    sx={{
                                                                        "&:hover": {
                                                                            backgroundColor: "var(--plat-four)",
                                                                        }
                                                                    }}
                                                                >
                                                                    {plan.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextFieldCheckoutForm>
                                                    </FormControl>
                                                }

                                                <div className='summ_data_pay'>
                                                    <div className='data_pay_line'>
                                                        <p>Plano {planSelected.name} <span>(Acesso válido por 1 ano)</span>< br/>{!changePlan && <button className='btn_changeplan' onClick={() => setChangePlan(!changePlan)}>Alterar Plano</button>}</p>
                                                        <span>R$ {planSelected.price_pretty}</span>
                                                    </div>

                                                    <div className='data_pay_line'>
                                                        <p>Descontos</p>
                                                        <span>-{/* R$ 0,00 */}</span>
                                                    </div>

                                                    <div className='data_pay_line'>
                                                        <p className="price_main">Total</p>
                                                        <span className="price_main">R$ {planSelected.price_pretty}</span>
                                                    </div>
                                                </div>

                                                <div className='discount_coupon'>
                                                    <Form>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <FormControl variant="standard" fullWidth>
                                                                    <CustomTextField
                                                                        label="Cupom de desconto"
                                                                        name='email_nwl'
                                                                        disabled
                                                                        // {...(fieldsError.email_nwl && {error: 'true'})}
                                                                        // onChange={setEmailNwl} 
                                                                        // value={formNewsLetSub.email_nwl}
                                                                        size="medium"
                                                                        autoComplete='off'
                                                                        fullWidth
                                                                        inputProps={{ 
                                                                            maxLength: 200
                                                                        }}
                                                                        InputLabelProps={{
                                                                            sx: {
                                                                            ...inputLabelStyles,
                                                                            },
                                                                        }}
                                                                    />

                                                                    <FormHelperText>
                                                                        {/* {fieldsError.email_nwl} */}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <CustomLoadingButtonOutlined
                                                                    onClick={sendDiscountCoupon}
                                                                    endIcon={<DiscountIcon />}
                                                                    loading={spinnerBtnLoader}
                                                                    disabled
                                                                    loadingPosition="end"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="large"
                                                                    color="secondary"
                                                                >
                                                                    <span>Aplicar Cupom</span>
                                                                </CustomLoadingButtonOutlined>
                                                            </Grid>
                                                        </Grid>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <div className='box_plan_loader'>
                                        <CircularProgress sx={{color: "var(--plat-seven)"}} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default StepFirstPayment;