import '../style.css'
import './style.css'
import { useHistory } from "react-router-dom";
import React from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Grid } from '@mui/material'
import { CustomLoadingButton } from '../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { redirectToWithParams } from '../../../helpers';

function ModalUserBlocked({modalStatus, setModalStatus, messageType, course}) {
    const history = useHistory()

    function handleCancel(e) {
        e.preventDefault()
        setModalStatus(false)
    }

    return (
        <div className={`modal_box ${modalStatus && 'modal_open'}`}>

            <div className='modal_big mdl_blk'>
                <div className="modal_content">
                    <div className='modal_header mdl_blk'>
                        <div className='modtitbox'>
                            <LockOutlinedIcon color='neutral' sx={{marginRight:"12px"}} fontSize="large" />
                            <h3 className='mdl_blk'>Acesso Bloqueado</h3>
                        </div>

                        <CloseOutlinedIcon onClick={(e) => handleCancel(e)} color='neutral' sx={{cursor:"pointer"}}/>
                    </div>

                    <div className="modal_content_middle">
                        {/* Aluno é do plano gratuito, ele nao pode emitir certificado */}
                        {messageType === 1 &&
                            <p className='mdl_blk'>Você não possui permissão para emitir o certificado.</p>
                        }

                        {/* Aluno é do plano gratuito, ele nao pode responder os desafios */}
                        {messageType === 2 &&
                            <p className='mdl_blk'>Você não possui permissão para responder os desafios.</p>
                        }
                        
                    </div>

                    <div className="modal_footer">
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CustomLoadingButton
                                    onClick={() => redirectToWithParams(history, '/recurso-bloqueado', `?curso=${course.slug}`)}
                                    variant="outlined"
                                    size="large"
                                    color="secondary"
                                    fullWidth
                                >
                                    <span>Saiba Mais</span>
                                </CustomLoadingButton>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>

        </div> 
    )
}

export default ModalUserBlocked;