import styled from "styled-components";
import { TextField } from '@mui/material'

const CustomTextFieldCheckoutForm = styled(TextField)({
    backgroundColor: "#222222",
    borderRadius: "4px",
    "& .MuiOutlinedInput-notchedOutline": {
        //border: "none",
        borderColor: "#333333"
    },
    "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
            //border: "none",
            borderColor: "#333333",
            border: '1px solid #333'
        }
    },
    '& label.Mui-focused': {
        color: 'var(--first-color)',
    },
    '& label.Mui-disabled': {
        color: 'var(--plat-seven)'
    },
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "var(--plat-seven)",
        background: "var(--plat-one)"
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
        backgroundColor: "var(--plat-three)",
    },
    "& .MuiOutlinedInput-root": {
        color: '#FAFAFA',
    },
    '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333333',
    },
});

export { CustomTextFieldCheckoutForm }