import React, { useEffect, useState, useRef, useContext } from 'react'
import { useHistory } from "react-router-dom";
import Form from '../../../components/Form'
import '../styleForm.css'
import TitleFormBox from '../../../components/Platform/TitleBox/TitleFormBox'
import api from '../../../services/api'
import { toast } from 'react-toastify'
import TableHistoric from '../../../components/Platform/Table/TableHistoric';
import { Editor } from '@tinymce/tinymce-react';
import FormLoader from '../../../components/Loaders/Divs/Platform/FormLoader';
import HistoricLoader from '../../../components/Loaders/Divs/Platform/HistoricLoader';
import { Context } from '../../../context/AuthContext';
import { FormControl, FormHelperText, Grid, /*InputAdornment,*/ MenuItem } from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Box from '@mui/material/Box';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ViewModuleOutlinedIcon from '@mui/icons-material/ViewModuleOutlined';
import {TabPanel, a11yProps} from '../../../components/TabPanel';
import InputFile from '../../../components/Form/InputFile';
import InputFileImage from '../../../components/Form/InputFile';
import { CustomTab, CustomTabs } from '../../../components/MaterialCustom/Laboratory/Tabs/CustomTabs';
import SpinnerLoader from '../../../components/Loaders/SpinnerLoader';
import { CustomTextField, inputLabelStyles } from '../../../components/MaterialCustom/Laboratory/TextField/CustomTextField';

function CursosForm({regId, dark = true}) {  
    const history = useHistory()
    const [loaderTab, setLoaderTab] = useState(false)
    const [loaderTab2, setLoaderTab2] = useState(false)
    const [loaderTab3, setLoaderTab3] = useState(false)
    const [loaderTab4, setLoaderTab4] = useState(false)
    const { handleLogout, loading, setLoading } = useContext(Context)
    const editorRef = useRef(null);

    const [formCourseFile, setFormCourseFile] = useState({thumb:''})
    const [formCourse, setFormCourse] = useState({
        name: '',
        description: '',
        status: '',
        level: '',
        subcategory_id: '',
        publication_date: '',
        teacher_user_id: '',
        is_free: '',
        intro_video: '',
        full_description: '',
        summary: '',
        thumb: '',
        link_of_code: '',
        price: '',
        stripe_price: '',
        stripe_prod: ''
    })

    const [modules, setModules] = useState([])

    const orderDefaultValue = useRef(1);
    const modulesPendingAdd = useRef({
        order: null, 
        description: null
    });

    const [fieldsError, setFieldsError] = useState({
        name: '',
        description: '',
        status: '',
        level: '',
        subcategory_id: '',
        publication_date: '',
        teacher_user_id: '',
        is_free: '',
        intro_video: '',
        full_description: '',
        summary: '',
        thumb: '',
        link_of_code: '',
        price: '',
        stripe_price: '',
        stripe_prod: ''
    })

    const [statusOptions, setStatusOptions] = useState([
        {id: 1, name: 'Ativo'},
        {id: 2, name: 'Inativo'}
    ])

    const [isFreeOptions, setIsFreeOptions] = useState([
        {id: 1, name: 'Gratuito'},
        {id: 2, name: 'Pago'}
    ])

    const [levelOptions, setLevelOptions] = useState([
        {id: 1, name: 'Iniciante'},
        {id: 2, name: 'Intermediário'},
        {id: 3, name: 'Avançado'}
    ])

    const initialFormPublicFullDescription = useRef('')
    const [tableHistrcCell, setTableHistrcCell] = useState([])
    const [subcategorysOptions, setSubcategorysOptions] = useState([])
    const [teachersOptions, setTeachersOptions] = useState([])

    const thumbValidations = useRef({
        required: {message:'Informe um thumb!'},
    })

    const title = useRef({
        name: 'Cursos',
        description_insert: 'Insira novos cursos na plataforma',
        description_update: 'Altere cursos da plataforma',
        link_from: '/admin/cursos'
    })

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (regId) {
            setLoaderTab(true)
            setLoaderTab2(true)
            setLoaderTab3(true)
            setLoaderTab4(true)

            api.get(`admin/cursos/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab(false)
                        setLoaderTab3(false)
                        setLoaderTab4(false)
                        setFormCourse(response.data.data.course)
                        setModules(response.data.data.modules)
                        initialFormPublicFullDescription.current = response.data.data.course.full_description
                        orderDefaultValue.current = (response.data.data.modules.length + 1)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca histórico de inserções/remoções
            api.get(`admin/cursos/historico/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })

            //Busca subcategorias do blog para por no select>options
            api.get(`admin/cursos/subcategorias/${regId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setSubcategorysOptions(response.data.data.subcategorys)
                        setTeachersOptions(response.data.data.teachers)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
                
        } else {
            setLoaderTab2(true)

            //Busca histórico de alterações
            api.get('admin/cursos/historico')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setLoaderTab2(false)
                        setTableHistrcCell(response.data.data.historic)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
            
            //Busca subcategorias do blog para por no select>options
            api.get('admin/cursos/subcategorias')
                .then((response) => {
                    if (response.data.status === 'success') {
                        setSubcategorysOptions(response.data.data.subcategorys)
                        setTeachersOptions(response.data.data.teachers)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
        }
    }, [])

    function setName(e) {
        setFormCourse({...formCourse, name: e.target.value})
    }

    function setDescription(e) {
        setFormCourse({...formCourse, description: e.target.value})
    }

    function setSummary(e) {
        setFormCourse({...formCourse, summary: e.target.value})
    }

    function setFullDescription(e) {
        initialFormPublicFullDescription.current = e.target.getContent()
        // setFormCourse({...formCourse, full_description: e.target.getContent()})
    }

    function setStatus(e) {
        setFormCourse({...formCourse, status: e.target.value})
    }

    function setIsFree(e) {
        setFormCourse({...formCourse, is_free: e.target.value})
    }

    function setLevel(e) {
        setFormCourse({...formCourse, level: e.target.value})
    }

    function setSubcategoryId(e) {
        setFormCourse({...formCourse, subcategory_id: e.target.value})
    }

    function setPublicationDate(e) {
        setFormCourse({...formCourse, publication_date: e.target.value})
    }

    function setTeacherUserId(e) {
        setFormCourse({...formCourse, teacher_user_id: e.target.value})
    }

    function setIntroVideo(e) {
        setFormCourse({...formCourse, intro_video: e.target.value})
    }

    function setLinkOfCode(e) {
        setFormCourse({...formCourse, link_of_code: e.target.value})
    }

    function setPrice(e) {
        setFormCourse({...formCourse, price: e.target.value})
    }

    function setStripePrice(e) {
        setFormCourse({...formCourse, stripe_price: e.target.value})
    }

    function setStripeProd(e) {
        setFormCourse({...formCourse, stripe_prod: e.target.value})
    }

    function setThumb(e) {
        if (e.target.files.length === 0) {
            return;
        }

        let file = e.target.files[0]

        let reader = new FileReader()
        reader.readAsDataURL(file)
 
        reader.onload = () => {
            setFormCourse({...formCourse, thumb: reader.result})
            setFormCourseFile({thumb: file})
        }
    }

    async function handleSave(e) {
        e.preventDefault()

        if (!initialFormPublicFullDescription.current || initialFormPublicFullDescription.current === '') {
            toast.error('Informe a descrição completa do curso!')
        } else if (initialFormPublicFullDescription.current.length < 30) {
            toast.error('A descrição completa deve ter no mínimo 30 caracteres!')
        } else if (initialFormPublicFullDescription.current.length > 15000) {
            toast.error('A descrição completa deve ter no máximo 15000 caracteres!')
        } else {
            setLoading(true)

            // let mountDataSave = {...formCourse, ...{modules:modules}}
            let formData = new FormData();
            formData.append('name', formCourse.name)
            formData.append('description', formCourse.description)
            formData.append('status', formCourse.status)
            formData.append('level', formCourse.level)
            formData.append('subcategory_id', formCourse.subcategory_id)
            formData.append('publication_date', formCourse.publication_date)
            formData.append('teacher_user_id', formCourse.teacher_user_id)
            formData.append('is_free', formCourse.is_free)
            formData.append('intro_video', formCourse.intro_video)
            formData.append('link_of_code', formCourse.link_of_code)
            formData.append('price', formCourse.price)
            formData.append('stripe_price', formCourse.stripe_price)
            formData.append('stripe_prod', formCourse.stripe_prod)
            // formData.append('full_description', formCourse.full_description)
            formData.append('full_description', initialFormPublicFullDescription.current)
            formData.append('summary', formCourse.summary)
            // formData.append('modules', modules)
            formData.append('modules', JSON.stringify(modules))
            formData.append('thumb', formCourseFile.thumb)

            // await api.post('admin/cursos', mountDataSave)
            await api.post('admin/cursos', formData)
                .then((response) => {
                    if (response.data.status === 'success') {
                        toast.success(response.data.message)
                        setFormCourse({name: '', description: '', status: '', level: '', subcategory_id: '', publication_date: '', teacher_user_id: '', is_free: '', intro_video: '', full_description: '', summary: '', thumb: '', link_of_code: '', price: '', stripe_price: '', stripe_prod: ''})
                        setFormCourseFile({thumb:''})

                        history.push("/admin/cursos")
                    } else {
                        toast.error(response.data.message)
                    }

                    setFieldsError({name: '', description: '', status: '', level: '', subcategory_id: '', publication_date: '', teacher_user_id: '', is_free: '', intro_video: '', full_description: '', summary: '', thumb: '', link_of_code: '', price: '', stripe_price: '', stripe_prod: ''})
                    setLoading(false)
                }).catch((error) => {
                    setLoading(false)

                    if (error.response.status === 422) {
                        setFieldsError(error.response.data.data)
                        toast.error(error.response.data.message)
                    } else if (error.response.status === 401) {
                        handleLogout()
                    } else {
                        toast.error(error.response.data.message)
                    }
                })
        }
    }

    async function handleUpdate(e) {
        e.preventDefault()
        setLoading(true)

        // let mountDataSave = {...formCourse, ...{modules:modules}}
        let formData = new FormData();

        if (formCourseFile?.thumb) {
            formData.append('thumb', formCourseFile.thumb)
        }

        formData.append('name', formCourse.name)
        formData.append('description', formCourse.description)
        formData.append('status', formCourse.status)
        formData.append('level', formCourse.level)
        formData.append('subcategory_id', formCourse.subcategory_id)
        formData.append('publication_date', formCourse.publication_date)
        formData.append('teacher_user_id', formCourse.teacher_user_id)
        formData.append('is_free', formCourse.is_free)
        formData.append('intro_video', formCourse.intro_video)
        formData.append('link_of_code', formCourse.link_of_code)
        formData.append('price', formCourse.price)
        formData.append('stripe_price', formCourse.stripe_price)
        formData.append('stripe_prod', formCourse.stripe_prod)
        // formData.append('full_description', formCourse.full_description)
        formData.append('full_description', initialFormPublicFullDescription.current)
        formData.append('summary', formCourse.summary)
        formData.append('modules', JSON.stringify(modules))

        await api.post(`admin/cursos/${regId}`, formData)
        // await api.post(`admin/cursos/${regId}`, mountDataSave)
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    history.push("/admin/cursos")
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({name: '', description: '', status: '', level: '', subcategory_id: '', publication_date: '', teacher_user_id: '', is_free: '', intro_video: '', full_description: '', summary: '', thumb: '', link_of_code: '', price: '', stripe_price: '', stripe_prod: ''})
                setLoading(false)
            }).catch((error) => {
                setLoading(false)

                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                } else {
                    toast.error(error.response.data.message)
                }
            })
    }

    function addRowOnList() {
        // setModules([...modules, {order: 1, description: 'teste'}])
        if (!modulesPendingAdd.current.description) {
            toast.warning('Informe a descrição do módulo!')
            return
        }

        setModules([...modules, modulesPendingAdd.current])
        orderDefaultValue.current += 1
        modulesPendingAdd.current = {}
    }

    function rmvRowOnList(order) {
        orderDefaultValue.current -= 1

        //Remove o modulo em questão
        let newModules = modules.filter((module) => order !== module.order)

        //Refaz ordenação/numeração dos modulos 
        let reorder = 1;
        newModules = newModules.map((module) => {
            module.order = reorder
            reorder++

            return module
        })

        setModules(newModules)
    }

    function setModulesPendingAdd(description, status) {
        modulesPendingAdd.current = {
            order: orderDefaultValue.current,
            description: description,
            status: status
        }
    } 

    function setModulesDescription(order, descriptionVal) {
        let newModules = modules.map((module) => {
            if (module.order === order) {
                module.description = descriptionVal
            }

            return module
        })

        setModules(newModules)
    }

    function setModulesStatus(order, statusVal) {
        let newModules = modules.map((module) => {
            if (module.order === order) {
                module.status = statusVal
            }

            return module
        })

        setModules(newModules)
    }

    const ListSmartItem = ({isAddRow}) => {
        return (
            <div className="list_smart list_add">
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={3} lg={2} xl={1}>
                            <FormControl variant="standard" fullWidth>
                                <CustomTextField
                                    required
                                    label="Numeração"

                                    //List ADD
                                    // {...(isAddRow && {onChange: (e) => setModulesPendingAdd(orderDefaultValue.current, modulesPendingAdd.current.description)})}
                                    {...(isAddRow && {value: orderDefaultValue.current})}

                                    disabled
                                    name={`module_order`}
                                    // {...(fieldsError.name && {error: 'true'})}
                                    size="medium"
                                    fullWidth
                                    inputProps={{ type: 'number', maxLength: 11 }}

                                    InputLabelProps={{
                                        sx: {
                                        ...inputLabelStyles,
                                        },
                                    }}
                                />

                                <FormHelperText>
                                    {/* {fieldsError.name} */}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
                            <FormControl variant="standard" fullWidth>
                                <CustomTextField
                                    required
                                    label="Descrição"

                                    //List ADD
                                    {...(isAddRow && {onChange: (e) => setModulesPendingAdd(e.target.value, modulesPendingAdd.current.status)})}
                                    {...(isAddRow && {value: modulesPendingAdd.current.description})}

                                    name={`module_description`}
                                    // {...(fieldsError.name && {error: 'true'})}
                                    size="medium"
                                    fullWidth
                                    inputProps={{ maxLength: 150 }}

                                    InputLabelProps={{
                                        sx: {
                                        ...inputLabelStyles,
                                        },
                                    }}
                                />

                                <FormHelperText>
                                    {/* {fieldsError.name} */}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
                            <FormControl variant="standard" fullWidth>
                                <CustomTextField
                                    required
                                    select
                                    label="Status"
                                    // {...(fieldsError.status && {error: 'true'})}
                                    size="medium"
                                    fullWidth
                                    name={`module_status`}

                                    //List ADD
                                    {...(isAddRow && {onChange: (e) => setModulesPendingAdd(modulesPendingAdd.current.description, e.target.value)})}
                                    {...(isAddRow && {value: modulesPendingAdd.current.status})}

                                    InputLabelProps={{
                                        sx: {
                                        ...inputLabelStyles,
                                        },
                                    }}
                                >
                                    {statusOptions.map((stat) => (
                                        <MenuItem key={stat.id} value={stat.id}>
                                            {stat.name}
                                        </MenuItem>
                                    ))}
                                </CustomTextField>

                                <FormHelperText>
                                    {/* {fieldsError.status} */}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Form>

                <div className='box_icons_smart'>
                    <AddOutlinedIcon titleAccess='Adicionar' onClick={addRowOnList} color='fifthcolor' fontSize='large'/>
                </div>
            </div>
        )
    }

    return (
        <>
            <TitleFormBox handleSave={handleSave} handleUpdate={handleUpdate} regId={regId} title={title.current}/>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <CustomTabs 
                                value={value} 
                                onChange={handleChange} 
                                variant="scrollable"
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                                sx={{
                                    '& .Mui-selected': { color: 'var(--plat-twelve) !important' }
                                }}
                            >
                                <CustomTab icon={<DatasetOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Cadastro" {...a11yProps(0)} />
                                <CustomTab icon={<DescriptionOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Descrição" {...a11yProps(1)}  />
                                <CustomTab icon={<ViewModuleOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Módulos" {...a11yProps(2)}  />
                                <CustomTab icon={<UpdateOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Histórico" {...a11yProps(3)} />
                            </CustomTabs>
                        </Box>

                        <TabPanel value={value} index={0}>
                            <>
                                {!loaderTab ?
                                    <div className='admin_box_form'>
                                        <Form>
                                            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Nome"
                                                            onChange={setName}
                                                            value={formCourse.name} 
                                                            name='name'
                                                            autoComplete='off'
                                                            {...(fieldsError.name && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 150 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.name}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Categoria/Subcategoria"
                                                            {...(fieldsError.subcategory_id && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="subcategory_id"
                                                            onChange={setSubcategoryId} 
                                                            value={formCourse.subcategory_id} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {subcategorysOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.subcategory_id}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Descrição resumida"
                                                            onChange={setDescription}
                                                            value={formCourse.description} 
                                                            name='description'
                                                            autoComplete='off'
                                                            {...(fieldsError.description && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 80 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.description}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Sumário"
                                                            onChange={setSummary}
                                                            value={formCourse.summary} 
                                                            name='summary'
                                                            autoComplete='off'
                                                            {...(fieldsError.summary && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 300 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.summary}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Status"
                                                            {...(fieldsError.status && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="status"
                                                            onChange={setStatus} 
                                                            value={formCourse.status} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {statusOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.status}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Professor(a)"
                                                            {...(fieldsError.teacher_user_id && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="teacher_user_id"
                                                            onChange={setTeacherUserId} 
                                                            value={formCourse.teacher_user_id} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {teachersOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.teacher_user_id}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Level"
                                                            {...(fieldsError.level && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="level"
                                                            onChange={setLevel} 
                                                            value={formCourse.level} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {levelOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.level}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            select
                                                            sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                            label="Gratuito/Pago"
                                                            {...(fieldsError.is_free && {error: 'true'})}
                                                            size="medium"
                                                            fullWidth
                                                            name="is_free"
                                                            onChange={setIsFree} 
                                                            value={formCourse.is_free} 
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            inputProps={{
                                                                MenuProps: {
                                                                    MenuListProps: {
                                                                        sx: {
                                                                            backgroundColor: 'var(--plat-two)',
                                                                            color: 'var(--plat-seven)'
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {isFreeOptions.map((stat) => (
                                                                <MenuItem key={stat.id} value={stat.id} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                    {stat.name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomTextField>

                                                        <FormHelperText>
                                                            {fieldsError.is_free}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            label="Data de lançamento"
                                                            type="datetime-local"
                                                            required
                                                            size="medium"
                                                            name="publication_date"
                                                            // defaultValue="2017-05-24"
                                                            {...(fieldsError.publication_date && {error: 'true'})}
                                                            onChange={setPublicationDate}
                                                            value={formCourse.publication_date}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.publication_date}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Preço (igual ao stripe_price, ex: 2990 = R$ 29,90)"
                                                            onChange={setPrice}
                                                            value={formCourse.price} 
                                                            {...(regId && {disabled: 'true'})}
                                                            name='price'
                                                            // type='number'
                                                            {...(fieldsError.price && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 200 }}
                                                            // InputProps={{ startAdornment: <InputAdornment position="start">R$</InputAdornment> }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.price}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Stripe Prod ID"
                                                            onChange={setStripeProd}
                                                            value={formCourse.stripe_prod} 
                                                            name='stripe_prod'
                                                            {...(regId && {disabled: 'true'})}
                                                            autoComplete='off'
                                                            {...(fieldsError.stripe_prod && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 200 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.stripe_prod}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Stripe Price ID"
                                                            onChange={setStripePrice}
                                                            value={formCourse.stripe_price} 
                                                            name='stripe_price'
                                                            {...(regId && {disabled: 'true'})}
                                                            autoComplete='off'
                                                            {...(fieldsError.stripe_price && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 200 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.stripe_price}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            required
                                                            label="Link do vídeo de apresentação"
                                                            onChange={setIntroVideo}
                                                            value={formCourse.intro_video} 
                                                            name='intro_video'
                                                            {...(!regId && {helperText: "Exemplo: https://player.vimeo.com/video/509774252"})}
                                                            autoComplete='off'
                                                            {...(fieldsError.intro_video && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            FormHelperTextProps={{
                                                                sx: {
                                                                    color: 'var(--plat-twelve)'
                                                                },
                                                              }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 600 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.intro_video}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                    <FormControl variant="standard" fullWidth>
                                                        <CustomTextField
                                                            label="Link do código fonte (GitHub)"
                                                            onChange={setLinkOfCode}
                                                            value={formCourse.link_of_code} 
                                                            name='link_of_code'
                                                            {...(!regId && {helperText: "Exemplo: https://github.com/facquesta5/mvc.git"})}
                                                            autoComplete='off'
                                                            {...(fieldsError.link_of_code && {error: 'true'})}
                                                            InputLabelProps={{
                                                                sx: {
                                                                ...inputLabelStyles,
                                                                },
                                                            }}
                                                            FormHelperTextProps={{
                                                                sx: {
                                                                    color: 'var(--plat-twelve)'
                                                                },
                                                            }}
                                                            size="medium"
                                                            fullWidth
                                                            inputProps={{ maxLength: 600 }}
                                                        />

                                                        <FormHelperText>
                                                            {fieldsError.link_of_code}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                
                                                {regId &&
                                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <CustomTextField
                                                                label="Avaliação do curso"
                                                                value={`${formCourse.avg_evaluation} de 5`}
                                                                disabled
                                                                name='evaluation'
                                                                // {...(fieldsError.intro_video && {error: 'true'})}
                                                                size="medium"
                                                                fullWidth
                                                                // inputProps={{ maxLength: 600 }}
                                                                InputLabelProps={{
                                                                    sx: {
                                                                    ...inputLabelStyles,
                                                                    },
                                                                }}
                                                            />

                                                            <FormHelperText>
                                                                {/* {fieldsError.intro_video} */}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                            </Grid>

                                            <Grid container spacing={2} sx={{marginBottom:"50px"}}>
                                                <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
                                                    <FormControl variant="standard" fullWidth>
                                                        {(!regId || !formCourse.thumb) ?
                                                            <InputFile 
                                                                name='thumb' 
                                                                description='Thumb' 
                                                                onChange={setThumb} 
                                                                value={formCourse.thumb} 
                                                                validations={thumbValidations.current}
                                                            />
                                                        :
                                                            <InputFileImage 
                                                                value={formCourse.thumb} 
                                                                name='thumb' 
                                                                description='Thumb' 
                                                                onChange={setThumb}
                                                            />
                                                        }

                                                        <FormHelperText>
                                                            {fieldsError.thumb}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    </div>
                                :
                                    <FormLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <>
                                {!loaderTab4 ?
                                    <div className='editor_rich_text'>
                                        <span className="inpdescpt">Descrição Completa</span>

                                        {/* No help do editor de texto tem a documentação com todos os plugins gratuitos, nao apliquei todos que existem, apenas os principais (https://www.tiny.cloud/docs/tinymce/6/image/) */}
                                        <Editor
                                            apiKey='zzj6wp4jzuygxnabbci5mqkzqwwce1ju40x7kzle01w00rxc'
                                            name='full_description'
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            initialValue={initialFormPublicFullDescription.current}
                                            onChange={(e) => setFullDescription(e)} 
                                            init={{
                                                // skin: "oxide-dark",
                                                // content_css: "dark",
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'autolink', 'link', 'preview', 'anchor', 'searchreplace',
                                                    'visualblocks', 'fullscreen', 'help', 'wordcount', 'code'
                                                ],
                                                toolbar: 'undo redo | link | blocks | ' +
                                                    'bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | ' +
                                                    'removeformat | code | help',
                                                a11y_advanced_options: true,
                                                content_style: 
                                                    'body { font-family:AlbertSans, Roboto, Arial; font-size:18px } p{margin-bottom: 20px; font-size: 18px; color: rgba(0, 0, 0, 0.75);} h1, h2, h3, h4, h5, h6{margin-bottom: 30px; margin-top: 60px;} p a{margin-bottom: 10px !important; color: rgba(4, 170, 96, 1); text-decoration: underline;} ul{margin-left: 35px;} ul li{margin: 10px 0px;font-size: 18px;} img{height: auto;} table.tbl_imgs{background-color: rgba(18,18,18,.05); border: 1px solid #2A2A2A;} img.sizexs_1{width: 20%;} img.sizexs_2{ width: 40%; } img.sizexs_3{ width: 60%; } img.sizexs_4{ width: 80%; } img.sizexs_5{ width: 100%; } blockquote{padding: 10px 20px; border-left: 5px solid #EEEEEE;} blockquote p{margin-bottom: 0 !important;} pre[class*="language-"]{padding: 25px; border-left: 5px solid rgba(1, 254, 135, 1);} :not(pre) > code[class*="language-"], pre[class*="language-"]{background: rgba(39, 41, 62, 1);} code[class*="language-"], pre[class*="language-"] {color: #f8f8f2;} @media (min-width: 600px) {img.sizesm_1{ width: 20%; } img.sizesm_2{ width: 40%; } img.sizesm_3{ width: 60%; } img.sizesm_4{ width: 80%; } img.sizesm_5{ width: 100%; }} @media (min-width: 900px) {img.sizemd_1{ width: 20%; } img.sizemd_2{ width: 40%; } img.sizemd_3{ width: 60%; } img.sizemd_4{ width: 80%; } img.sizemd_5{ width: 100%; }} @media (min-width: 1200px) {img.sizelg_1{ width: 20%; } img.sizelg_2{ width: 40%; } img.sizelg_3{ width: 60%; } img.sizelg_4{ width: 80%; } img.sizelg_5{ width: 100%; }} @media (min-width: 1536px) {img.sizexl_1{ width: 20%; } img.sizexl_2{ width: 40%; } img.sizexl_3{ width: 60%; } img.sizexl_4{ width: 80%; } img.sizexl_5{ width: 100%; }}'
                                                // cloudChannel='dev' 
                                            }}
                                        />
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <>
                                {!loaderTab3 ?
                                    <> 
                                        {/* RESULT ITEM */}
                                        {modules.map((module, index) => {
                                            return (
                                                <div className="list_smart" key={index}>
                                                    <Form>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={3} md={3} lg={2} xl={1}>
                                                                <FormControl variant="standard" fullWidth>
                                                                    <CustomTextField
                                                                        required
                                                                        label="Numeração"
                                    
                                                                        //List RESULT
                                                                        onChange={setModulesPendingAdd}
                                                                        value={module.order}
                                    
                                                                        disabled
                                                                        name={`module_order_${module.order}`}
                                                                        // {...(fieldsError.name && {error: 'true'})}
                                                                        size="medium"
                                                                        fullWidth
                                                                        inputProps={{ type: 'number', maxLength: 11 }}
                                                                        InputLabelProps={{
                                                                            sx: {
                                                                            ...inputLabelStyles,
                                                                            },
                                                                        }}
                                                                    />
                                    
                                                                    <FormHelperText>
                                                                        {/* {fieldsError.name} */}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                    
                                                            <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
                                                                <FormControl variant="standard" fullWidth>
                                                                    <CustomTextField
                                                                        required
                                                                        label="Descrição"
                                    
                                                                        //List RESULT
                                                                        onChange={(e) => setModulesDescription(module.order, e.target.value)}
                                                                        value={module.description}
                                    
                                                                        name={`module_description_${module.order}`}
                                                                        // {...(fieldsError.name && {error: 'true'})}
                                                                        size="medium"
                                                                        fullWidth
                                                                        inputProps={{ maxLength: 150 }}
                                                                        InputLabelProps={{
                                                                            sx: {
                                                                            ...inputLabelStyles,
                                                                            },
                                                                        }}
                                                                    />
                                    
                                                                    <FormHelperText>
                                                                        {/* {fieldsError.name} */}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
                                                                <FormControl variant="standard" fullWidth>
                                                                    <CustomTextField
                                                                        required
                                                                        select
                                                                        sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                                        label="Status"
                                                                        // {...(fieldsError.status && {error: 'true'})}
                                                                        size="medium"
                                                                        fullWidth
                                                                        name={`module_status_${module.order}`}

                                                                        //List RESULT
                                                                        onChange={(e) => setModulesStatus(module.order, e.target.value)}
                                                                        value={module.status}
                                                                        // onChange={setStatus} 
                                                                        // value={formPerfis.status} 

                                                                        InputLabelProps={{
                                                                            sx: {
                                                                            ...inputLabelStyles,
                                                                            },
                                                                        }}
                                                                    >
                                                                        {statusOptions.map((stat) => (
                                                                            <MenuItem key={stat.id} value={stat.id}>
                                                                                {stat.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </CustomTextField>

                                                                    <FormHelperText>
                                                                        {/* {fieldsError.status} */}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Form>
                                    
                                                    <div className='box_icons_smart'>
                                                        <DeleteOutlinedIcon fontSize='large' titleAccess='Remover' onClick={() => rmvRowOnList(module.order)} color='neutral'/>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        {/* ADD ITEM */}
                                        <ListSmartItem isAddRow={true}/>
                                    </>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>

                        <TabPanel value={value} index={3}>
                            <>
                                {!loaderTab2 ?
                                    <div className='admin_box_historic'>
                                        <div className='admin_con_table'>
                                            <TableHistoric tableHistrcCell={tableHistrcCell} />
                                        </div>
                                    </div>
                                :
                                    <HistoricLoader/>
                                }
                            </>
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>

            {loading &&
                <div className='modal_box modal_open'>
                    <SpinnerLoader borderColorImp={dark === true ? "var(--plat-seven)" : ""} />
                </div>
            }
        </>
    )
}

export default CursosForm;