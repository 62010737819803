// import './style.css'
import { useHistory, useParams } from "react-router-dom";
import api from "../../../services/api";
import { Context } from '../../../context/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import BannerWebsite from '../../../components/Banner/Website';
import { Grid } from '@mui/material';
// import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
// import CourseItem from '../../../components/Website/CourseItem';
// import {TabPanel, a11yProps} from '../../../components/TabPanel';
// import HomeLoader from '../../../components/Loaders/Divs/Website/HomeLoader';
// import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
// import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
// import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
// import { CustomTab, CustomTabs } from '../../../components/MaterialCustom/Laboratory/Tabs/CustomTabs';
// import SectionBeStudent from '../../../components/Website/SectionBeStudent'
import { redirectTo } from "../../../helpers";

function CourseLPType1 () {
    const {courseSlug, categorySlug, subCategorySlug} = useParams()
    const [course, setCourse] = useState({})

    const { setMetaTagsOfPage } = useContext(Context)
    // const [homeLoader, setHomeLoader] = useState(true)
    // const [frontEndCourses, setFrontEndCourses] = useState([])
    // const [backEndCourses, setBackEndCourses] = useState([])
    // const [devOpsCourses, setDevOpsCourses]  = useState([])
    const history = useHistory()

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // useEffect(() => {
    //     setMetaTagsOfPage('Escola online para programadores', 'Uma plataforma de ensino feita para programadores!')
    //     loadHome()

    //     window.scrollTo(0, 0)
    // }, [])

    useEffect(() => {
        loadCourse()

        window.scrollTo(0, 0)
    }, [courseSlug])

    async function loadCourse() {
        // setSpinnerLoader(true)

        await api.get(`curso/lp/${courseSlug}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    setMetaTagsOfPage(`Curso de ${response.data.data.course.subcategory} - ${response.data.data.course.name}`, 'Escola online para programadores!')
                    setCourse(response.data.data.course)

                    // setSpinnerLoader(false)
                } else {
                    history.push("/erro/404");
                }
            }).catch((error) => {
                if (error.response.status === 404) {
                    history.push("/erro/404");
                }
            })
    }

    // async function loadHome() {
    //     await api.get('website')
    //         .then((response) => {
    //             if (response.data.status === 'success') {
    //                 setFrontEndCourses(response.data.data.frontend_courses)
    //                 setBackEndCourses(response.data.data.backend_courses)
    //                 setDevOpsCourses(response.data.data.devops_courses)
                    
    //                 setHomeLoader(false)
    //             }
    //         }).catch((error) => {
    //             // if (error.response.status === 401) {
    //             //     handleLogout()
    //             // }
    //         })
    // }

    return (
        <>
            <BannerWebsite/>

            <div className="website_section box_website">
                <Grid container spacing={2} className="wb_sec_grid_center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h1 className='main_title_ws'>Curso de JS Básico - CHATBOT (MODELO)</h1>
                        <p>Sendo aluno você tem acesso ao nosso blá bla bla bla bla bla bla bla bla bla bla bla bla bla (MODELO). </p>
                        
                        <LoadingButton
                            onClick={() => redirectTo(history, `/curso/${categorySlug}/${subCategorySlug}/${courseSlug}/checkout`)}
                            variant="outlined"
                            color="fifthcolor"
                        >
                            <span>Comprar curso</span>
                        </LoadingButton>
                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <CustomTabs 
                                    value={value} 
                                    onChange={handleChange} 
                                    variant="scrollable"
                                    allowScrollButtonsMobile
                                    aria-label="scrollable force tabs example"
                                    sx={{
                                        '& .Mui-selected': { color: 'var(--plat-twelve) !important' }
                                    }}
                                >
                                    <CustomTab icon={<ViewQuiltOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Front-End" {...a11yProps(0)} />
                                    <CustomTab icon={<IntegrationInstructionsOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="Back-End" {...a11yProps(1)} />
                                    <CustomTab icon={<SettingsSuggestOutlinedIcon color='fifthcolor'/>} iconPosition="start" label="DevOps" {...a11yProps(2)} />
                                </CustomTabs>
                            </Box>

                            <TabPanel value={value} index={0}>
                                <div className="tabs_home">
                                    <div>
                                        <h2>Cursos de Front-End</h2>
                                        <p className="alg_lft">Confira alguns cursos de front-end presentes no nosso laboratório.</p>
                                    </div>
                                    
                                    <LoadingButton
                                        onClick={() => redirectTo(history, '/cursos/front-end')}
                                        variant="outlined"
                                        color="fifthcolor"
                                        size="medium"
                                    >
                                        <span>Ver Todos os Cursos de Front-End</span>
                                    </LoadingButton>
                                </div>

                                <Grid container spacing={3} alignItems="stretch">
                                    {homeLoader ?
                                        <HomeLoader />
                                    :
                                        <>
                                            {frontEndCourses.map((course, index) => {
                                                return (
                                                    <Grid container item xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                                                        <CourseItem name={course.name} isFree={course.is_free} evaluation={course.avg_evaluation} level={course.level} durationTotal={course.duration_total} quantityOfClasses={course.number_of_classes}  slugCategory={course.link_category} slugSubCategory={course.link_subcategory} slugCourse={course.slug} category={course.category} subcategory={course.subcategory} description={course.description} teacher={course.author} subcategoryBgColor={course.subcat_bg_color} publicationDate={course.publication_date} />
                                                    </Grid>
                                                )
                                            })}
                                        </>
                                    }
                                </Grid>
                            </TabPanel>

                            <TabPanel value={value} index={1}>
                                <div className="tabs_home">
                                    <div>
                                        <h2>Cursos de Back-End</h2>
                                        <p className='alg_lft'>Confira alguns cursos de back-end presentes no nosso laboratório.</p>
                                    </div>
                                    
                                    <LoadingButton
                                        onClick={() => redirectTo(history, '/cursos/back-end')}
                                        variant="outlined"
                                        color="fifthcolor"
                                    >
                                        <span>Ver Todos os Cursos de Back-End</span>
                                    </LoadingButton>
                                </div>

                                <Grid container spacing={3} alignItems="stretch">
                                    {homeLoader ?
                                        <HomeLoader />
                                    :
                                        <>
                                            {backEndCourses.map((course, index) => {
                                                return (
                                                    <Grid container item xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                                                        <CourseItem name={course.name} isFree={course.is_free} evaluation={course.avg_evaluation} level={course.level} durationTotal={course.duration_total} quantityOfClasses={course.number_of_classes} slugCategory={course.link_category} slugSubCategory={course.link_subcategory} slugCourse={course.slug} category={course.category} subcategory={course.subcategory} description={course.description} teacher={course.author} subcategoryBgColor={course.subcat_bg_color} publicationDate={course.publication_date} />
                                                    </Grid>
                                                )
                                            })}
                                        </>
                                    }
                                </Grid>
                            </TabPanel>

                            <TabPanel value={value} index={2}>
                                <div className="tabs_home">
                                    <div>
                                        <h2>Cursos de DevOps</h2>
                                        <p className='alg_lft'>Confira alguns cursos de devops presentes no nosso laboratório.</p>
                                    </div>
                                    
                                    <LoadingButton
                                        onClick={() => redirectTo(history, '/cursos/devops')}
                                        variant="outlined"
                                        color="fifthcolor"
                                    >
                                        <span>Ver Todos os Cursos de DevOps</span>
                                    </LoadingButton>
                                </div>

                                <Grid container spacing={3} alignItems="stretch">
                                    {homeLoader ?
                                        <HomeLoader />
                                    :
                                        <>
                                            {devOpsCourses.map((course, index) => {
                                                return (
                                                    <Grid container item xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                                                        <CourseItem name={course.name} isFree={course.is_free} evaluation={course.avg_evaluation} level={course.level} durationTotal={course.duration_total} quantityOfClasses={course.number_of_classes} slugCategory={course.link_category} slugSubCategory={course.link_subcategory} slugCourse={course.slug} category={course.category} subcategory={course.subcategory} description={course.description} teacher={course.author} subcategoryBgColor={course.subcat_bg_color} publicationDate={course.publication_date} />
                                                    </Grid>
                                                )
                                            })}
                                        </>
                                    }
                                </Grid>
                            </TabPanel>
                        </Box>
                    </Grid> */}
                </Grid>
            </div>

            {/* <SectionBeStudent /> */}
        </>
    )
}

export default CourseLPType1;