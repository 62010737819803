import { prettyUserName } from '../../../../../helpers';
import { CustomLoadingButton } from '../../../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';

function StepAlertSuccessfullyPaid ({user, typeMsg = null, course = null}) {
    return (
        <div className='payment_box_big_msg'>
            <div className='payment_box_message'>
                {typeMsg === 'buy' ?
                    <>
                        {course?.is_free === 1 ?
                            <h1>Parabéns {user?.name ? prettyUserName(user.name) : user.name}, seu presente foi liberado!</h1>
                        :
                            <h1>Parabéns {user?.name ? prettyUserName(user.name) : user.name}, sua compra foi concluída com sucesso!</h1>
                        }
                        
                        <p>Acesse a área do aluno e aproveite seu curso agora mesmo.</p>
                    </>
                :
                    <>
                        <h1>Parabéns {user?.name ? prettyUserName(user.name) : user.name}, sua assinatura foi {typeMsg === 'reactivate' ? 'reativada' : 'iniciada'}!</h1>
                        <p>Acesse a área do aluno e aproveite todos os cursos disponíveis no seu plano agora mesmo.</p>
                    </>
                }

                <CustomLoadingButton
                    onClick={() => {window.location.href = `${process.env.REACT_APP_URL_BASE_LABORATORY}`}}
                    variant="contained"
                    fullWidth
                    color='secondary'
                    size="large"
                >
                    <span>Acessar área do aluno</span>
                </CustomLoadingButton>
            </div>
        </div>
    )
}

export default StepAlertSuccessfullyPaid;