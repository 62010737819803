import { CircularProgress, FormControl, FormHelperText, Grid, MenuItem } from '@mui/material';
import { useContext, useState } from 'react';
import './style.css'
import '../../SinglePayment/StepSinglePayment/style.css'
import api from '../../../../../services/api';
import { CustomTextField, inputLabelStyles } from '../../../../MaterialCustom/Laboratory/TextField/CustomTextField';
import { CustomLoadingButton } from '../../../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';
import { Context } from '../../../../../context/AuthContext';
import { toast } from 'react-toastify';
import { CustomTextFieldCheckoutForm } from '../../../../MaterialCustom/Laboratory/TextField/CustomTextFieldCheckoutForm';
import Form from '../../../../Form';
import { CustomLoadingButtonOutlined } from '../../../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButtonOutlined';
import DiscountIcon from '@mui/icons-material/Discount';

function StepReactivateDefaultPayment ({userCards, setUserCards, plans = null, nextStep, changeDefaultPayStep, planSelected = null, setPlanSelected = null, stepNow = null, stepTotals = null, typeCheckout='reactivate-subscription', course = null, setupIntentId = null}) {
    const [stepLoader, setStepLoader] = useState(false)
    const [spinnerBtnLoader, setSpinnerBtnLoader] = useState(false)
    const [isSetPlanLoading, setIsSetPlanLoading] = useState(false)
    const { handleLogout } = useContext(Context)
    const [changePlan, setChangePlan] = useState(false)
    const [isChangingCard, setIsChangingCard] = useState(false)

    async function sendStep() {
        setStepLoader(true)

        if (typeCheckout === 'subscription' || typeCheckout === 'reactivate-subscription') {
            await restartSignatureWithDefaultPaymentMethod()
        } else if (typeCheckout === 'buy-course') {
            await buyWithDefaultPaymentMethod()
        }
    }

    async function buyWithDefaultPaymentMethod() {
        await api.post('purchase/costumer/user-buy-course-with-default-payment-method', {amount: course?.price, course_id: course?.id, seti: setupIntentId})
            .then((response) => {
                if (response.data.status === 'success') {
                    nextStep()
                }

                setStepLoader(false)
            }).catch((error) => {
                setStepLoader(false)

                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    async function restartSignatureWithDefaultPaymentMethod() {
        await api.post('assinatura/costumer/reactivate-user-plan-with-default-payment-method', {plan_id: planSelected?.id})
            .then((response) => {
                if (response.data.status === 'success') {
                    nextStep()
                }

                setStepLoader(false)
            }).catch((error) => {
                setStepLoader(false)

                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    async function onChangePlan(newPlan) {
        setIsSetPlanLoading(true)

        await api.post('assinatura/costumer/change-user-plan', {plan_id: newPlan.id})
            .then((response) => {
                if (response.data.status === 'success') {
                    setPlanSelected(newPlan)
                    toast.success(response.data.message)
                } else {
                    toast.error(response.data.message)
                }

                setChangePlan(!changePlan)
                setIsSetPlanLoading(false)
            }).catch((error) => {
                toast.error(error.response.data.message)
                setIsSetPlanLoading(false)

                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    async function changeDefaultPaymentMethod(paymentMethodId, pmIsDefaultCard) {
        //se tiver só um cartão eu desconsidero o clique, pois não tem como ele trocar a forma de pagamento (só tem um cartão)
        //se já for o cartão padrão eu tbem desconsidero o clique
        if (userCards?.length >= 2 && pmIsDefaultCard === false) {
            setIsChangingCard(true)

            await api.post('costumer/change-user-default-payment-method', {payment_method_id: paymentMethodId})
                .then((response) => {
                    if (response.data.status === 'success') {
                        setUserCards(response.data.data.payment_methods)
                        toast.success(response.data.message)
                    } else {
                        toast.error(response.data.message)
                    }

                    setIsChangingCard(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
        }
    }

    async function sendDiscountCoupon(e) {
        e.preventDefault()
        setSpinnerBtnLoader(true)

        // await api.post('newsletter-subscribe', formNewsLetSub)
        //     .then((response) => {
        //         if (response.data.status === 'success') {
        //             toast.success(response.data.message)
        //             setFormNewsLetSub({email_nwl: ''});
        //         } else {
        //             toast.error(response.data.message)
        //         }

        //         setSpinnerBtnLoader(false)
        //         setFieldsError({email_nwl: ''})
        //     }).catch((error) => {
        //         if (error.response.status === 422) {
        //             setFieldsError(error.response.data.data)
        //             toast.error(error.response.data.message)
        //         }
                
        //         setSpinnerBtnLoader(false)
        //     })
    }

    return (
        <div className='payment_box_small_msg'>
            <div className='payment_box_message'>
                <span className='step_payment'>PASSO {stepNow} de {stepTotals}</span>
                <h1>Revise as informações!</h1>

                {(typeCheckout === 'subscription' || typeCheckout === 'reactivate-subscription') &&
                    <p>Seu acesso será liberado após o pagamento. Lembrando que você pode alterar ou cancelar seu plano quando quiser.</p>
                }

                {typeCheckout === 'buy-course' &&
                    <>
                        {course?.is_free === 1 ?
                            <p>Seu acesso será liberado após concluir todos os passos. Lembrando que você pode solicitar reembolso 7 dias corridos após a compra.</p>
                        :
                            <p>Seu acesso será liberado após o pagamento. Lembrando que você pode solicitar reembolso 7 dias corridos após a compra.</p>
                        }
                    </>
                }

                <div className="website_payment_new">
                    <div className="website_pay_container_new">
                       <div className='payment_content_new'>
                            <div className='box_all_checkout'>
                                <div className='box_checkout_father'>
                                    <>
                                        <div className='box_checkout_default_card'>
                                            <div className='deft_head'>
                                                <h2>Escolha a forma de pagamento</h2>
                                            </div>

                                            <div className='deft_content'>
                                                {userCards.map((card, index) => {
                                                    return (
                                                        <div className={`card_populated ${card.is_default && 'card_selected'}`} key={index} onClick={() => changeDefaultPaymentMethod(card.pm_id, card.is_default)}>
                                                            {(isChangingCard && !card.is_default) ?
                                                                <div className='card_simulator_loader'>
                                                                    <CircularProgress sx={{width:"34px !important", height:"34px !important", color: "var(--plat-seven)"}} />
                                                                    <p style={{marginTop: '20px'}}>Alterando forma de pagamento padrão...</p>
                                                                </div>
                                                            :
                                                                <>
                                                                    <div className='card_popu_left'>
                                                                        <span className='card_default_descp'>{card.is_default ? 'Cartão Padrão' : 'Cartão'}</span>
                                                                        <h4><b>{card.brand}</b>, final {card.last_numbers}</h4>
                                                                        <p>{card.name}</p>
                                                                    </div>

                                                                    <div className='card_popu_right'>
                                                                        {card.is_default ?
                                                                            <span>SELECIONADO</span>
                                                                        :
                                                                            <span className='card_change_click'>DESABILITADO</span>
                                                                        }

                                                                        <div className='card_venc'>
                                                                            <h5>Validade</h5>
                                                                            <span className='dt_valid'>{card.exp_month} / {card.exp_year}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    )
                                                })}

                                                <div className='box_add_new_card'>
                                                    <button className={`card_pupulated_add_new_card ${isChangingCard && 'card_add_blocked'}`} onClick={() => changeDefaultPayStep()} disabled={isChangingCard}>Adicionar novo cartão</button>
                                                </div>

                                                <CustomLoadingButton
                                                    onClick={() => sendStep()}
                                                    loading={stepLoader}
                                                    variant="contained"
                                                    fullWidth
                                                    disabled={isChangingCard}
                                                    color='secondary'
                                                    size="large"
                                                >
                                                    {typeCheckout === 'subscription' &&
                                                        <span>Assinar agora</span>
                                                    }

                                                    {typeCheckout === 'reactivate-subscription' &&
                                                        <span>Reativar assinatura</span>
                                                    }

                                                    {typeCheckout === 'buy-course' &&
                                                        <span>Finalizar compra</span>
                                                    }
                                                </CustomLoadingButton>
                                            </div>
                                        </div>

                                        {typeCheckout === 'buy-course' &&
                                            <div className='box_checkout_shop_summary'>
                                                <div className='summ_head'>
                                                    <h2>Resumo</h2>
                                                </div>

                                                <div className='summ_content'>
                                                    <div className='summ_data_pay'>
                                                        <div className='data_pay_line'>
                                                            <p>Curso de {course.subcategory} ({course.name})< br/><span>Acesso válido por 1 ano</span></p>
                                                            <span>R$ {course.price_pretty}</span>
                                                        </div>

                                                        <div className='data_pay_line'>
                                                            <p>Descontos</p>
                                                            <span>-{/* R$ 0,00 */}</span>
                                                        </div>

                                                        <div className='data_pay_line'>
                                                            <p className="price_main">Total</p>
                                                            <span className="price_main">R$ {course.price_pretty}</span>
                                                        </div>
                                                    </div>

                                                    <div className='discount_coupon'>
                                                        <Form>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <FormControl variant="standard" fullWidth>
                                                                        <CustomTextField
                                                                            label="Cupom de desconto"
                                                                            name='email_nwl'
                                                                            disabled
                                                                            // {...(fieldsError.email_nwl && {error: 'true'})}
                                                                            // onChange={setEmailNwl} 
                                                                            // value={formNewsLetSub.email_nwl}
                                                                            size="medium"
                                                                            autoComplete='off'
                                                                            fullWidth
                                                                            inputProps={{ 
                                                                                maxLength: 200
                                                                            }}
                                                                            InputLabelProps={{
                                                                                sx: {
                                                                                ...inputLabelStyles,
                                                                                },
                                                                            }}
                                                                        />

                                                                        <FormHelperText>
                                                                            {/* {fieldsError.email_nwl} */}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <CustomLoadingButtonOutlined
                                                                        onClick={sendDiscountCoupon}
                                                                        endIcon={<DiscountIcon />}
                                                                        loading={spinnerBtnLoader}
                                                                        disabled
                                                                        loadingPosition="end"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        size="large"
                                                                        color="secondary"
                                                                    >
                                                                        <span>Aplicar Cupom</span>
                                                                    </CustomLoadingButtonOutlined>
                                                                </Grid>
                                                            </Grid>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {(typeCheckout === 'reactivate-subscription' || typeCheckout === 'subscription') &&
                                            <div className='box_checkout_shop_summary'>
                                                <div className='summ_head'>
                                                    <h2>Resumo</h2>
                                                </div>

                                                <div className='summ_content'>
                                                    {changePlan &&
                                                        <FormControl variant="standard" fullWidth sx={{marginBottom: '30px'}}>
                                                            <CustomTextFieldCheckoutForm
                                                                select
                                                                sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                                label="Plano Selecionado"
                                                                size="medium"
                                                                fullWidth
                                                                name="plan_id"
                                                                {...(isSetPlanLoading && {disabled: 'true'})}
                                                                onChange={(e) => onChangePlan(e.target.value)} 
                                                                value={planSelected} 
                                                                InputLabelProps={{
                                                                    sx: {
                                                                    ...inputLabelStyles,
                                                                    },
                                                                }}
                                                                inputProps={{
                                                                    MenuProps: {
                                                                        MenuListProps: {
                                                                            sx: {
                                                                                backgroundColor: 'var(--plat-two)',
                                                                                color: 'var(--plat-seven)'
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                {plans?.map((plan) => (
                                                                    <MenuItem 
                                                                        key={plan.id} 
                                                                        value={plan}
                                                                        sx={{
                                                                            "&:hover": {
                                                                                backgroundColor: "var(--plat-four)",
                                                                            }
                                                                        }}
                                                                    >
                                                                        {plan.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </CustomTextFieldCheckoutForm>
                                                        </FormControl>
                                                    }

                                                    <div className='summ_data_pay'>
                                                        <div className='data_pay_line'>
                                                            <p>Plano {planSelected?.name} <span>(Acesso válido por 1 ano)</span>< br/>{!changePlan && <button className='btn_changeplan' onClick={() => setChangePlan(!changePlan)}>Alterar Plano</button>}</p>
                                                            <span>R$ {planSelected?.price_pretty}</span>
                                                        </div>

                                                        <div className='data_pay_line'>
                                                            <p>Descontos</p>
                                                            <span>-{/* R$ 0,00 */}</span>
                                                        </div>

                                                        <div className='data_pay_line'>
                                                            <p className="price_main">Total</p>
                                                            <span className="price_main">R$ {planSelected?.price_pretty}</span>
                                                        </div>
                                                    </div>

                                                    <div className='discount_coupon'>
                                                        <Form>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <FormControl variant="standard" fullWidth>
                                                                        <CustomTextField
                                                                            label="Cupom de desconto"
                                                                            name='email_nwl'
                                                                            disabled
                                                                            // {...(fieldsError.email_nwl && {error: 'true'})}
                                                                            // onChange={setEmailNwl} 
                                                                            // value={formNewsLetSub.email_nwl}
                                                                            size="medium"
                                                                            autoComplete='off'
                                                                            fullWidth
                                                                            inputProps={{ 
                                                                                maxLength: 200
                                                                            }}
                                                                            InputLabelProps={{
                                                                                sx: {
                                                                                ...inputLabelStyles,
                                                                                },
                                                                            }}
                                                                        />

                                                                        <FormHelperText>
                                                                            {/* {fieldsError.email_nwl} */}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <CustomLoadingButtonOutlined
                                                                        onClick={sendDiscountCoupon}
                                                                        endIcon={<DiscountIcon />}
                                                                        loading={spinnerBtnLoader}
                                                                        disabled
                                                                        loadingPosition="end"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        size="large"
                                                                        color="secondary"
                                                                    >
                                                                        <span>Aplicar Cupom</span>
                                                                    </CustomLoadingButtonOutlined>
                                                                </Grid>
                                                            </Grid>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepReactivateDefaultPayment;