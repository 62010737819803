import '../../style.css'
import Logo from '../../../../../assets/imgs/logos/logoeduteka.png'
import { Context } from '../../../../../context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import api from '../../../../../services/api';
import { useHistory, useParams } from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';
import StepCreateUser from '../../../../../components/PaymentWithStripe/Steps/General/StepCreateUser'
import StepAlertPayment from '../../../../../components/PaymentWithStripe/Steps/General/StepAlertPayment';
import StepAlertSuccessfullyPaid from '../../../../../components/PaymentWithStripe/Steps/General/StepAlertSuccessfullyPaid';
import { LoadingButton } from '@mui/lab';
import ModalLogin from '../../../../../components/Modal/General/ModalLogin';
import StepReactivateDefaultPayment from '../../../../../components/PaymentWithStripe/Steps/General/StepReactivateDefaultPayment';
import StepReactivateChangeDefaultPayment from '../../../../../components/PaymentWithStripe/Steps/General/StepReactivateChangeDefaultPayment';
import StepVerifyHasActivePlanOrBuyThisCourse from '../../../../../components/PaymentWithStripe/Steps/General/StepVerifyHasActivePlanOrBuyThisCourse';
import StepSinglePayment from '../../../../../components/PaymentWithStripe/Steps/SinglePayment/StepSinglePayment';

function Pay() {
    const { authenticated, setMetaTagsOfPage, handleLogout } = useContext(Context)
    const history = useHistory()
    const [course, setCourse] = useState()
    const [currentStep, setCurrentStep] = useState(1)
    const [user, setUser] = useState('')
    const [modalLoginStatus, setModalLoginStatus] = useState(false)
    const [userCards, setUserCards] = useState()
    const {courseSlug} = useParams()

    //stripe
    const [stripePromise, setStripePromise] = useState(null)
    const [clientSecret, setClientSecret] = useState(null)

    //Stripe (Opcional)
    const [setupIntentId, setSetupIntentId] = useState(null)

    useEffect(() => {
        setMetaTagsOfPage('Complete a sua matrícula na Eduteka', 'Uma plataforma de ensino feita para designers e programadores!')

        //Stripe
        loadPublishableKey()
    }, [])

    useEffect(() => {
        // Checa se o usuário está logado
        if (authenticated) {
            getDataOfUserLogged()
        }
    }, [authenticated])

    useEffect(() => {
        //Só carrega os cursos novamente, se não existir nenhum curso no state (não tá sendo mandado como prop, talvez no futuro)
        // if (!course) {
            loadCourse()
        // }

        window.scrollTo(0, 0)
    }, [courseSlug])

    useEffect(() => {
        if (user?.id && course) {
            //Cria uma intenção de pagamento no servidor
            createPaymentIntent()
        }
    }, [user, course]);

    async function loadCourse() {
        await api.get(`curso/lp/${courseSlug}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    setCourse(response.data.data.course)
                } else {
                    history.push("/erro/404");
                }
            }).catch((error) => {
                if (error.response.status === 404) {
                    history.push("/erro/404");
                }
            })
    }
    
    function loadPublishableKey() {
        api.get(`/stripe/config`)
            .then((response) => {
                if (response.data.status === 'success') {
                    setStripePromise(loadStripe(response.data.data.publishable_key))
                } else {
                    history.push("/erro/404")
                }
            })
            .catch((error) => {
                // if (error.response.status === 401) {
                //     handleLogout()
                // }
            })
    }

    //Cria uma intenção de pagamento
    function createPaymentIntent() {
        api.post('purchase/create-payment-intent', {
            // payment_method_type: 'card',
            // currency: 'brl',
            user_id: user?.id,
            amount: course?.price,
            course_id: course?.id
        }).then((response) => {
            if (response.data.status === 'success') {
                setClientSecret(response.data.data.client_secret);
                setSetupIntentId(response.data.data.seti)
            }
        }).catch((error) => {
            // if (error.response.status === 401) {
            //     handleLogout()
            // }
        })
    }

    //Recupera os dados do usuário logado
    const getDataOfUserLogged = () => {
        let userRecovery = JSON.parse(localStorage?.getItem('user'));
        setUser({id: userRecovery.id, name: userRecovery.name})        
    }

    return (
        <>
            <div className='payment_header'>
                <div className='website_pay_header_container'>
                    {/* <Link to="/"> */}
                        <img className="logo" src={Logo} width={98} alt="Eduteka" title="Eduteka"/>
                    {/* </Link> */}
                    
                    {!authenticated ?
                        <>
                            {!user &&
                                <LoadingButton
                                    onClick={() => setModalLoginStatus(!modalLoginStatus)}
                                    // endIcon={<PsychologyAltOutlinedIcon />}
                                    // loading={spinnerLoaderBtn}
                                    // loadingPosition="end"
                                    variant="outlined"
                                    color="secondary"
                                >
                                    <span>Logar</span>
                                </LoadingButton>
                            }
                        </>
                    :
                        <LoadingButton
                            onClick={() => handleLogout(null, false)}
                            // endIcon={<PsychologyAltOutlinedIcon />}
                            // loading={spinnerLoaderBtn}
                            // loadingPosition="end"
                            variant="outlined"
                            color="secondary"
                        >
                            <span>Sair</span>
                        </LoadingButton>
                    }
                </div>
            </div>
    
            {!authenticated ?
                <>
                    {currentStep === 1 &&
                        <StepCreateUser 
                            setUser={setUser} 
                            stepNow={1} 
                            stepTotals={3} 
                            nextStep={() => setCurrentStep(2)} 
                        />
                    }

                    {currentStep === 2 &&
                        <StepAlertPayment 
                            stepNow={2} 
                            stepTotals={3} 
                            typeMsg='buy'
                            course={course}
                            user={user}
                            nextStep={() => setCurrentStep(3)} 
                        />
                    }

                    {currentStep === 3 &&
                        <StepSinglePayment
                            clientSecret={clientSecret}
                            stripePromise={stripePromise}
                            stepNow={3} 
                            stepTotals={3} 
                            course={course}
                            userId={user?.id}
                            nextStep={() => setCurrentStep(4)} 
                        />
                    }

                    {currentStep === 4 &&
                        <StepAlertSuccessfullyPaid
                            user={user}
                            typeMsg='buy'
                            course={course}
                        />
                    }
                </>
            : // Usuário logado na plataforma
                <>
                    {currentStep === 1 &&
                        <StepVerifyHasActivePlanOrBuyThisCourse 
                            stepNow={1} 
                            stepTotals={2} 
                            course={course}
                            checkType='course'
                            setUserCards={setUserCards}
                            user={user}
                            nextStep={() => setCurrentStep(2)} 
                        />
                    }

                    {currentStep === 2 &&
                        <>
                            {/* Usuário possui método de pagamento padrão salvo */}
                            {userCards ?
                                <StepReactivateDefaultPayment
                                    stepNow={2} 
                                    stepTotals={2} 
                                    userCards={userCards}
                                    setUserCards={setUserCards}
                                    nextStep={() => setCurrentStep(4)}
                                    changeDefaultPayStep={() => setCurrentStep(3)}
                                    typeCheckout='buy-course'
                                    course={course}
                                    setupIntentId={setupIntentId}
                                />
                            : // Usuário NÃO possui método de pagamento padrão salvo
                                <StepSinglePayment
                                    clientSecret={clientSecret}
                                    stripePromise={stripePromise}
                                    stepNow={2} 
                                    stepTotals={2} 
                                    course={course}
                                    userId={user?.id}
                                    nextStep={() => setCurrentStep(4)} 
                                />
                            }
                        </>
                    }

                    {currentStep === 3 &&
                        <StepReactivateChangeDefaultPayment 
                            backToBeforeStep={() => setCurrentStep(2)}
                            nextStep={() => setCurrentStep(4)}
                            clientSecret={clientSecret}
                            stripePromise={stripePromise}
                            typeCheckout='buy-course'
                            course={course}
                        />
                    }

                    {currentStep === 4 &&
                        <StepAlertSuccessfullyPaid 
                            user={user} 
                            typeMsg='buy'
                            course={course}
                        />
                    }
                </>
            }

            <ModalLogin modalStatus={modalLoginStatus} setModalStatus={setModalLoginStatus} />
        </>
    )
}

export default Pay;