import '../style.css'
import './style.css'
import React, { useContext } from 'react'
import { Context } from '../../../context/AuthContext'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import { Grid } from '@mui/material'
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { CustomLoadingButton } from '../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';

function ModalChangePlan({modalStatus, setModalStatus, planChanged}) {
    const { loading, setLoading, handleLogout } = useContext(Context)

    function handleCancel(e) {
        e.preventDefault()
        setModalStatus(false)
    }

    async function handleChangePlan() {
        setLoading(true)

        await api.post('assinatura/costumer/my-profile/change-user-plan', {plan_id: planChanged?.id})
            .then((response) => {
                if (response.data.status === 'success') {
                    toast.success(response.data.message)
                    setModalStatus(false)

                    let userUpdt = JSON.parse(localStorage.getItem('user'));
                    userUpdt.plan = response.data.data.plan_name
                    userUpdt.plan_downgrade = response.data.data.plan_downgrade

                    //Altero no localstorage a chave 'plan' do usuario, caso tenha sido alterado
                    localStorage.setItem('user', JSON.stringify(userUpdt))
                } else {
                    toast.error(response.data.message)
                }

                setLoading(false)
            }).catch((error) => {
                if (error.response.status === 422) {
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                }

                setLoading(false)
            })
    }

    return (
        <div className={`modal_box ${modalStatus && 'modal_open'}`}>

            <div className='modal modal_change_plan mdl_blk'>
                <div className="modal_content">
                    <div className='modal_header mdl_blk'>
                        <div className='modtitbox'>
                            <PasswordOutlinedIcon color='neutral' sx={{marginRight:"12px"}} fontSize="large" />
                            <h3 className='mdl_blk'>Alterar plano</h3>
                        </div>

                        <CloseOutlinedIcon onClick={(e) => handleCancel(e)} color='neutral' sx={{cursor:"pointer"}}/>
                    </div>

                    <div className="modal_content_middle">
                        <h6>Upgrade</h6>
                        <p className='mdl_blk'>Se você fizer <b>upgrade</b> do plano, a alteração entrará em vigor imediatamente, portanto, você poderá aproveitar os novos recursos do plano escolhido.</p>
                        <p className='mdl_blk'>Iremos realizar a cobrança apenas dos dias que restam para a próxima fatura, essa cobrança será feita com base no valor do plano escolhido e deduzindo o valor do seu antigo plano. Além disso, sua data de cobrança será atualizada.</p>
                        
                        <h6>Downgrade</h6>
                        <p className="mdl_blk">Se você fizer <b>downgrade</b> do plano, a alteração só entrará em vigor ao final do período. Até lá, você pode continuar aproveitando os recursos do plano mais caro.</p> 
                        {/* <p className="mdl_blk">Iremos descontar o valor dos dias não utilizados do antigo plano nas próximas faturas, após esse período as cobranças serão feitas normalmente.</p> */}
                    </div>

                    <div className="modal_footer">
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CustomLoadingButton
                                    onClick={() => handleChangePlan()}
                                    endIcon={<ScheduleSendOutlinedIcon />}
                                    loading={loading}
                                    loadingPosition="end"
                                    variant="contained"
                                    size="medium"
                                    fullWidth
                                    color="secondary"
                                >
                                    <span>Confirmar Alteração do plano</span>
                                </CustomLoadingButton>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>

        </div> 
    )
}

export default ModalChangePlan;