import { CustomTextField, inputLabelStyles } from '../../../../MaterialCustom/Laboratory/TextField/CustomTextField';
import { CircularProgress, FormControl, FormHelperText, Grid } from '@mui/material';
import {Elements} from '@stripe/react-stripe-js';
import { useState } from 'react';
import './style.css'
import Form from '../../../../Form';
import { CustomLoadingButtonOutlined } from '../../../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButtonOutlined';
import DiscountIcon from '@mui/icons-material/Discount';
import { appearance } from '../../../SubComponents/CheckoutFormOptionsAndStyled';
import CheckoutSinglePaymentForm from '../../../CheckoutSinglePaymentForm';

function StepSinglePayment ({clientSecret, stripePromise, nextStep, userId, stepNow = null, stepTotals = null, course}) {
    const [spinnerBtnLoader, setSpinnerBtnLoader] = useState(false)

    const options = {
        clientSecret,
        appearance
    };

    function sendDiscountCoupon(e) {
        e.preventDefault()
        setSpinnerBtnLoader(true)

        // api.post('newsletter-subscribe', formNewsLetSub)
        //     .then((response) => {
        //         if (response.data.status === 'success') {
        //             toast.success(response.data.message)
        //             setFormNewsLetSub({email_nwl: ''});
        //         } else {
        //             toast.error(response.data.message)
        //         }

        //         setSpinnerBtnLoader(false)
        //         setFieldsError({email_nwl: ''})
        //     }).catch((error) => {
        //         if (error.response.status === 422) {
        //             setFieldsError(error.response.data.data)
        //             toast.error(error.response.data.message)
        //         }
                
        //         setSpinnerBtnLoader(false)
        //     })
    }

    return (
        <div className='payment_box_small_msg'>
            <div className='payment_box_message'>
                <span className='step_payment'>PASSO {stepNow} de {stepTotals}</span>
               
                <h1>Informe os dados do seu cartão!</h1>
                
                {course.is_free === 1 ?
                    <p>Seu acesso será liberado após concluir todos os passos. Lembrando que você pode solicitar reembolso 7 dias corridos após a compra.</p>
                :
                    <p>Seu acesso será liberado após o pagamento. Lembrando que você pode solicitar reembolso 7 dias corridos após a compra.</p>
                }

                <div className="website_payment_new">
                    <div className="website_pay_container_new">
                       <div className='payment_content_new'>
                            <div className='box_all_checkout'>
                                {clientSecret ?
                                    <div className='box_checkout_father'>
                                        <div className='box_checkout_card_payment'>
                                            {clientSecret && stripePromise && (
                                                <Elements options={options} stripe={stripePromise}>

                                                    {/* 
                                                        Se o curso for gratuito o STRIPE não aceita utilizar o PAYMENT INTENT, somente o SETUP INTENT.
                                                        Por isso mandei um parâmetro adicional (courseIsFree) 
                                                    */}
                                                    <CheckoutSinglePaymentForm 
                                                        typeCheckout='buy-course'
                                                        courseIsFree={(course.is_free === 1 && course.price === 0) ? true : false} 
                                                        clientSecret={clientSecret} 
                                                        nextStep={nextStep} 
                                                        // userId={userId} 
                                                    />
                                                </Elements>
                                            )}
                                        </div>

                                        <div className='box_checkout_shop_summary'>
                                            <div className='summ_head'>
                                                <h2>Resumo</h2>
                                            </div>

                                            <div className='summ_content'>
                                                <div className='summ_data_pay'>
                                                    <div className='data_pay_line'>
                                                        <p>Curso de {course.subcategory} ({course.name})< br/><span>Acesso válido por 1 ano</span></p>
                                                        <span>R$ {course.price_pretty}</span>
                                                    </div>

                                                    <div className='data_pay_line'>
                                                        <p>Descontos</p>
                                                        <span>-{/* R$ 0,00 */}</span>
                                                    </div>

                                                    <div className='data_pay_line'>
                                                        <p className="price_main">Total</p>
                                                        <span className="price_main">R$ {course.price_pretty}</span>
                                                    </div>
                                                </div>

                                                <div className='discount_coupon'>
                                                    <Form>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <FormControl variant="standard" fullWidth>
                                                                    <CustomTextField
                                                                        label="Cupom de desconto"
                                                                        name='email_nwl'
                                                                        disabled
                                                                        // {...(fieldsError.email_nwl && {error: 'true'})}
                                                                        // onChange={setEmailNwl} 
                                                                        // value={formNewsLetSub.email_nwl}
                                                                        size="medium"
                                                                        autoComplete='off'
                                                                        fullWidth
                                                                        inputProps={{ 
                                                                            maxLength: 200
                                                                        }}
                                                                        InputLabelProps={{
                                                                            sx: {
                                                                            ...inputLabelStyles,
                                                                            },
                                                                        }}
                                                                    />

                                                                    <FormHelperText>
                                                                        {/* {fieldsError.email_nwl} */}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <CustomLoadingButtonOutlined
                                                                    onClick={sendDiscountCoupon}
                                                                    endIcon={<DiscountIcon />}
                                                                    loading={spinnerBtnLoader}
                                                                    disabled
                                                                    loadingPosition="end"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="large"
                                                                    color="secondary"
                                                                >
                                                                    <span>Aplicar Cupom</span>
                                                                </CustomLoadingButtonOutlined>
                                                            </Grid>
                                                        </Grid>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <div className='box_plan_loader'>
                                        <CircularProgress sx={{color: "var(--plat-seven)"}} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default StepSinglePayment;