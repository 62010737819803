import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useContext, useState } from "react";
import '../CheckoutSubscriptionForm/style.css'
import api from "../../../services/api";
import { toast } from "react-toastify";
import { FormControl, FormHelperText, Grid } from "@mui/material";
import { CustomLoadingButton } from "../../../components/MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton";
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import { Context } from "../../../context/AuthContext";
import { CustomTextFieldCheckoutForm } from "../../MaterialCustom/Laboratory/TextField/CustomTextFieldCheckoutForm";
import { paymentElementOptions } from "../SubComponents/CheckoutFormOptionsAndStyled";

export default function AddingPaymentMethodForm({clientSecret, modalStatus, setModalStatus, setCards }) {
  const { handleLogout } = useContext(Context)
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [messageError, setMessageError] = useState(null);
  // const [messageWarning, setMessageWarning] = useState(null);

  const [cardName, setCardName] = useState('')
  const [cardNameError, setCardNameError] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault();

    setMessageError('')
    // setMessageWarning('')

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    //Adiciona uma nova forma de pagamento do cliente (um novo cartão), mas não cobra nada
    const { error: stripeError, setupIntent } = await stripe.confirmSetup({ 
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/sucessed`,

        payment_method_data: {
          billing_details:{
            name: cardName
          }
        }
      },
      redirect: 'if_required', //remove o redirecionamento aplicado acima
    });

    if (stripeError) {
      //Exibe o erro do cartão para o usuário (ex: saldo insuficiente)
      setMessageError(stripeError.message);

      setIsLoading(false);
      return;
    } else if (setupIntent && setupIntent?.status === 'succeeded') {
      // setMessageWarning('Aguarde, adicionando novo cartão...')

      //Cria um novo método de pagamento
      await addPaymentMethodToUser(setupIntent.payment_method)
      
      // setIsLoading(false);
    } else {
      setMessageError('Erro inesperado!')

      setIsLoading(false);
      return;
    }
  };

  //Requisição de vinculação do novo método de pagamento com o usuário logado
  async function addPaymentMethodToUser(paymentMethod) {
    setIsLoading(true);

    let formData = new FormData();
    formData.append('payment_method', paymentMethod);

    await api.post('costumer/user-add-payment-method', formData)
      .then((response) => {
          if (response.data.status === 'success') {
            toast.success(response.data.message)
            setCards(response.data.data.payment_methods)
            setModalStatus(!modalStatus)

            //Limpa os campos do cartão, pois se não fica salvo
            // elements.getElement(PaymentElement).clear(); //tá dando erro aqui
            setCardName('')
            setCardNameError('')

            // setMessageWarning('')
          } else {
            toast.error(response.data.message)
          }

          setIsLoading(false);
      }).catch((error) => {
          toast.error(error.response.data.message)

          if (error.response.status === 401) {
              handleLogout()
          }

          setIsLoading(false);
      })
  }

  return (
    <form id="payment-form" className="payment-form" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl variant="standard" fullWidth sx={{marginBottom: '15px'}}>
              <label htmlFor="card-holder-name" style={{color: "var(--plat-six)", marginBottom:"0.25rem", fontSize:"0.93rem", lineHeight: '1.15'}}>Nome impresso no cartão</label>
              <CustomTextFieldCheckoutForm
                  // required
                  label=" "
                  id="card-holder-name" 
                  onChange={(e) => setCardName(e.target.value)}
                  value={cardName} 
                  autoComplete="off"
                  name='account_name'
                  {...(cardNameError.account_name && {error: 'true'})}
                  // InputLabelProps={{
                  //     sx: {
                  //     ...inputLabelStyles,
                  //     },
                  //     // shrink: true
                  // }}
                  size="medium"
                  fullWidth
                  inputProps={{ maxLength: 200, style: {textTransform: "uppercase"} }}
              />

              <FormHelperText>
                  {cardNameError.account_name}
              </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>

      <PaymentElement id="payment-element" options={paymentElementOptions}/>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{marginTop:"25px"}}>
          <CustomLoadingButton
              onClick={(e) => handleSubmit(e)}
              loading={isLoading}
              endIcon={<AddCardOutlinedIcon />}
              variant="contained"
              fullWidth
              disabled={isLoading || !stripe || !elements}
              color='secondary'
              size="large"
          >
              <span>Adicionar novo cartão</span>
          </CustomLoadingButton>
      </Grid>

      {/* Show any error or success messages */}
      {messageError && <div id="payment-message">{messageError}</div>}
      {/* {messageWarning && <div id="payment-message-warning">{messageWarning}</div>} */}
    </form>
  );
}