import { CircularProgress } from '@mui/material';
import {Elements} from '@stripe/react-stripe-js';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import CheckoutSubscriptionForm from '../../../CheckoutSubscriptionForm'
import './style.css'
import { appearance } from '../../../SubComponents/CheckoutFormOptionsAndStyled';
import CheckoutSinglePaymentForm from '../../../CheckoutSinglePaymentForm';

function StepReactivateChangeDefaultPayment ({clientSecret, stripePromise, /*planSelected,*/ nextStep, backToBeforeStep, typeCheckout = 'reactivate-subscription', course = null}) {
    const options = {
        clientSecret,
        appearance
    };

    return (
        <div className='payment_box_small_msg'>
            <div className='payment_box_message'>
                <div className='step_lk_back' onClick={() => backToBeforeStep()}>
                    <KeyboardBackspaceOutlinedIcon sx='color: var(--first-color)'/>
                    <span className='step_link'>Voltar na forma de pagamento padrão</span>
                </div>
                
                <h1>Preencha os dados do seu novo cartão</h1>

                {(typeCheckout === 'reactivate-subscription' || typeCheckout === 'subscription') &&
                    <p>Seu acesso será liberado após o pagamento. Lembrando que você pode alterar ou cancelar seu plano quando quiser.</p>
                }
                
                {typeCheckout === 'buy-course' &&
                     <>
                        {course?.is_free === 1 ?
                            <p>Seu acesso será liberado após concluir todos os passos. Lembrando que você pode solicitar reembolso 7 dias corridos após a compra.</p>
                        :
                            <p>Seu acesso será liberado após o pagamento. Lembrando que você pode solicitar reembolso 7 dias corridos após a compra.</p>
                        }
                    </>
                }

                <div className="website_payment_new">
                    <div className="website_pay_container_new">
                        <div className='payment_content_new'>
                            <div className='box_all_checkout'>
                                {clientSecret ?
                                    <div className='box_checkout_default_father'>
                                        <div className='box_checkout_card_payment'>
                                            {clientSecret && stripePromise && (
                                                <Elements options={options} stripe={stripePromise}>
                                                    {typeCheckout === 'buy-course' ?
                                                        <CheckoutSinglePaymentForm 
                                                            typeCheckout={typeCheckout} 
                                                            clientSecret={clientSecret} 
                                                            /*planSelectedId={planSelected.id}*/ 
                                                            nextStep={() => nextStep()}

                                                            // courseIsFree={(course.is_free === 1 && course.price === 0) ? true : false} 
                                                        />
                                                    :
                                                        <CheckoutSubscriptionForm 
                                                            typeCheckout={typeCheckout} 
                                                            clientSecret={clientSecret} 
                                                            /*planSelectedId={planSelected.id}*/ 
                                                            nextStep={() => nextStep()}
                                                        />
                                                    } 
                                                </Elements>
                                            )}
                                        </div>
                                    </div>
                                :
                                    <div className='box_plan_loader'>
                                        <CircularProgress sx={{color: "var(--plat-seven)"}} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepReactivateChangeDefaultPayment;