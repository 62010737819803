import React, { useEffect, useState, useRef, useContext } from "react";
import { toast } from "react-toastify";
import Form from "../../../components/Form";
import api from "../../../services/api";
import { Context } from '../../../context/AuthContext'
import './style.css'
import InputFile from "../../../components/Form/InputFile";
import ModalChangePassword from "../../../components/Modal/General/ModalChangePassword";
import ModalCropUpdatePhoto from "../../../components/Modal/General/ModalCropUpdatePhoto";
import InputFileImage from "../../../components/Form/InputFile";
import { CircularProgress, FormControl, FormHelperText, Grid, MenuItem } from "@mui/material";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { CustomLoadingButton } from "../../../components/MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton";
import { CustomTextField, inputLabelStyles } from "../../../components/MaterialCustom/Laboratory/TextField/CustomTextField";
import ModalCancelUserPlan from "../../../components/Modal/General/ModalCancelUserPlan";
import ModalReactivateUserPlan from "../../../components/Modal/General/ModalReactivateUserPlan";
import ModalChangePlan from "../../../components/Modal/General/ModalChangePlan";
import CardSimulator from "../../../components/PaymentWithStripe/SubComponents/CardSimulator";
import CardSimulatorAdd from "../../../components/PaymentWithStripe/SubComponents/CardSimulatorAdd";
import ModalAddPaymentMethod from "../../../components/Modal/General/ModalAddPaymentMethod";
import {loadStripe} from '@stripe/stripe-js';
import { useHistory } from "react-router-dom";
// import apiDownloadPdf from "../../../services/apiDownloadPdf";

function MyProfile ({}) {
    const { setMetaTagsOfPage, handleLogout, formProfilePhotoUrl, setFormProfilePhotoUrl, userPicture, showAlert, alertInTopClosed } = useContext(Context)
    const [spinnerBtnLoader, setSpinnerBtnLoader] = useState(false)
    const [spinnerLoader, setSpinnerLoarder] = useState(false)
    const [modalChangePasswordStatus, setModalChangePasswordStatus] = useState(false)
    const [modalCropUpdatePhotoStatus, setModalCropUpdatePhotoStatus] = useState(false)
    const [modalCancelUsrPlan, setModalCancelUsrPlan] = useState(false)
    const [modalReactivateUsrPlan, setModalReactivateUsrPlan] = useState(false)
    const [modalChangePlanStatus, setModalChangePlanStatus] = useState(false)
    const [modalAddPayMethodStatus, setModalAddPayMethodStatus] = useState(false)
    const [plansOptions, setPlansOptions] = useState([])
    const [userPlanCurrent, setUserPlanCurrent] = useState()
    const [userChgPlan, setUserChgPlan] = useState()
    const [cards, setCards] = useState([])
    // const [loadingInvoice, setLoadingInvoice] = useState(false)
    const history = useHistory()
    const [userRecovery, setUserRecovery] = useState();

    //stripe
    const [stripePromise, setStripePromise] = useState(null)

    const [formMyProfile, setFormMyProfile] = useState({
        email: '',
        name: '',
        // plan_id: '',
        born_date: ''
    })

    const [fieldsError, setFieldsError] = useState({
        email: '',
        name: '',
        // plan_id: '',
        born_date: ''
    })

    const thumbValidations = useRef({
        // required: {message:'Informe um thumb!'},
    })

    useEffect(() => {
        window.scrollTo(0, 0)

        setMetaTagsOfPage('Meu perfil', 'Confira ou altere seus dados de perfil!')
        setSpinnerLoarder(true)

        //Recupera os dados do usuário logado
        getDataOfUserLogged()

        //Stripe
        loadPublishableKey()

        getData()
    }, [])

    async function getData() {
        let userHasAnPlan = JSON.parse(localStorage?.getItem('user'))?.has_an_active_plan ? 'yes' : 'no';

        await api.get('meu-perfil', {params: {has_plan: userHasAnPlan}})
            .then((response) => {
                if (response.data.status === 'success') {
                    setFormMyProfile(response.data.data?.user)
                    setCards(response.data.data?.payment_methods ?? [])

                    if (userHasAnPlan === 'yes') {
                        setPlansOptions(response.data.data?.plans)
                        setUserPlanCurrent(response.data.data?.current_plan)
                        
                        response.data.data?.plans.map((plan) => {
                            if (plan.id === response.data.data?.current_plan.id) {
                                setUserChgPlan(plan)
                                return;
                            }

                            return;
                        })
                    }
                }

                setSpinnerLoarder(false)
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout()
                }

                setSpinnerLoarder(false)
            })
    }

    function getDataOfUserLogged(){
        let userRecov = JSON.parse(localStorage?.getItem('user'))
        setUserRecovery(userRecov)
    }

    function loadPublishableKey() {
        api.get('/stripe/config')
            .then((response) => {
                if (response.data.status === 'success') {
                    setStripePromise(loadStripe(response.data.data.publishable_key))
                } else {
                    history.push("/erro/404")
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout()
                }
            })
    }

    function setName(e) {
        setFormMyProfile({...formMyProfile, name: e.target.value})
    }

    function setEmail(e) {
        setFormMyProfile({...formMyProfile, email: e.target.value})
    }

    // function setPlan(e) {
    //     setFormMyProfile({...formMyProfile, plan_id: e.target.value})
    // }

    function setBornDate(e) {
        setFormMyProfile({...formMyProfile, born_date: e.target.value})
    }

    async function handleUpdate(e) {
        e.preventDefault()
        setSpinnerBtnLoader(true)

        await api.post('meu-perfil', formMyProfile)
            .then((response) => {
                if (response.data.status === 'success') {
                    let userOld = userRecovery;
                    let nameOld = userOld.name;

                    if (nameOld !== formMyProfile.name) {
                        userOld.name = formMyProfile.name

                        //Altero no localstorage e no State o nome do usuário, caso tenha sido alterado
                        localStorage.setItem('user', JSON.stringify(userOld))
                        setUserRecovery(userOld)
                    } 

                    toast.success(response.data.message)
                } else {
                    toast.error(response.data.message)
                }

                setFieldsError({email: '', name: '', /*plan_id: '',*/ born_date: ''})
                setSpinnerBtnLoader(false)
            }).catch((error) => {
                if (error.response.status === 422) {
                    setFieldsError(error.response.data.data)
                    toast.error(error.response.data.message)
                } else if (error.response.status === 401) {
                    handleLogout()
                }

                setSpinnerBtnLoader(false)
            })
    }

    function loadImage(e) {
        if (e.target.files.length === 0) {
            return;
        }

        let file = e.target.files[0]

        let reader = new FileReader()
        reader.readAsDataURL(file)

        //Validações de Front
        const allowedExtensions = ['image/jpg', 'image/jpeg', 'image/png'];
        if (!allowedExtensions.includes(file.type)) {
            toast.warning('Formato inválido! Formatos permitidos: jpg, jpeg, png')
            return
        }
 
        reader.onload = () => {
            // setFormProfilePhotoBase64({ thumb: reader.result })
            // setFormProfilePhotoObject({ thumb: file })
            setFormProfilePhotoUrl(URL.createObjectURL(file));

            setModalCropUpdatePhotoStatus(!modalCropUpdatePhotoStatus)
        }
    }

    //Recupera a fatura do usuário (NÃO APAGAR SERÁ USADO NO FUTURO)
    // function getInvoice()
    // {
    //     let invoiceId = 'in_1OpEaVKlOKadBLAgjHOfyhTR'
    //     setLoadingInvoice(true)

    //     apiDownloadPdf.get(`/user/invoice/${invoiceId}`)
    //         .then((response) => {
    //             const url = window.URL.createObjectURL(new Blob([response.data]));
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute('download', 'my-invoice.pdf'); //or any other extension
    //             document.body.appendChild(link);
    //             link.click();

    //             setLoadingInvoice(false)
    //         }).catch((error) => {
    //             if (error.response.status === 401) {
    //                 handleLogout()
    //             }

    //             setLoadingInvoice(false)
    //         })
    // }

    return (
        <div className="box_labor_all">
            <div className={`box_profile ${(showAlert !== '' && !alertInTopClosed) && 'alert-active'}`}>

                <div className="profile_content">
                    <div className="contact_inside">
                        <h1>Meu Perfil</h1>
                        <p className="descp_sup_prof">Informações de perfil, mantenha seus dados atualizados!</p>
                    
                        <div className="box_myprofile">
                            {spinnerLoader ? 
                                <CircularProgress sx={{color: "var(--plat-seven)"}} />
                            :
                                <>
                                    <div className="big_box_prof_pic">
                                        <div className="profile_picture">
                                            <div className="box_profile_thumb">
                                                {(!formProfilePhotoUrl) ?
                                                    <InputFile name='thumb' description='Foto de Perfil' onChange={loadImage} value={userPicture ?? ''} validations={thumbValidations.current}/>
                                                :
                                                    <InputFileImage value={userPicture} name='thumb' description='Foto de Perfil' onChange={loadImage}/>
                                                }
                                                
                                                <div className="box_camera">
                                                    <label for="file_image">
                                                        <CameraAltIcon color="neutral" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{width: '100%'}}>
                                        <div className='myprofile_plan'>
                                            <h2>Dados Pessoais</h2>

                                            <Form>
                                                <Grid container spacing={2} sx={{marginBottom:"20px"}}>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <CustomTextField
                                                                required
                                                                label="Nome completo"
                                                                onChange={setName}
                                                                value={formMyProfile.name} 
                                                                name='name'
                                                                autoComplete='off'
                                                                {...(fieldsError.name && {error: 'true'})}
                                                                InputLabelProps={{
                                                                    sx: {
                                                                    ...inputLabelStyles,
                                                                    },
                                                                }}
                                                                size="medium"
                                                                fullWidth
                                                                inputProps={{ maxLength: 200 }}
                                                            />

                                                            <FormHelperText>
                                                                {fieldsError.name}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <CustomTextField
                                                                required
                                                                label="E-mail"
                                                                name='email'
                                                                disabled
                                                                {...(fieldsError.email && {error: 'true'})}
                                                                onChange={setEmail} 
                                                                value={formMyProfile.email}
                                                                size="medium"
                                                                fullWidth
                                                                inputProps={{ maxLength: 200 }}
                                                                InputLabelProps={{
                                                                    sx: {
                                                                    ...inputLabelStyles,
                                                                    },
                                                                }}
                                                            />

                                                            <FormHelperText>
                                                                {fieldsError.email}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <CustomTextField
                                                                label="Data de nascimento"
                                                                type="date"
                                                                name="born_date"
                                                                size="medium"
                                                                // defaultValue="2017-05-24"
                                                                {...(fieldsError.born_date && {error: 'true'})}
                                                                onChange={setBornDate}
                                                                value={formMyProfile.born_date}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                    sx: {
                                                                    ...inputLabelStyles
                                                                    },
                                                                }}
                                                            />

                                                            <FormHelperText>
                                                                {fieldsError.born_date}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <div className="box_forget">
                                                    <span className="forget" onClick={() => setModalChangePasswordStatus(!modalChangePasswordStatus)}>Deseja alterar sua senha?</span>
                                                </div>

                                                <Grid container >
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <CustomLoadingButton
                                                            onClick={handleUpdate}
                                                            endIcon={<SendOutlinedIcon />}
                                                            loading={spinnerBtnLoader}
                                                            loadingPosition="end"
                                                            variant="contained"
                                                            fullWidth
                                                            color='secondary'
                                                            size="large"
                                                        >
                                                            <span>Alterar Dados</span>
                                                        </CustomLoadingButton>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        </div>

                                        {/* Se o usuário não possuir Planos de Assinatura eu não exibo a BOX "Minha Assinatura" */}
                                        {userRecovery?.has_an_active_plan === true &&
                                            <div className="myprofile_plan">
                                                <div className="title_with_descp_plan_changed">
                                                    <h2>Minha Assinatura</h2>

                                                    {Boolean(userRecovery?.plan_downgrade) === true &&
                                                        <span>Você fez <b>downgrade</b> do seu plano recentemente, até o final do período de faturamento, você ainda terá as permissões do plano Starter.</span>
                                                    }
                                                </div>

                                                <div className="box_mp_plan_main">
                                                    <Form>
                                                        <Grid container spacing={2} sx={{marginBottom:"20px"}}>
                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                <FormControl variant="standard" fullWidth>
                                                                    <CustomTextField
                                                                        required
                                                                        select
                                                                        sx={{'& .MuiSvgIcon-root': {color: 'var(--plat-seven)'}}}
                                                                        label="Plano atual"
                                                                        {...((userRecovery?.is_teacher === true) && {disabled: 'true'})}
                                                                        {...(fieldsError.plan_id && {error: 'true'})}
                                                                        size="medium"
                                                                        fullWidth
                                                                        name="plan_id"
                                                                        onChange={(e) => setUserChgPlan(e.target.value)}
                                                                        value={userChgPlan} 
                                                                        InputLabelProps={{
                                                                            sx: {
                                                                            ...inputLabelStyles,
                                                                            },
                                                                        }}
                                                                        inputProps={{
                                                                            MenuProps: {
                                                                                MenuListProps: {
                                                                                    sx: {
                                                                                        backgroundColor: 'var(--plat-two)',
                                                                                        color: 'var(--plat-seven)'
                                                                                    }
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        {plansOptions.map((plan) => (
                                                                            <MenuItem key={plan.id} value={plan} sx={{"&:hover": {backgroundColor: "var(--plat-four)"}}}>
                                                                                {plan.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </CustomTextField>
                                                                    
                                                                    <FormHelperText>
                                                                        {fieldsError.plan_id}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                <FormControl variant="standard" fullWidth>
                                                                    <CustomTextField
                                                                        required
                                                                        label="Preço"
                                                                        name='price'
                                                                        disabled
                                                                        {...(fieldsError.price && {error: 'true'})}
                                                                        value={`R$ ${userChgPlan?.price_pretty}`}
                                                                        size="medium"
                                                                        fullWidth
                                                                        inputProps={{ maxLength: 200 }}
                                                                        InputLabelProps={{
                                                                            sx: {
                                                                            ...inputLabelStyles,
                                                                            },
                                                                        }}
                                                                    />

                                                                    {/* <FormHelperText>
                                                                        {fieldsError.price}
                                                                    </FormHelperText> */}
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <CustomLoadingButton
                                                                    // onClick={handleUpdate}
                                                                    onClick={() => setModalChangePlanStatus(!modalChangePlanStatus)}
                                                                    endIcon={<SendOutlinedIcon />}
                                                                    loading={spinnerBtnLoader}
                                                                    {...((userRecovery?.is_teacher === true) && {disabled: 'true'})}
                                                                    loadingPosition="end"
                                                                    variant="contained"
                                                                    fullWidth
                                                                    color='secondary'
                                                                    size="large"
                                                                >
                                                                    <span>Alterar Plano</span>
                                                                </CustomLoadingButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Form>
                                                </div>

                                                <div className="box_mp_plan_change">
                                                    <div className="mp_plan_infos">
                                                        {userRecovery?.is_teacher !== true ?
                                                            <>
                                                                {/* Plano alunos */}
                                                                {/* <h3>Plano {userPlanCurrent?.name} {userPlanCurrent?.status === true ? <span className="plan_activated">Ativo</span> : <span className="plan_inactivated">Cancelado</span>}</h3> */}
                                                                {/* <span>R$ {userPlanCurrent?.price}/Mês</span> */}

                                                                {!userPlanCurrent?.signature_ends_at ?
                                                                    <span className='plan_msg_status_active'>Sua assinatura será renovada automaticamente em {userPlanCurrent?.signature_next_payment}</span>
                                                                :
                                                                    <span className='plan_msg_status_canceled'>Você cancelou sua assinatura, seu acesso termina em {userPlanCurrent?.signature_ends_at}</span>
                                                                }
                                                            </>
                                                        :
                                                            <>
                                                                {/* Plano professores */}
                                                                {/* <h3>Plano {userRecovery?.plan} <span className="plan_activated">Ativo</span></h3> */}
                                                                {/* <span>Gratuito</span> */}
                                                            </>
                                                        }
                                                    </div>

                                                    <div className="mp_plan_actions">
                                                        <div className="box_alter_plan">
                                                            <>
                                                                {/* NÃO APAGAR, SERÁ USADO NO FUTURO
                                                                {userRecovery?.is_teacher !== true &&
                                                                    <>
                                                                        {loadingInvoice ?
                                                                            <CircularProgress sx={{width:"24px !important", height:"24px !important", color: "var(--plat-twelve)"}} />
                                                                        :
                                                                            <span onClick={() => getInvoice()}>Meus pagamentos</span>
                                                                        }
                                                                    </>
                                                                } */}

                                                                {!userPlanCurrent?.signature_ends_at ?
                                                                    <>
                                                                        {userRecovery?.is_teacher !== true &&
                                                                            <span onClick={() => setModalCancelUsrPlan(!modalCancelUsrPlan)}>Cancelar assinatura</span>
                                                                        }
                                                                    </>
                                                                :
                                                                    <>
                                                                        {cards?.length >= 1 &&
                                                                            <span onClick={() => setModalReactivateUsrPlan(!modalReactivateUsrPlan)}>Reativar assinatura</span>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {/* Se o usuário possuir o Plano de Professor eu não exibo a BOX "Cartões" */}
                                        {userRecovery?.is_teacher !== true &&
                                            <div className='myprofile_plan'>
                                                <h2>Cartões Cadastrados</h2>
                                                
                                                {cards?.length === 2 &&
                                                    <p className="card_description">O cartão selecionado será utilizado para realizar o pagamento da mensalidade.</p>
                                                }
                                                
                                                <div className="mprf_card_box">
                                                    <div className="card_display">
                                                        {cards &&
                                                            <>
                                                                {cards.map((card, index) => {
                                                                    return <CardSimulator 
                                                                        key={index} 
                                                                        isCardDefault={card.is_default} 
                                                                        cardName={card.name} 
                                                                        cardBrand={card.brand} 
                                                                        cardLastFourNumbers={card.last_numbers} 
                                                                        cardExpMonth={card.exp_month} 
                                                                        cardExpYear={card.exp_year} 
                                                                        cardPmId={card.pm_id} 
                                                                        withRmv={true}
                                                                        cards={cards} 
                                                                        setCards={setCards}
                                                                        setUserPlanCurrent={setUserPlanCurrent}
                                                                        userPlanCurrent={userPlanCurrent}
                                                                    />
                                                                })}
                                                            </>
                                                        }

                                                        {/* Só pode adicionar no máximo dois cartões */}
                                                        {cards?.length < 2 &&
                                                            <CardSimulatorAdd onClickPm={() => setModalAddPayMethodStatus(!modalAddPayMethodStatus)} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>

                                    <ModalChangePassword modalStatus={modalChangePasswordStatus} setModalStatus={setModalChangePasswordStatus} />
                                    <ModalCropUpdatePhoto modalStatus={modalCropUpdatePhotoStatus} setModalStatus={setModalCropUpdatePhotoStatus} />
                                    <ModalCancelUserPlan modalStatus={modalCancelUsrPlan} setModalStatus={setModalCancelUsrPlan} userPlanCurrent={userPlanCurrent} setUserPlanCurrent={setUserPlanCurrent}/>
                                    <ModalReactivateUserPlan modalStatus={modalReactivateUsrPlan} setModalStatus={setModalReactivateUsrPlan} userPlanCurrent={userPlanCurrent} setUserPlanCurrent={setUserPlanCurrent}/>
                                    <ModalChangePlan modalStatus={modalChangePlanStatus} setModalStatus={setModalChangePlanStatus} planChanged={userChgPlan}/>
                                    <ModalAddPaymentMethod modalStatus={modalAddPayMethodStatus} setModalStatus={setModalAddPayMethodStatus} stripePromise={stripePromise} setCards={setCards} />
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyProfile;