import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useState } from "react";
import '../CheckoutSubscriptionForm/style.css'
import { FormControl, FormHelperText, Grid } from "@mui/material";
import { CustomLoadingButton } from "../../../components/MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton";
import { CustomTextFieldCheckoutForm } from "../../MaterialCustom/Laboratory/TextField/CustomTextFieldCheckoutForm";
import { paymentElementOptions } from "../SubComponents/CheckoutFormOptionsAndStyled";

export default function CheckoutSinglePaymentForm({clientSecret, nextStep, courseIsFree = false /*, userId = null, planSelectedId = null*/}) {
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [messageError, setMessageError] = useState(null);
  // const [messageWarning, setMessageWarning] = useState(null);
  
  const [cardName, setCardName] = useState('')
  const [cardNameError, setCardNameError] = useState('')

  // function retrieve() {
    // Retrieve the PaymentIntent
    // stripe.retrievePaymentIntent(clientSecret)
    //   .then(({paymentIntent}) => {
    //     switch (paymentIntent.status) {
    //       case 'succeeded':
    //         setMessage('Success! Payment received.');
    //         break;

    //       case 'processing':
    //         setMessage("Payment processing. We'll update you when payment is received.");
    //         break;

    //       case 'requires_payment_method':
    //         // Redirect your user back to your payment page to attempt collecting
    //         // payment again
    //         setMessage('Payment failed. Please try another payment method.');
    //         break;

    //       default:
    //         setMessage('Something went wrong.');
    //         break;
    //     }
    //   });
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setMessageError('')
    // setMessageWarning('')

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // Se o curso for gratuito o STRIPE não aceita utilizar o PAYMENT INTENT, somente o SETUP INTENT.
    // Por isso estou verificando se o courseIsFree=true
    if (courseIsFree) {

      //Confirma se os dados do cartão estão aptos para o "pagamento" do curso gratuito
      const { error: stripeError, setupIntent } = await stripe.confirmSetup({ 
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/sucessed`,

          payment_method_data: {
            billing_details:{
              name: cardName
            }
          }
        },
        redirect: 'if_required', //remove o redirecionamento aplicado acima
      });

      if (stripeError) {
        //Exibe o erro do cartão para o usuário (ex: saldo insuficiente)
        setMessageError(stripeError.message);

        setIsLoading(false);
        return;
      } else if (setupIntent && setupIntent?.status === 'succeeded') {
        // setMessageWarning('Aguarde, finalizando assinatura...')

        //Criou com sucesso
        setTimeout(() => {
            nextStep()
            setIsLoading(false);
        },  3000)
      } else {
        setMessageError('Erro inesperado!')

        setIsLoading(false);
        return;
      }

    } else {

      //Confirma se os dados do cartão estão aptos para o pagamento de taxa única
      const { error: stripeError, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/sucessed`,

          payment_method_data: {
            billing_details:{
              name: cardName
            }
          }
        },
        redirect: 'if_required', //remove o redirecionamento aplicado acima
      });

      if (stripeError) {
        //Exibe o erro do cartão para o usuário (ex: saldo insuficiente)
        setMessageError(stripeError.message);

        setIsLoading(false);
        return;
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        // setMessageWarning('Aguarde, finalizando assinatura...')

        //Criou com sucesso
        setTimeout(() => {
            nextStep()
            setIsLoading(false);
        },  3000)
      } else {
        setMessageError('Erro inesperado!')

        setIsLoading(false);
        return;
      }

    }
  };

  return (
    <form id="payment-form" className="payment-form" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl variant="standard" fullWidth sx={{marginBottom: '15px'}}>
              <label htmlFor="card-holder-name" style={{color: "var(--plat-six)", marginBottom:"0.25rem", fontSize:"0.93rem", lineHeight: '1.15'}}>Nome impresso no cartão</label>
              
              <CustomTextFieldCheckoutForm
                  // required
                  label=" "
                  id="card-holder-name" 
                  onChange={(e) => setCardName(e.target.value)}
                  value={cardName} 
                  autoComplete="off"
                  name='account_name'
                  {...(cardNameError.account_name && {error: 'true'})}
                  // InputLabelProps={{
                  //     sx: {
                  //     ...inputLabelStyles,
                  //     },
                  //     // shrink: true
                  // }}
                  size="medium"
                  fullWidth
                  inputProps={{ maxLength: 200, style: {textTransform: "uppercase"} }}
              />

              <FormHelperText>
                  {cardNameError.account_name}
              </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>

      <PaymentElement id="payment-element" options={paymentElementOptions}/>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{marginTop:"25px"}}>
        <CustomLoadingButton
            onClick={(e) => handleSubmit(e)}
            loading={isLoading}
            variant="contained"
            fullWidth
            disabled={isLoading || !stripe || !elements}
            color='secondary'
            size="large"
        >
          {courseIsFree ?
            <span>Ganhar Presente</span>
          :
            <span>Finalizar compra</span>
          }
        </CustomLoadingButton>
      </Grid>

      {/* Show any error or success messages */}
      {messageError && <div id="payment-message">{messageError}</div>}
      {/* {messageWarning && <div id="payment-message-warning">{messageWarning}</div>} */}
    </form>
  );
}