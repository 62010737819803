import { useContext, useState } from 'react';
import ModalRmvPaymentMethod from '../../../Modal/General/ModalRmvPaymentMethod';
import './style.css'
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import { Context } from '../../../../context/AuthContext';
import { CircularProgress } from '@mui/material';

function CardSimulator({ isCardDefault=false, cardName='', cardPmId, cardBrand, cardLastFourNumbers, cardExpMonth, cardExpYear, withRmv=false, cards=null, setCards=null, setUserPlanCurrent=null, userPlanCurrent=null }) {
    const [modalRmvPayMethodStatus, setModalRmvPayMethodStatus] = useState(false)
    const { handleLogout } = useContext(Context)
    const [isChangingCard, setIsChangingCard] = useState(false)

    async function changeDefaultPaymentMethod(paymentMethodId) {
        //se tiver só um cartão eu desconsidero o clique, pois não tem como ele trocar a forma de pagamento (só tem um cartão)
        //se já for o cartão padrão eu tbem desconsidero o clique
        if (cards?.length >= 2 && isCardDefault === false) {
            setIsChangingCard(true)

            await api.post('costumer/change-user-default-payment-method', {payment_method_id: paymentMethodId})
                .then((response) => {
                    if (response.data.status === 'success') {
                        setCards(response.data.data.payment_methods)
                        toast.success(response.data.message)
                    } else {
                        toast.error(response.data.message)
                    }

                    setIsChangingCard(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        handleLogout()
                    }
                })
        }
    }

    return (
        <div className="card_out_box">
            <div className={`card_simulator${isCardDefault ? ' card_is_default' : ''}`} onClick={() => changeDefaultPaymentMethod(cardPmId)}>
                {isChangingCard ?
                    <div className='card_simulator_loader'>
                        <CircularProgress sx={{width:"34px !important", height:"34px !important", color: "var(--plat-seven)"}} />
                        <p style={{marginTop: '20px'}}>Alterando forma de pagamento padrão...</p>
                    </div>
                :
                    <>
                        <div className="cs_title">
                            <span>{isCardDefault ? 'Cartão padrão' : 'Cartão inativo'}</span>
                            <span className="brand">{cardBrand}</span>
                        </div>
                        
                        <div className="cs_hologram_box">
                            <div className="cs_hologram">
                                <div className="cs_inside_hologram">
                                    <div className="cs_in_inside_holoragram">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cs_fullname">
                            <span>{cardName ? cardName : '******* *******'}</span>
                        </div>

                        <div className="cs_details">
                            <span>**** **** **** {cardLastFourNumbers}</span>
                            <span>{cardExpMonth} / {cardExpYear}</span>
                        </div>
                    </>
                }
            </div>

            {withRmv &&
                <>
                    <span className="rmv_card" onClick={() => setModalRmvPayMethodStatus(!modalRmvPayMethodStatus)}>Remover cartão</span>
                    <ModalRmvPaymentMethod modalStatus={modalRmvPayMethodStatus} setModalStatus={setModalRmvPayMethodStatus} cards={cards} setCards={setCards} cardPmId={cardPmId} setUserPlanCurrent={setUserPlanCurrent} userPlanCurrent={userPlanCurrent} />
                </>
            }
        </div>
    )
}

export default CardSimulator;