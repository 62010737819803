import { /*useContext,*/ useState } from 'react';
import { CustomLoadingButton } from '../../../../MaterialCustom/Laboratory/LoadingButton/CustomLoadingButton';
// import api from '../../../../../services/api';
// import { Context } from '../../../../../context/AuthContext';
import { prettyUserName } from '../../../../../helpers';

function StepAlertPayment ({nextStep, stepNow = null, stepTotals = null, typeMsg = null, setDefaultCard = null, user, course = null}) {
    const [stepLoader, setStepLoader] = useState(false)
    // const { authenticated, handleLogout } = useContext(Context)

    // async function retrieveUserCards() {
    //     await api.get('costumer/user-payment-methods')
    //         .then((response) => {
    //             if (response.data.status === 'success') {
    //                 setDefaultCard(response.data.data.default_card)
    //                 nextStep()
    //             }

    //             setStepLoader(false)
    //         }).catch((error) => {
    //             setStepLoader(false)

    //             if (error.response.status === 401) {
    //                 handleLogout()
    //             }
    //         })
    // }

    async function sendStep() {
        setStepLoader(true)

        // if (authenticated) {
        //     await retrieveUserCards()
        // } else {
            setTimeout(() => {
                nextStep()
                setStepLoader(false)
            }, 300)
        // }
    }

    return (
        <div className='payment_box_big_msg'>
            <div className='payment_box_message'>
                <span className='step_payment'>PASSO {stepNow} de {stepTotals}</span>
                <h1>{user?.name ? prettyUserName(user.name) : user.name}, falta bem pouquinho!</h1>
                
                {typeMsg === 'buy' ?
                    <>
                        {course?.is_free === 1 ?
                            <p>Seu acesso será liberado após concluir todos os passos.</p>
                        :
                            <p>Seu acesso será liberado após o pagamento.</p>
                        }
                    </>
                   
                :
                    <p>Sua assinatura será {typeMsg === 'reactivate' ? 'reativada' : 'iniciada'} após o pagamento. Lembrando que você pode alterar ou cancelar seu plano quando quiser.</p>
                }
                
                <CustomLoadingButton
                    onClick={() => sendStep()}
                    loading={stepLoader}
                    variant="contained"
                    fullWidth
                    color='secondary'
                    size="large"
                >
                    <span>Próximo</span>
                </CustomLoadingButton>
            </div>
        </div>
    )
}

export default StepAlertPayment;